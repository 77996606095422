import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { TypesTableRow } from './types'
import { getSerieFolio, parseTimestampToTimeZone, dateFormatter, Roles, URLS } from '@netcurio/frontend-common'
import Formatter from '../../utilities/formatter'
import Constants from '../../utilities/constants'
import styles from './creditMemoList.module.scss'

export const TableRow = ({
	dataCreditMemo: { date, folio, invoice, receiver, invoice_reference, sender, serie, status, total, uuid },
	userRole
}: TypesTableRow): ReactElement => {
	const history = useHistory<string>()
	const styleColumnTotal: string = userRole === Roles.CUSTOMER ? styles.totalClient : styles.totalSupplier
	const senderOrReceiverName: string =
		userRole === Roles.CUSTOMER && sender ? sender.name : receiver ? receiver.name : ''
	const styleColumnSenderOrReceiver: string =
		userRole === Roles.CUSTOMER && sender ? styles.supplier : receiver ? styles.receiver : ''
	const containerMissingLink: string =
		status &&
		(status.key === Constants.CREDIT_MEMO_STATUS.MISSING_LINK ||
			status.key === Constants.CREDIT_MEMO_STATUS.ERROR)
			? ` ${styles.containerMissingLink} `
			: ''

	return (
		<div
			className={`${styles.rowCreditMemoList} ${containerMissingLink}`}
			onClick={() => history.push(`${URLS.CREDIT_MEMO_DETAIL}?creditmemo=${uuid}`)}
		>
			<p className={`${styles.textColumnMainTableCreditMemo} ${styles.seriesFolio}`}>
				{getSerieFolio(serie, folio)}
			</p>
			<p className={`${styles.textColumnMainTableCreditMemo} ${styles.statusContainer}`}>
				{status.value}
			</p>
			<p
				className={`${styles.textColumnMainTableCreditMemo} ${styles.receiver} ${styleColumnSenderOrReceiver}`}
			>
				{senderOrReceiverName}
			</p>
			<p className={`${styles.textColumnMainTableCreditMemo} ${styles.createdAt}`}>
				{dateFormatter.format(parseTimestampToTimeZone(date))}
			</p>
			<p
				className={`${styles.textColumnMainTableCreditMemo}  justify-content-left padding-right-20 total-credit-memo-small ${styleColumnTotal}`}
			>
				{Formatter.currency.format(total)}
			</p>
			<p className={`${styles.textColumnMainTableCreditMemo} ${styles.relatedInvoice}`}>
				{invoice ? getSerieFolio(invoice.serie, invoice.folio) : invoice_reference}
			</p>
		</div>
	)
}
