import React, { ReactNode } from 'react'
import { Theme, ThemeOptions } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { TableCellBaseProps } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { SxProps } from '@mui/material'
import {
	ColorBaseGrayLynx,
	ColorBaseGrayPebble,
	ColorBaseGraySilver,
	ColorThemeListsPending,
	MdRefTypefaceFont,
	MdSysLabelMediumLineHeight,
	MdSysLabelMediumSize
} from '@netcurio/frontend-design-tokens/dist/_variables'
import { CSSProperties } from '@mui/system/CSSProperties'

export const NetcurioTableThemeOptions: ThemeOptions = {
	components: {
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontSize: MdSysLabelMediumSize,
					lineHeight: MdSysLabelMediumLineHeight,
					fontFamily: MdRefTypefaceFont
				}
			}
		}
	}
}

interface TableProps {
	/**
	 * The content of the table, normally `NetcurioTableHead` and `NetcurioTableBody`.
	 */
	children?: React.ReactNode
	/**
	 * Set the header sticky.
	 *
	 * ⚠️ It doesn't work with IE11.
	 * @default false
	 */
	stickyHeader?: boolean
	/**
	 * Allows NetcurioTableCells to inherit size of the Table.
	 * @default 'medium'
	 */
	size?: 'small' | 'medium'
	/**
	 * Specify Table min width
	 */
	minWidth?: string
	/**
	 * Specify Table min height
	 */
	minHeight?: string
}

/**
 * NetcurioTable
 * @param stickyHeader <boolean>: Set the header sticky.
 * @param size <'small' | 'medium'>: Allows NetcurioTableCells to inherit size of the Table.
 * @default 'medium'
 * @param minWidth  <string>: Set to override default minWidth
 * @param minHeight  <string>: Set to override default minHeight
 * @returns component
 */
export const NetcurioTable = ({ children, minWidth, minHeight, ...rest }: TableProps) => {
	return (
		<Table
			stickyHeader
			sx={{
				minWidth: minWidth ?? 'none',
				minHeight: minHeight ?? 'none'
			}}
			{...rest}
		>
			{children}
		</Table>
	)
}

interface TableBodyProps {
	children: ReactNode
}

export const NetcurioTableBody = ({ children, ...rest }: TableBodyProps) => {
	return <TableBody {...rest}>{children}</TableBody>
}
interface TableCellProps {
	/**
	 * The content of the component.
	 */
	children?: React.ReactNode
	/**
	 * Set the text-align on the table cell content.
	 *
	 * Monetary or generally number fields **should be right aligned** as that allows
	 * you to add them up quickly in your head without having to worry about decimals.
	 * @default 'inherit'
	 */
	align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
	/**
	 * Sets the padding applied to the cell.
	 * The prop defaults to the value (`'default'`) inherited from the parent Table component.
	 */
	padding?: 'normal' | 'checkbox' | 'none'
	/**
	 * Specify the size of the cell.
	 * The prop defaults to the value (`'medium'`) inherited from the parent Table component.
	 */
	size?: 'small' | 'medium'
	/**
	 * Set aria-sort direction.
	 */
	sortDirection?: 'desc' | 'asc'
	/**
	 * Specify the cell type.
	 * The prop defaults to the value inherited from the parent NetcurioTableHead, NetcurioTableBody, or NetcurioTableFooter components.
	 */
	variant?: 'head' | 'body' | 'footer'
	/**
	 * Set scope attribute.
	 */
	scope?: string
	/**
	 * The component used for the root node.
	 * Either a string to use a HTML element or a component.
	 */
	component?: React.ElementType<TableCellBaseProps>
	/**
	 * Set colSpan attribute.
	 */
	colSpan?: number | undefined
	/**
	 * Set rowSpan attribute.
	 */
	rowSpan?: number | undefined
	/**
	 * The system prop that allows defining system overrides as well as additional CSS styles.
	 */
	sx?: SxProps<Theme>
	/**
	 * Custom class name
	 */
	className?: string
}

/**
 * NetcurioTableCell
 * @param children <ReactNode>: The content of the component.
 * @param align <'inherit' | 'left' | 'center' | 'right' | 'justify'>: Set the text-align on the table cell content.
 * Monetary or generally number fields **should be right aligned** as that allows
 * you to add them up quickly in your head without having to worry about decimals.
 * @default 'inherit'
 * @param padding <'normal' | 'checkbox' | 'none'>: Sets the padding applied to the cell.
 * The prop defaults to the value (`'default'`) inherited from the parent Table component.
 * @param size <'small' | 'medium'>: Specify the size of the cell.
 * The prop defaults to the value (`'medium'`) inherited from the parent Table component.
 * @param sortDirection <'desc'| 'asc'>: Set aria-sort direction.
 * @param variant <'head' | 'body' | 'footer'>: Specify the cell type.
 * The prop defaults to the value inherited from the parent NetcurioTableHead, NetcurioTableBody, or NetcurioTableFooter components.
 * @param scope <string>: Set scope attribute.
 * @param colSpan <number | undefined>: Set colSpan attribute.
 * @param rowSpan <number | undefined>: Set rowSpan attribute.
 * @param component <ElementType<TableCellBaseProps>>: The component used for the root node.
 * @param sx <SxProps<Theme>> The system prop that allows defining system overrides as well as additional CSS styles.
 * @param classname <string> Is a custom class name
 * Either a string to use a HTML element or a component.
 * @returns component
 */
export const NetcurioTableCell = ({ children, ...rest }: TableCellProps) => {
	return <TableCell {...rest}>{children}</TableCell>
}

interface TableContainerProps {
	children: ReactNode
	maxHeight?: string
	maxWidth?: string
	minHeight?: string
}
/**
 * NetcurioTableContainer
 * @param children <ReactNode>: The content of the component.
 * @param maxHeight <string>: Specify Table max height
 * @param minHeight <string>: Specify Table min height
 * @returns component
 */
export const NetcurioTableContainer = ({ children, maxHeight, maxWidth, minHeight }: TableContainerProps) => {
	return (
		<TableContainer
			sx={{
				maxHeight: maxHeight ?? 'none',
				maxWidth: maxWidth ?? 'none',
				minHeight: minHeight ?? 'none',
				'::-webkit-scrollbar': maxHeight ? { display: 'block' } : { display: 'none' },
				scrollbarWidth: maxHeight ? '2px' : 'none'
			}}
			component={Paper}
		>
			{children}
		</TableContainer>
	)
}

interface TableHeadProps {
	/**
	 * The content of the component.
	 */
	children: ReactNode
}
/**
 * NetcurioTableContainer
 * @param children <ReactNode>: The content of the component.
 * @returns component
 */
export const NetcurioTableHead = ({ children }: TableHeadProps) => {
	return <TableHead>{children}</TableHead>
}

interface TableRowProps {
	children: ReactNode
	key?: string | number
	isDetailHeader?: boolean
	isDetailRow?: boolean
	rowWithError?: boolean
	hasCollapsible?: boolean
	onClick?(): void
	style?: CSSProperties
}

/**
 * NetcurioTableContainer
 * @param children <ReactNode>: The content of the component.
 * @param key <string | number>: Row key
 * @param isDetailHeader <boolean>: Flag that indicates that it's a header row inside a detail view
 * @param isDetailRow <boolean>: Flag that indicate that it's a detail row
 * @param rowWithError <boolean>: Flag that activates the default styles when item is in status error
 * @param style <CSSProperties>: Custom styles
 * @returns component
 */
export const NetcurioTableRow = ({
	children,
	isDetailHeader,
	isDetailRow,
	rowWithError,
	hasCollapsible,
	onClick,
	style
}: TableRowProps) => {
	const styleRowCustom: SxProps<Theme> = isDetailHeader
		? {
				'& .MuiTableCell-head': {
					color: ColorBaseGrayPebble,
					backgroundColor: ColorBaseGraySilver,
					height: '4.5rem',
					fontSize: '1.1rem',
					fontWeight: 'bold',
					padding: '0.6rem'
				}
		  }
		: isDetailRow
		? {
				'& .MuiTableCell-body': {
					color: ColorBaseGrayPebble,
					fontSize: '1.1rem',
					padding: '.5rem 0',
					backgroundColor: rowWithError ? ColorThemeListsPending : '',
					cursor: onClick ? 'pointer' : 'default'
				},
				'&:hover': {
					backgroundColor: ColorBaseGrayLynx
				}
		  }
		: hasCollapsible
		? {
				'& > *': { borderBottom: 'none' }
		  }
		: {}

	return (
		<TableRow onClick={onClick} sx={styleRowCustom} style={style}>
			{children}
		</TableRow>
	)
}
