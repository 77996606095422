import { PURCHASE, Status } from '@netcurio/frontend-common'
import { NetcurioAlert, NetcurioButton, Severity } from '@netcurio/frontend-components'
import { t } from 'i18next'
import React from 'react'
import { Invoice } from '../../../../types'
import { ValidationHeader } from '../../../../types/Validations'
import { themeButtonReview } from '../utilities/defaultValues'
import styles from './InvoiceAlert.module.scss'

interface InvoiceAlertProps {
	invoice: Invoice
	seeMessageInvoiceError: boolean
	showLegacyMessage: boolean
	isStandaloneDocument: boolean
	isMissingLink: boolean
	executeValidation: () => void
	amountValidation: ValidationHeader | undefined
}

export const InvoiceAlert = ({
	invoice,
	seeMessageInvoiceError,
	showLegacyMessage,
	isStandaloneDocument,
	isMissingLink,
	executeValidation,
	amountValidation
}: InvoiceAlertProps) => {
	if (showLegacyMessage || invoice.cancellation_reason) {
		return (
			<div className={styles.msgContainer}>
				<NetcurioAlert severity={Severity.Warning}>
					{invoice.cancellation_reason ?? (invoice.error as Status)?.value}
				</NetcurioAlert>
			</div>
		)
	}

	if (isStandaloneDocument) {
		return (
			<div className={styles.msgContainer}>
				<NetcurioAlert severity={Severity.Info} messagePadding="0">
					<div className={styles.verticalCenter}>
						<div className={styles.textCenter}>
							{isMissingLink
								? t('invoices.sendOrCancelAssociation')
								: t('invoices.reviewOrCancelInvoice')}
						</div>
					</div>
				</NetcurioAlert>
			</div>
		)
	}

	if (
		!isStandaloneDocument &&
		amountValidation?.value === 'NOT_OK' &&
		invoice.status.key !== PURCHASE.PAYMENT_SCHEDULED &&
		invoice.status.key !== PURCHASE.PAID &&
		(invoice.advance || invoice.discount)
	) {
		return (
			<div className={styles.msgContainer}>
				<NetcurioAlert severity={Severity.Warning} messagePadding="0">
					<div className={styles.verticalCenter}>
						<div className={styles.textCenter}>
							{invoice.advance ? t('invoices.advanceAmountError') : t('invoices.discountError')}
							<NetcurioButton onClick={() => executeValidation()} sx={themeButtonReview}>
								{t('review')}
							</NetcurioButton>
						</div>
					</div>
				</NetcurioAlert>
			</div>
		)
	}

	if (!seeMessageInvoiceError) {
		return false
	}

	return (
		<div className={styles.msgContainer}>
			<NetcurioAlert severity={Severity.Warning} messagePadding="0">
				<div className={styles.alertWithActionButtonContainer}>
					<div>{t('authorizeManualInvoice.reviewErrors')}</div>
					<NetcurioButton onClick={() => executeValidation()} sx={themeButtonReview}>
						{t('review')}
					</NetcurioButton>
				</div>
			</NetcurioAlert>
		</div>
	)
}
