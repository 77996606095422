import closeIcon from '@netcurio/frontend-assets/src/icons/cancel-solid-red.svg'
import { getSerieFolio, URLS } from '@netcurio/frontend-common'
import classNames from 'classnames'
import * as i18next from 'i18next'
import { AutoComplete } from 'primereact/autocomplete'
import React, { Component } from 'react'
import { Translation } from 'react-i18next'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import Formatter from '../../utilities/formatter'
import { getUrlParameter } from '../../utilities/getUrlParameter'
import * as cmQueries from '../../views/creditMemoDetail/queries'
import { AssociationItem } from './associationItem'
import { ErrorModal } from './Modals/ErrorModal/ErrorModal'
import { RemoveAssociation } from './Modals/RemoveAssociation/RemoveAssociation'
import styles from './styles.module.scss'

class ContainerAssociationDocument extends Component {
	constructor(props, context) {
		const client = connection()
		super(props, context)
		this.state = {
			client,
			searchDocument: undefined,
			documentTypeActual: undefined,
			showChangeDocumentModal: false,
			removeDocumentModal: false,
			errorModal: false,
			documentPresets: {}
		}
		this.loadedInformation = false
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.referenceType !== null && this.props.referenceType !== prevProps.referenceType) {
			let documentTypeActual = this.props.referenceType
				? this.props.referenceType
				: Constants.DOCUMENT_TYPE.PURCHASE_ORDER
			if (this.props.documentTypeSelected) {
				this.props.documentTypeSelected(documentTypeActual)
			}
			if (this.state.documentTypeActual !== documentTypeActual) {
				this.setState({
					documentTypeActual
				})
			}
		}
		if (
			(this.props.documentSelected &&
				prevProps.documentSelected &&
				prevProps.documentSelected !== this.props.documentSelected) ||
			(this.props.documentReferenceId &&
				this.props.documentSelected &&
				this.props.documentReferenceId !== this.props.documentSelected &&
				!this.state.savedDocument)
		) {
			this.setState({
				removeDocumentModal: true,
				savedDocument: true
			})
		}
		if (Object.keys(this.state.documentPresets).length === 0) {
			let textDocumentRelated, goToTooltip, linkTo
			if (this.props.documentReferenceId) {
				if (this.props.detailGR) {
					this.setState({
						documentPresets: {
							textDocumentRelated: 'id_PO',
							goToTooltip: 'goToPurchaseOrder',
							linkTo:
								URLS.PO_DETAIL +
								'?order=' +
								this.props.documentReferenceId +
								'&customer=' +
								this.props.customerRFC
						}
					})
				} else if (this.props.referenceType) {
					switch (this.props.referenceType) {
						case Constants.DOCUMENT_TYPE.PURCHASE_ORDER:
						case Constants.DOCUMENT_TYPE.CUSTOMER_PURCHASE_ORDER:
							textDocumentRelated = 'id_PO'
							goToTooltip = 'goToPurchaseOrder'
							linkTo =
								URLS.PO_DETAIL +
								'?order=' +
								this.props.documentReferenceId +
								'&customer=' +
								this.props.customerRFC
							break
						case Constants.DOCUMENT_TYPE.CONSUMPTION:
							textDocumentRelated = 'consumptionTitle'
							goToTooltip = 'goToConsumption'
							linkTo =
								URLS.CONSUMPTION_DETAIL +
								'?consumption=' +
								this.props.documentReferenceId +
								'&customer=' +
								this.props.customerRFC
							break
						case Constants.DOCUMENT_TYPE.REQUEST_FOR_CREDIT_MEMO:
							textDocumentRelated = 'rfcmTitle'
							goToTooltip = 'goToRfcm'
							linkTo =
								URLS.RFCM_DETAIL +
								'?requestforcreditmemo=' +
								this.props.documentReferenceId +
								'&customer=' +
								this.props.customerRFC
							break
					}
					this.setState({
						documentPresets: {
							textDocumentRelated: textDocumentRelated,
							goToTooltip: goToTooltip,
							linkTo: linkTo
						}
					})
				}
			}
		}
	}

	queryDocumentForSelectedDocumentType(action, search) {
		if (this.state.documentTypeActual || this.props.referenceType) {
			const client = this.state.client
			let query
			let variables
			if (action !== 'documentReferenceExist') {
				query =
					this.state.documentTypeActual === Constants.DOCUMENT_TYPE.PURCHASE_ORDER
						? SEARCH_PURCHASE_ORDERS_FOR_INVOICE
						: this.props.referenceType === Constants.DOCUMENT_TYPE.REQUEST_FOR_CREDIT_MEMO
							? cmQueries.REQUEST_FOR_CREDIT_MEMOS_FOR_CM
							: SEARCH_CONSUMPTIONS_FOR_INVOICE
				variables = {
					search_text: search,
					customer: this.props.rfcCustomerInDocument
				}
			} else {
				query =
					this.state.documentTypeActual === Constants.DOCUMENT_TYPE.PURCHASE_ORDER
						? PURCHASE_ORDERS_FOR_INVOICE
						: this.props.referenceType === Constants.DOCUMENT_TYPE.REQUEST_FOR_CREDIT_MEMO
							? cmQueries.REQUEST_FOR_CREDIT_MEMO
							: CONSUMPTION
				variables = {
					id: search,
					customer: this.props.rfcCustomerInDocument
				}
			}
			client
				.query({
					query,
					variables
				})
				.then((result) => {
					if (action === 'documentReferenceExist') {
						let associatedDocumentData = {}
						if (result.data)
							for (let object in result.data) {
								associatedDocumentData = result.data[object]
							}
						let items = associatedDocumentData?.items || undefined
						if (!this.loadedInformation) {
							this.loadedInformation = true
							this.props.documentAssociate(this.props.documentReferenceId, items)
						}
						this.setState({ finishSearch: true })
					} else {
						let documentForSelectedDocumentType =
							this.state.documentTypeActual === Constants.DOCUMENT_TYPE.PURCHASE_ORDER
								? result.data.SearchPurchaseOrdersForInvoice
								: this.props.referenceType === Constants.DOCUMENT_TYPE.REQUEST_FOR_CREDIT_MEMO
									? result.data.RequestForCreditMemosForCM
									: result.data.ConsumptionsForInvoice
						this.setState({
							documentForSelectedDocumentType
						})
					}
				})
				.catch((error) => {
					console.error(error)
					const errorCode = showErrorComponent(error)
					if (!expiredToken(errorCode)) {
						this.setState({
							errorModal: true,
							errorCode: errorCode
						})
					}
				})
		}
	}

	removeAssociation = () => {
		// TODO: When refactoring, add an spinner here
		const documentId = getUrlParameter(
			this.props.referenceType === Constants.DOCUMENT_TYPE.REQUEST_FOR_CREDIT_MEMO
				? 'creditmemo'
				: 'invoice'
		)
		const client = this.state.client
		const queryToRemoveAssociation =
			this.props.referenceType === Constants.DOCUMENT_TYPE.REQUEST_FOR_CREDIT_MEMO
				? cmQueries.REMOVE_ASSOCIATION
				: REMOVE_ASSOCIATION
		client
			.mutate({
				mutation: queryToRemoveAssociation,
				variables: {
					uuid: documentId
				}
			})
			.then(() => {
				this.props.documentAssociate()
				this.props.refreshDocumentData()
				this.setState({
					showChangeDocumentModal: false,
					removeDocumentModal: false,
					documentAssociateField: false,
					searchDocument: undefined
				})
				// TODO: Stop spinner here
			})
			.catch((error) => {
				console.error(error)
				const errorCode = showErrorComponent(error)
				if (!expiredToken(errorCode)) {
					this.setState({
						errorModal: true,
						errorCode: errorCode
					})
				}
				// TODO: Stop spinner here
			})
	}

	suggestDocument(event) {
		if (this.state.documentTypeActual === Constants.DOCUMENT_TYPE.PURCHASE_ORDER) {
			this.queryDocumentForSelectedDocumentType('purchaseOrderSearch', event.query.toLowerCase())
		} else if (this.props.referenceType === Constants.DOCUMENT_TYPE.REQUEST_FOR_CREDIT_MEMO) {
			this.queryDocumentForSelectedDocumentType('rfcmSearch', event.query.toLowerCase())
		} else {
			this.queryDocumentForSelectedDocumentType('consumptionSearch', event.query.toLowerCase())
		}
	}

	selectedDocument(selectDocument) {
		if (selectDocument) {
			this.props.documentAssociate(selectDocument.value.id, selectDocument.value.items)
			this.setState({ documentAssociateField: true })
		} else {
			if (this.props.documentSelected || this.props.documentReferenceId) {
				this.setState({
					removeDocumentModal: true
				})
			}
		}
	}

	getDocument() {
		if (this.state.searchDocument !== undefined) {
			return this.state.searchDocument
		} else {
			if (this.props.documentReferenceId && !this.state.finishSearch) {
				this.loadedInformation = false
				this.queryDocumentForSelectedDocumentType(
					'documentReferenceExist',
					this.props.documentReferenceId
				)
			}
			return (
				this.props.documentReferenceId &&
				`${Formatter.id(this.props.documentReferenceId)} - ${
					this.props.customerReference ? this.props.customerReference : i18next.t('NAtext')
				}`
			)
		}
	}

	itemTemplate(selectDocument) {
		return (
			<div>{`${Formatter.id(selectDocument.id)} - ${
				selectDocument.customer_reference ? selectDocument.customer_reference : i18next.t('NAtext')
			}`}</div>
		)
	}

	selectedItemTemplate(selectDocument) {
		return selectDocument.id
			? `${Formatter.id(selectDocument.id)} - ${
					selectDocument.customer_reference
						? selectDocument.customer_reference
						: i18next.t('NAtext')
				}`
			: Formatter.id(selectDocument.id)
	}

	removeDocumentAssociation() {
		if (this.props.documentSelected || this.props.documentReferenceId) {
			this.setState({
				removeDocumentModal: true
			})
		}
	}

	showRemoveAssociationButton() {
		if (this.props.documentReferenceId || this.state.documentAssociateField === true) {
			return (
				<img
					className={styles.removePurchaseOrderAssociationImg}
					src={closeIcon}
					alt=""
					onClick={() => this.removeDocumentAssociation()}
				/>
			)
		}
	}

	showDocumentAssociation() {
		let disabled = false
		let styleDisabled
		let borderAutoComplete
		if (this.props.associateCreditNote) {
			const disableAutoComplete =
				this.props.documentReferenceId || this.state.documentAssociateField === true
			if (disableAutoComplete) {
				disabled = true
				styleDisabled = 'background-light-gray'
				borderAutoComplete = 'border-change'
			}
			return (
				<Translation>
					{(t) => (
						<div className="searchPurchaseOrder">
							{!disableAutoComplete && (
								<p className={styles.documentTypeText}>{t('associateCreditMemo')}</p>
							)}
							<AutoComplete
								className={classNames(
									styles.inputTextSearchPurchaseOrderAutocomplete,
									borderAutoComplete
								)}
								inputClassName={classNames(
									styles.inputTextSearchPurchaseOrder,
									styleDisabled
								)}
								placeholder={t('textSearch')}
								value={this.getDocument()}
								onChange={(e) => {
									this.setState({
										searchDocument: e.value
									})
								}}
								suggestions={this.state.documentForSelectedDocumentType || []}
								completeMethod={this.suggestDocument.bind(this)}
								onSelect={this.selectedDocument.bind(this)}
								minLength={1}
								delay={500}
								onClear={() => this.selectedDocument(undefined)}
								itemTemplate={this.itemTemplate.bind(this)}
								selectedItemTemplate={this.selectedItemTemplate.bind(this)}
								inputStyle={{ border: 'none', opacity: 1 }}
								disabled={disabled}
							/>
							{this.showRemoveAssociationButton()}
						</div>
					)}
				</Translation>
			)
		} else {
			const reference = `${Formatter.id(this.props.documentReferenceId)} - ${
				this.props.customerReference ? this.props.customerReference : i18next.t('NAtext')
			}`
			return (
				<Translation>
					{(t) => (
						<>
							<AssociationItem
								label={t(
									this.props.referenceType ===
										Constants.DOCUMENT_TYPE.REQUEST_FOR_CREDIT_MEMO
										? 'rfcmTitle'
										: ''
								)}
								url={this.state.documentPresets.linkTo}
								dataTip={t(this.state.documentPresets.goToTooltip)}
								data={this.props.documentReferenceId ? reference : ''}
								disabled={!this.props.documentReferenceId}
							/>
							{this.props.statusCredit === Constants.STATUS.ACCOUNTED && (
								<>
									<AssociationItem
										label={t('invoiceText')}
										url={`${URLS.INVOICE_DETAIL}?invoice=${this.props.invoiceDetail.uuid}`}
										dataTip={t('goToInvoice')}
										data={
											this.props.invoiceDetail
												? getSerieFolio(
														this.props.invoiceDetail.serie,
														this.props.invoiceDetail.folio
													)
												: ''
										}
									/>
									{this.props.invoiceDetail.purchase_order && (
										<AssociationItem
											label={t('PURCHASE_ORDER')}
											url={`${URLS.PO_DETAIL}?order=${this.props.invoiceDetail.purchase_order.id}&customer=${this.props.invoiceDetail.purchase_order.customer.rfc}`}
											dataTip={t('goToPurchaseOrder')}
											data={
												this.props.invoiceDetail
													? Formatter.getInvoiceIdRelated(
															this.props.invoiceDetail.purchase_order.id,
															this.props.invoiceDetail.purchase_order
																.customer_reference
														)
													: ''
											}
										/>
									)}
									{this.props.invoiceDetail.consumption && (
										<AssociationItem
											label={t('CONSUMPTION')}
											url={`${URLS.CONSUMPTION_DETAIL}?consumption=${this.props.invoiceDetail.consumption.id}&customer=${this.props.invoiceDetail.consumption.customer.rfc}`}
											dataTip={t('goToConsumption')}
											data={
												this.props.invoiceDetail
													? Formatter.getInvoiceIdRelated(
															this.props.invoiceDetail.consumption.id,
															this.props.invoiceDetail.consumption
																.customer_reference
														)
													: ''
											}
										/>
									)}
								</>
							)}
						</>
					)}
				</Translation>
			)
		}
	}

	changeDocumentType(value) {
		if (this.props.documentSelected) {
			this.setState({
				showChangeDocumentModal: true,
				newDocumentTypeSelected: value
			})
		} else {
			this.setState({
				showChangeDocumentModal: false,
				documentTypeActual: value
			})
			this.props.documentTypeSelected(value)
		}
	}

	closeRemoveAssociation = () => {
		this.setState({
			removeDocumentModal: false,
			searchDocument: Formatter.id(this.props.documentSelected)
		})
	}

	render() {
		return (
			<Translation>
				{(t) => (
					<div
						className={classNames(
							styles.grayColorTextGeneralInfo,
							styles.containerSelectPurchaseOrder,
							this.state.POassociation
						)}
					>
						<div
							className={classNames(
								styles.titleContainerSelectPurchaseOrder,
								this.props.titleContainerClass || ''
							)}
						>
							<p className={styles.titleSelectPurchaseOrder}>{t('associatedTo')}</p>
						</div>
						<div className={styles.containerSearchPurchaseOrder}>
							{this.showDocumentAssociation()}
						</div>
						<RemoveAssociation
							open={this.state.removeDocumentModal}
							onClose={this.closeRemoveAssociation}
							onAccept={this.removeAssociation}
						/>
						<ErrorModal open={this.state.errorModal} errorCode={this.state.errorCode} />
					</div>
				)}
			</Translation>
		)
	}
}

export default ContainerAssociationDocument
