import cancel from '@netcurio/frontend-assets/src/icons/cancel.svg'
import {
	calendarLanguage,
	dateFormatter,
	getDateFormatByLanguage,
	getDatePlaceholderByLanguage,
	parseDateWLanguage
} from '@netcurio/frontend-common'
import { NetcurioLoaderSpinnerContext, TextArea } from '@netcurio/frontend-components'
import { AutoComplete } from 'primereact/autocomplete'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'

import React, { Component } from 'react'
import { render } from 'react-dom'
import { Trans, Translation } from 'react-i18next'
import { beforeUnloadListener } from '../../utilities/beforeUnloadListener'
import { connection } from '../../utilities/connection'
import { showErrorComponent } from '../../utilities/errorCode'
import { errorModal } from '../../utilities/errorModal'
import { expiredToken } from '../../utilities/expiredToken'
import * as Queries from '../../views/purchaseOrderEdit/queries'
import styles from './dialogModal.module.scss'
import MainModal from './mainModal'

interface DialogModalProps {
	fillModal: any
	acceptActionModal: any
	hideModal: any
	language: string
}

interface DialogModalState {
	colorCancel: string
	colorAccept: string
	rejectOrderBody: string
	rejectBox: string
	modalType: string
	reasonsSuggestions: any
	reason: any
	existReason: boolean
	colorAcceptDisabled: string
	client: any
	allReasons: any
	showDefaultSetUp: boolean
	showErrorSetUp: boolean
	showPaymentInvoiceSetUp: boolean
	datePaymentActivateButton: boolean
	buttonDisabled: boolean
	paymentDate: any
	bankReference: any
	buttonsConfirmedWithPriceModified: string
	motiveChange: string
	reasonConfirm: string
	errorModal: boolean
	showErrorStyles: string
	errorCode: string
	currentReasonsList: any
	selectedReason: any
	selectedDate: any
}

class DialogModal extends Component<DialogModalProps, DialogModalState> {
	private contextType = NetcurioLoaderSpinnerContext
	private dateFormat: string
	private placeholderFormat: string
	private mounted = false

	constructor(props: DialogModalProps) {
		super(props)
		const client = connection()
		this.state = {
			colorCancel: 'cancel-button',
			colorAccept: 'accept-button',
			rejectOrderBody: '',
			rejectBox: '',
			modalType: this.props.fillModal[0].getActionModal(),
			reasonsSuggestions: null,
			reason: null,
			existReason: false,
			colorAcceptDisabled: '',
			client,
			allReasons: null,
			showErrorSetUp: false,
			showDefaultSetUp: true,
			showPaymentInvoiceSetUp: false,
			datePaymentActivateButton: false,
			buttonDisabled: false,
			paymentDate: undefined,
			bankReference: undefined,
			buttonsConfirmedWithPriceModified: '',
			motiveChange: '',
			reasonConfirm: '',
			errorModal: false,
			showErrorStyles: '',
			errorCode: '',
			currentReasonsList: [],
			selectedReason: undefined,
			selectedDate: undefined
		}
		this.dateFormat = getDateFormatByLanguage(this.props.language)
		this.placeholderFormat = getDatePlaceholderByLanguage(this.props.language)
	}

	getAllReasons() {
		const generalSpinner = document.getElementById('generalSpinner')
		if (generalSpinner) {
			generalSpinner.style.display = 'block'
			const client = this.state.client
			client
				.query({
					query: Queries.PO_REASONS_REJECT,
					variable: {
						language: ''
					}
				})
				.then((result: any) => {
					if (this.mounted) {
						const allReasons = result.data.PurchaseOrderRejectedReasons
						if (allReasons.length === 1) {
							this.selectReason(allReasons[0])
						}
						this.setState({ allReasons })
						generalSpinner.style.display = 'none'
					}
				})
				.catch((error: any) => {
					console.log(error)
					const errorCode = showErrorComponent(error)
					if (!expiredToken(errorCode)) {
						this.setState({
							errorModal: true,
							errorCode: errorCode
						})
					}
					generalSpinner.style.display = 'none'
				})
		}
	}

	searchReasons(event: any) {
		const results = this.state.allReasons.filter((reason: { value: string }) => {
			return reason.value.toLowerCase().includes(event.query.toLowerCase())
		})
		this.setState({ currentReasonsList: results })
		return results
	}

	selectReason(reason: any) {
		if (reason === undefined) {
			this.setState({
				selectedReason: reason,
				existReason: false,
				colorAcceptDisabled: '  accept-disabled',
				showErrorStyles: 'border-red-input'
			})
		} else {
			this.setState({
				selectedReason: reason,
				existReason: true,
				colorAcceptDisabled: '',
				showErrorStyles: ''
			})
		}
	}

	eventMouseOption(optionButton: string, event: string) {
		if (event === 'over') {
			if (optionButton === 'cancel') {
				this.setState({
					colorCancel: ' colorCancelOver '
				})
			} else if (optionButton === 'confirm') {
				this.setState({
					colorAccept: 'colorAcceptOver '
				})
			}
		} else if (event === 'leave') {
			if (optionButton === 'cancel') {
				this.setState({
					colorCancel: 'cancel-button'
				})
			} else if (optionButton === 'confirm') {
				this.setState({
					colorAccept: 'accept-button'
				})
			}
		} else if (event === 'down' && this.state.existReason) {
			this.setState({ colorAccept: 'accept-button-active' })
		}
	}

	acceptAction() {
		if (this.state.modalType !== 'changePoType' && this.state.modalType !== 'cleanList') {
			beforeUnloadListener('remove')
		}
		if (this.state.modalType === 'rejectOrder') {
			if (this.state.existReason) {
				this.props.acceptActionModal(
					this.props.fillModal[0].getActionAccept(),
					this.state.selectedReason
				)
			} else {
				this.setState({ showErrorStyles: 'border-red-input' })
			}
		} else if (this.state.modalType === 'invoicesPayment') {
			this.props.acceptActionModal(
				this.props.fillModal[0].getActionAccept(),
				this.state.paymentDate,
				this.state.bankReference
			)
		} else if (this.state.modalType === 'confirmModalWithPriceModified') {
			this.props.acceptActionModal(this.props.fillModal[0].getActionAccept(), this.state.motiveChange)
		} else if (this.state.modalType === 'rejectModalWithReason') {
			this.props.acceptActionModal(this.props.fillModal[0].getActionAccept(), this.state.motiveChange)
		} else if (this.state.modalType === 'rejectModalWithReasonTicket') {
			this.props.acceptActionModal(this.props.fillModal[0].getActionAccept(), this.state.motiveChange)
		} else {
			this.props.acceptActionModal(this.props.fillModal[0].getActionAccept())
		}
	}

	closeModal() {
		this.props.hideModal(this.props.fillModal[0].getActionAccept())
	}

	selectType() {
		switch (this.state.modalType) {
			case 'confirmModal':
				break
			case 'rejectOrder':
				return (
					<div>
						<Trans>
							<p className={'rejectionReason'}>reasonReject</p>
						</Trans>
						<div className={'select-reason-container'}>
							<Translation>
								{(t) => (
									<AutoComplete
										value={this.state.selectedReason}
										onChange={(e) => this.setState({ selectedReason: e.value })}
										onSelect={(e) => this.selectReason(e.value)}
										onClear={() => this.selectReason(undefined)}
										suggestions={this.state.currentReasonsList}
										completeMethod={this.searchReasons.bind(this)}
										dropdown={true}
										minLength={3}
										placeholder={t('reasonSelector')}
										field={'value'}
										inputClassName={'select-reason-style'}
										inputStyle={{
											border: 'none'
										}}
										className={
											'autocomplete-rejected-reasons ' + this.state.showErrorStyles
										}
									/>
								)}
							</Translation>
						</div>
					</div>
				)
				break
			case 'cancelInvoiceEdit':
				break
			case 'sendInvoice':
				break
			case 'errorAssociation':
				break
			case 'invoicesPayment':
				return (
					<Translation>
						{(t) => (
							<div className={'payment-section'}>
								<div className={'box-question-modal date-modal-text'}>
									{t('datePaymentText')}
								</div>
								<div>
									<Calendar
										value={this.state.selectedDate}
										onChange={(e) => this.selectDate(e)}
										className={'calendar-modal-payment'}
										inputClassName={'width-100 input-modal-height'}
										dateFormat={this.dateFormat}
										placeholder={this.placeholderFormat}
										locale={calendarLanguage}
										inputStyle={{
											border: 'none'
										}}
									/>
								</div>
								<div>
									<div className={'box-question-modal bank-modal-text'}>
										{t('bankPaymentText')}
									</div>
									<div>
										<InputText
											className={'bank-modal-payment'}
											placeholder={t('optional')}
											onChange={(e: any) => this.selectBank(e.target.value)}
											value={this.state.bankReference || ''}
										/>
									</div>
								</div>
							</div>
						)}
					</Translation>
				)
				break
			case 'confirmModalWithPriceModified':
				return (
					<Translation>
						{(t) => (
							<div className={'containerTextarea'}>
								<TextArea
									placeholder={t('placehorlderMotiveChange')}
									maxLength={120}
									rows={3}
									onChangeText={(e) => this.setMotiveChange(e)}
									readValue={this.state.motiveChange}
									errorMessage={
										this.state.motiveChange !== '' ? '' : this.state.showErrorStyles
									}
								/>
							</div>
						)}
					</Translation>
				)
			case 'rejectModalWithReason':
				return (
					<Translation>
						{(t) => (
							<div className={'containerTextarea'}>
								<TextArea
									maxLength={50}
									rows={3}
									placeholder={t('placeHolderRejectReason')}
									readValue={this.state.motiveChange}
									onChangeText={(e) => this.setMotiveChange(e)}
									errorMessage={
										this.state.motiveChange !== '' ? '' : this.state.showErrorStyles
									}
								/>
							</div>
						)}
					</Translation>
				)
			case 'rejectModalWithReasonTicket':
				return (
					<Translation>
						{(t) => (
							<div className={'containerTextareaTicket'}>
								<label>{t('closingReasonTitle')}</label>
								<TextArea
									placeholder={'placeHolderRejectReasonTicket'}
									maxLength={80}
									rows={3}
									readValue={this.state.motiveChange}
									onChangeText={(e) => this.setMotiveChange(e)}
									errorMessage={
										this.state.motiveChange !== '' ? '' : this.state.showErrorStyles
									}
								/>
							</div>
						)}
					</Translation>
				)
			case 'rejectRFQ':
				break
		}
		return null
	}

	componentDidMount() {
		this.mounted = true
		if (this.state.modalType === 'rejectOrder') this.getAllReasons()
		if (this.mounted === true) {
			if (this.state.modalType === 'rejectOrder') {
				const rejectOrderBody = document.getElementById('rejectOrderBody')
				if (rejectOrderBody) {
					rejectOrderBody.style.height = '240px'
				}
				const rejectBox = document.getElementById('rejectBox')
				if (rejectBox) {
					rejectBox.style.height = '240px'
				}
				this.setState({
					colorAcceptDisabled: ' accept-disabled'
				})
			} else if (this.state.modalType === 'errorAssociation') {
				this.setState({
					showErrorSetUp: true,
					showDefaultSetUp: false,
					showPaymentInvoiceSetUp: false
				})
			} else if (this.state.modalType === 'invoicesPayment') {
				this.setState({
					buttonDisabled: true,
					showPaymentInvoiceSetUp: true,
					colorAcceptDisabled: ' accept-disabled',
					datePaymentActivateButton: false,
					rejectBox: 'invoicePaymentModal',
					rejectOrderBody: 'invoicePaymentModalGeneral'
				})
			} else if (this.state.modalType === 'confirmModalWithPriceModified') {
				this.setState({
					buttonsConfirmedWithPriceModified: 'heightButtons',
					reasonConfirm: 'bodyConfirmModalWithPriceModified',
					buttonDisabled: true,
					colorAcceptDisabled: ' accept-disabled'
				})
			} else if (this.state.modalType === 'rejectModalWithReason') {
				this.setState({
					buttonsConfirmedWithPriceModified: 'heightButtons',
					reasonConfirm: 'bodyConfirmModalWithPriceModified',
					buttonDisabled: true,
					colorAcceptDisabled: ' accept-disabled'
				})
			} else if (this.state.modalType === 'rejectModalWithReasonTicket') {
				this.setState({
					buttonsConfirmedWithPriceModified: 'heightButtons',
					reasonConfirm: 'bodyConfirmModalWithPriceModified',
					buttonDisabled: true,
					colorAcceptDisabled: ' accept-disabled'
				})
			}
		}
	}

	showButtons() {
		if (this.state.showDefaultSetUp) {
			return (
				<div className={'box-buttons ' + this.state.buttonsConfirmedWithPriceModified}>
					<div className={'box-confirm'}>
						<button
							className={'button-modal ' + this.state.colorCancel}
							onClick={() => this.closeModal()}
							onMouseOver={() => this.eventMouseOption('cancel', 'over')}
							onMouseLeave={() => this.eventMouseOption('cancel', 'leave')}
						>
							<Trans>
								<p className={'text-button'}>{this.props.fillModal[0].getTextCancel()}</p>
							</Trans>
						</button>
						<button
							className={
								'button-modal ' + this.state.colorAccept + this.state.colorAcceptDisabled
							}
							disabled={this.state.buttonDisabled}
							onClick={() => this.acceptAction()}
							onMouseOver={() => this.eventMouseOption('confirm', 'over')}
							onMouseLeave={() => this.eventMouseOption('confirm', 'leave')}
							onMouseDown={() => this.eventMouseOption('confirm', 'down')}
						>
							<Trans>
								<p className={'text-button'}>{this.props.fillModal[0].getTextAction()}</p>
							</Trans>
						</button>
					</div>
				</div>
			)
		} else if (this.state.showErrorSetUp) {
			return (
				<div className={'box-buttons-error'}>
					<div className={'box-confirm'}>
						<button
							className={
								'button-modal ' + this.state.colorAccept + this.state.colorAcceptDisabled
							}
							onClick={() => this.acceptAction()}
							onMouseOver={() => this.eventMouseOption('confirm', 'over')}
							onMouseLeave={() => this.eventMouseOption('confirm', 'leave')}
							onMouseDown={() => this.eventMouseOption('confirm', 'down')}
						>
							<Trans>
								<p className={'text-button'}>{this.props.fillModal[0].getTextAction()}</p>
							</Trans>
						</button>
					</div>
				</div>
			)
		}
		return null
	}

	contentTitle() {
		if (this.state.showErrorSetUp === true) {
			return (
				<Trans>
					<p className={'content-title-modal'}>{this.props.fillModal[0].getContentTitle()}</p>
				</Trans>
			)
		} else {
			return null
		}
	}

	selectBank(number: string) {
		if (number.trim() !== '') {
			this.setState({ bankReference: number })
		} else {
			this.setState({ bankReference: undefined })
		}
	}

	selectDate(date: any) {
		if (typeof date.value === 'object') {
			this.setState({
				paymentDate: date.value,
				selectedDate: dateFormatter.format(date.value.getTime()),
				colorAcceptDisabled: '',
				buttonDisabled: false
			})
		} else {
			const message = parseDateWLanguage(date.value, '')
			if (date.value.length < 11 || message.textError !== '') {
				this.setState({
					datePaymentActivateButton: false,
					colorAcceptDisabled: '  accept-disabled',
					buttonDisabled: true,
					paymentDate: undefined
				})
			} else {
				this.setState({
					datePaymentActivateButton: true,
					colorAcceptDisabled: '',
					buttonDisabled: false,
					paymentDate: date
				})
			}
			if (message) {
				this.setState({ selectedDate: message.newValue })
			}
		}
	}

	questionText() {
		return (
			<Trans>
				<p className={'box-question-modal'}>{this.props.fillModal[0].getQuestion()}</p>
			</Trans>
		)
	}

	headerTitleModal() {
		return (
			<div className={'box-title-modal'}>
				<Trans>
					<p className={'text-title-modal'}>{this.props.fillModal[0].getTitle()}</p>
				</Trans>
				<img
					src={cancel}
					alt="cancel"
					onClick={() => this.closeModal()}
					className={styles.cancelIcon}
				/>
			</div>
		)
	}

	setMotiveChange(event: string) {
		if (event !== '') {
			this.setState({
				colorAcceptDisabled: '',
				buttonDisabled: false,
				motiveChange: event,
				showErrorStyles: ''
			})
		} else {
			this.setState({
				buttonDisabled: true,
				colorAcceptDisabled: ' accept-disabled',
				motiveChange: '',
				showErrorStyles: 'emptyFieldError'
			})
		}
	}

	componentDidUpdate() {
		this.showModal()
	}

	componentWillUnmount() {
		this.mounted = false
	}

	showModal() {
		const parentModal = document.getElementById('parentModalError')
		let element: any = ''
		if (this.state.errorModal === true) {
			element = (
				<MainModal
					fillModal={errorModal(this.state.errorCode, undefined)}
					errorCode={this.state.errorCode}
					errorModalShow={true}
				/>
			)
		}
		if (parentModal) {
			render(element, parentModal)
		}
	}

	render() {
		return (
			<div
				id="rejectOrderBody"
				className={'gray-color-text-general-info body-modal ' + this.state.rejectOrderBody}
			>
				{this.headerTitleModal()}
				<div
					id="rejectBox"
					className={
						'box-question-response-modal ' + this.state.rejectBox + this.state.reasonConfirm
					}
				>
					<div>
						{this.contentTitle()}
						{this.questionText()}
					</div>
					<div>{this.selectType()}</div>
					{this.showButtons()}
				</div>
			</div>
		)
	}
}

export default DialogModal
