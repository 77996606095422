import {
	ColorBaseGraySilver,
	ColorBaseWhite,
	MdRefPaletteErrorAMain,
	NetcurioRefPrimaryAMain
} from '@netcurio/frontend-design-tokens/dist/_variables'
import { Invoice, InvoiceValidationEnum, PaymentComplement } from '../../../../types'
import { Validations } from '../../../../types/Validations'
import Constants from '../../../../utilities/constants'

export const defaultFileViewer = { open: false, titleText: '', fileSrc: '' }
export const defaultValuesInvoice: Invoice = {
	uuid: '',
	serie: '',
	folio: '',
	status: {
		key: '',
		value: ''
	},
	sender: {},
	receiver: {},
	reference_type: undefined,
	reference: undefined,
	purchase_order: undefined,
	consumption: undefined,
	uploaded_by: {},
	payment_method: '',
	currency: '',
	subtotal: 0,
	ieps: 0,
	iva: 0,
	total: 0,
	date: undefined,
	proposed_payment_date: undefined,
	payment_date: 0,
	payment_way: undefined,
	type: undefined,
	error: undefined,
	updated_at: 0,
	items: [],
	date_voided: undefined,
	modified_by_customer: undefined,
	modified_by_supplier: undefined,
	bank_reference: undefined,
	date_payment_registered: undefined,
	date_canceled: undefined,
	created_at: undefined,
	payment_complements: [],
	comments: [],
	invoice_documents: []
}

export const themeButtonReview = {
	marginLeft: '2.2rem',
	color: Constants.COLORS.REVIEW_BUTTON,
	fontWeight: '600'
}

export const defaultPaymentSelected: PaymentComplement = {
	uuid: '',
	sender: { name: '', rfc: '' },
	receiver: { name: '', rfc: '' },
	uploaded_by: { name: '', lastname: '', email: '' },
	created_at: undefined,
	status: { key: '', value: '' },
	void_reason: '',
	payments: [],
	comments: []
}

export const themePaymentComplementButton = {
	backgroundColor: NetcurioRefPrimaryAMain,
	color: `${ColorBaseWhite} !important`,
	'&:hover': {
		backgroundColor: NetcurioRefPrimaryAMain
	}
}

export const themePaymentComplementButtonDisabled = {
	backgroundColor: ColorBaseGraySilver,
	color: `${ColorBaseWhite} !important`,
	'&:hover': {
		backgroundColor: ColorBaseGraySilver
	}
}

export const defaultValuesValidations: Validations = {
	accountingPeriodValidation: { value: InvoiceValidationEnum.OK },
	currencyValidation: { positions: [], value: InvoiceValidationEnum.OK },
	priceValidation: { positions: [], value: InvoiceValidationEnum.OK },
	quantityValidation: { positions: [], value: InvoiceValidationEnum.OK },
	taxValidation: { positions: [], value: InvoiceValidationEnum.OK },
	missingMerchandiseValidation: { positions: [], value: InvoiceValidationEnum.OK },
	satValidation: { value: InvoiceValidationEnum.OK }
}

export const themeIconRowError = {
	cursor: 'pointer',
	color: MdRefPaletteErrorAMain
}
