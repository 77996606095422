import netcurioLogo from '@netcurio/frontend-assets/src/images/secondaryBlueNavyLogo.svg'
import coverImage from '@netcurio/frontend-assets/src/imagesNewDesign/landingPageLogin.jpg'
import coverImageWebp from '@netcurio/frontend-assets/src/imagesNewDesign/landingPageLogin.webp'
import { URLS } from '@netcurio/frontend-common'
import {
	NetcurioButton,
	NetcurioGrid,
	NetcurioTextField,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import { useQuery } from '@tanstack/react-query'
import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { fetchEnvironment } from '../../api/fetch-environment'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { loadRecaptchaScript } from '../../utilities/load-re-captcha-script'
import { RegisterCompanyModal } from '../registerCompanyModal/RegisterCompanyModal'
import styles from './passwordRecovery.module.scss'
import * as queries from './queries'

export const PasswordRecovery = () => {
	const history = useHistory()
	const { t } = useTranslation()
	const client = connection()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [userEmail, setUserEmail] = useState('')
	const [borderRedEmail, setBorderRedEmail] = useState(false)
	const [errorMessageResetPass, setErrorMessageResetPass] = useState('')
	const [pleaseCheck, setPleaseCheck] = useState(false)
	const { data: environment, isSuccess } = useQuery({
		queryKey: ['environment'],
		queryFn: fetchEnvironment,
		suspense: true,
		staleTime: Infinity
	})

	useEffect(() => {
		if (isSuccess && environment?.RECAPTCHA_KEY) loadRecaptchaScript(environment.RECAPTCHA_KEY)
	}, [isSuccess, environment])

	function clickInput(borderRemove: string) {
		if (borderRemove === 'borderEmail') {
			setBorderRedEmail(false)
		}
	}

	function redirectToResetPassword(token) {
		location.href = URLS.RESET_PASSWORD + '?token=' + token
	}

	function redirectToLogin() {
		history.push(URLS.LOGIN)
	}

	async function submitEmailForPassRecovery(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()
		if (!userEmail) {
			setErrorMessageResetPass('fieldNoFillText')
			setBorderRedEmail(true)
			return false
		}
		if (!userEmail.match(Constants.REGEX.EMAIL)) {
			setErrorMessageResetPass('emailNoValidText')
			setBorderRedEmail(true)
			setPleaseCheck(false)
			return false
		}
		setErrorMessageResetPass('')
		showLoadingSpinner()
		Auth.forgotPassword(userEmail)
			.then(() => {
				client
					.mutate({
						mutation: queries.GENERATE_RESET_PASSWORD_TOKEN,
						variables: {
							email: userEmail
						}
					})
					.then((result) => {
						redirectToResetPassword(result.data.generateResetPasswordToken)
					})
					.catch(() => {
						setErrorMessageResetPass('failedText')
						setPleaseCheck(false)
					})
			})
			.catch(() => {
				setErrorMessageResetPass('failedText')
				hideLoadingSpinner()
			})
			.finally(() => {
				hideLoadingSpinner()
			})
	}

	return (
		<NetcurioGrid container alignContent="flex-start" justifyContent="flex-start" className={styles.body}>
			<NetcurioGrid item xs={1} />
			<NetcurioGrid item xs={2}>
				<form onSubmit={submitEmailForPassRecovery}>
					<div className={styles.buttonsPosition}>
						<div>
							<img src={netcurioLogo} className={styles.logo} alt="Netcurio" />
						</div>
						<div className={styles.spaceBetweenTitle}>
							<span className={styles.loginLabel}>{t('forgottenPassTitle')}</span>
						</div>
						<div>
							<p className={styles.infoPasswordText}>{t('infoPasswordText')}</p>
						</div>
						<div className={styles.paddingTopMedium}>
							<NetcurioTextField
								error={borderRedEmail}
								size="small"
								fullWidth
								label={t('placeholderUserName')}
								value={userEmail}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setUserEmail(e.target.value.replace(' ', ''))
									clickInput('borderEmail')
								}}
							/>
						</div>
						{errorMessageResetPass && (
							<div>
								<p className={styles.msgerrorP}>
									{t(errorMessageResetPass)}
									<br />
									{t(pleaseCheck ? 'PleaseCheck' : undefined)}
								</p>
							</div>
						)}
						<div className={styles.paddingTopMedium}>
							<NetcurioButton fullWidth variant="contained" size="small" type="submit">
								{t('resetPassText')}
							</NetcurioButton>
						</div>
						<div className={styles.paddingTopXS}>
							<NetcurioButton
								fullWidth
								variant="text"
								onClick={() => redirectToLogin()}
								size="small"
							>
								{t('cancelText')}
							</NetcurioButton>
						</div>
					</div>
				</form>
			</NetcurioGrid>
			<NetcurioGrid item xs={1} />
			<NetcurioGrid item xs={8}>
				<link rel="preload" as="image" href={coverImageWebp} />
				<picture className={styles.coverImage}>
					<source type="image/webp" srcSet={coverImageWebp} />
					<source type="image/png" srcSet={coverImage} />
					<img src={coverImage} alt="" />
				</picture>
			</NetcurioGrid>
			<RegisterCompanyModal />
		</NetcurioGrid>
	)
}
