import React, { ReactNode, forwardRef, useEffect, useState } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import TextField from '@mui/material/TextField'
import { SxProps, Theme, ThemeOptions } from '@mui/material/styles'
import {
	ColorBaseWhite,
	MdRefTypefaceFont,
	MdSysLabelMediumSize,
	NetcurioRefActionCDisabled,
	NetcurioRefPrimaryBDark,
	NetcurioRefPrimaryCLight,
	NetcurioRefWarningCLight
} from '@netcurio/frontend-design-tokens/dist/_variables'

const heights = {
	medium: {
		standard: '4.8rem',
		outlined: '5.6rem',
		filled: '5.6rem'
	},
	small: {
		standard: '4.5rem',
		outlined: '4rem',
		filled: '4.8rem'
	},
	smaller: {
		standard: '4rem',
		outlined: '3.2rem',
		filled: '4.2rem'
	}
}

export const NetcurioNumericFieldThemeOptions: ThemeOptions = {
	components: {
		MuiInputBase: {
			styleOverrides: {
				formControl: {
					fontSize: MdSysLabelMediumSize,
					backgroundColor: ColorBaseWhite
				},
				input: {
					fontFamily: MdRefTypefaceFont,
					fontSize: MdSysLabelMediumSize
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontFamily: MdRefTypefaceFont,
					fontSize: MdSysLabelMediumSize
				}
			}
		}
	}
}

interface NetcurioNumericFormatBase {
	decimalScale?: number
	prefix?: string
	suffix?: string
	thousandSeparator?: string
	maxLimit?: number
	minLimit?: number
	allowNegative?: boolean
}

interface NetcurioNumericFormatProps extends NetcurioNumericFormatBase {
	onChange: (event: { target: { value: string } }) => void
}

interface NetcurioNumericFieldProps extends NetcurioNumericFormatBase {
	id?: string
	autoFocus?: boolean
	disabled?: boolean
	error?: boolean
	fullWidth?: boolean
	helperText?: ReactNode
	label?: React.ReactNode
	multiline?: boolean
	placeholder?: string
	rows?: string | number
	maxRows?: string | number
	minRows?: string | number
	value?: unknown
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	type?: undefined | 'password'
	adornment?: ReactNode
	adornmentPosition?: 'end' | 'start'
	size?: 'small' | 'medium'
	variant?: 'standard' | 'filled' | 'outlined'
	height?: 'small' | 'medium' | 'smaller'
	warning?: boolean
}

const NetcurioNumericFormat = forwardRef<NumericFormatProps, NetcurioNumericFormatProps>(
	function NumericFormatCustom(props, ref) {
		const { onChange, thousandSeparator = ',', maxLimit, minLimit, ...other } = props

		return (
			<NumericFormat
				{...other}
				getInputRef={ref}
				onValueChange={(values) => {
					onChange({
						target: {
							value: values.value
						}
					})
				}}
				thousandSeparator={thousandSeparator}
				valueIsNumericString
				isAllowed={(values) => {
					const { floatValue } = values
					if (maxLimit !== undefined && floatValue > maxLimit) {
						return false
					}

					if (minLimit !== undefined && floatValue < minLimit) {
						return false
					}
					return true
				}}
			/>
		)
	}
)

/**
 * NetcurioTextField
 * @param decimalScale <number>: If defined, it limits the number of digits after the decimal point.
 * @param prefix <string>: Adds the prefix character before the input value.
 * @param suffix <string>: Adds the suffix after the input value
 * @param suffix thousandSeparator <string>: Thousand separator, @default `,`
 * @param maxLimit <number>: Maximun allowed number
 * @param minLimit <number>: Minium allowed number
 * @param allowNegative: <boolean>: If set to `false`, negative numbers will not be allowed
 * @param id <string>: The id of the `input` element.
 * Use this prop to make `label` and `helperText` accessible for screen readers.
 * @param autoFocus <boolean>: If `true`, the `input` element is focused during the first mount.
 * @default false
 * @param disabled <boolean>: If `true`, the component is disabled.
 * @default false
 * @param error <boolean>: If `true`, the label is displayed in an error state.
 * @default false
 * @param fullWidth <boolean>: If `true`, the input will take up the full width of its container.
 * @default false
 * @param helperText <ReactNode>: The helper text content. Also useful in validation.
 * @param label <ReactNode>: The label content.
 * @param multiline <boolean>: If `true`, a `textarea` element is rendered instead of an input.
 * @default false
 * @param placeholder <string>: The short hint displayed in the `input` before the user enters a value.
 * @param rows <string | number>: Number of rows to display when multiline option is set to true.
 * @param maxRows <string | number>: Maximum number of rows to display when multiline option is set to true.
 * @param minRows <string | number>: Minimum number of rows to display when multiline option is set to true.
 * @param value <unknown>: The value of the `input` element, required for a controlled component.
 * @param onChange <(event: React.ChangeEvent<HTMLInputElement>) => void>: Callback fired when the value is changed.
 * @param type <undefined | 'password'>: Set to 'password' to use a password field
 * @param size: <'small' | 'medium'>: The size of the component.
 * @param variant: <'standard' | 'filled' | 'outlined'>: The variant to use. @default 'outlined'
 * @param height: <'smallest
 * @returns component
 */
export const NetcurioNumericField = forwardRef<HTMLDivElement, NetcurioNumericFieldProps>((props, ref) => {
	const {
		decimalScale,
		prefix,
		suffix,
		thousandSeparator,
		maxLimit,
		minLimit,
		allowNegative,
		variant = 'outlined',
		height = 'smaller',
		warning,
		...rest
	} = props

	const [sxValue, setSxValue] = useState<SxProps<Theme>>({})

	useEffect(() => {
		if (warning) {
			setSxValue({
				'& .MuiInputLabel-root': {
					height: heights[height] ? heights[height] : '',
					color: NetcurioRefWarningCLight
				},
				'& .MuiInputBase-root': {
					height: heights[height] ? heights[height][variant] : '',
					borderColor: NetcurioRefWarningCLight
				},
				'& .MuiTextField-root': {
					height: heights[height] ? heights[height][variant] : '',
					borderColor: NetcurioRefWarningCLight
				},
				'& .MuiOutlinedInput-root': {
					height: heights[height] ? heights[height][variant] : '',
					'& fieldset': {
						borderColor: NetcurioRefWarningCLight
					},
					'&:hover fieldset': {
						borderColor: NetcurioRefWarningCLight
					}
				},
				'& .MuiOutlinedInput-input': {
					padding: '.6rem 1.4rem'
				},
				'& .MuiFilledInput-root': {
					height: heights[height] ? heights[height][variant] : ''
				},
				'& .MuiStandardInput-root': {
					height: heights[height] ? heights[height][variant] : ''
				},
				/* Shrinked Animated Label Section */
				'& .MuiInputLabel-shrink': {
					position: 'absolute',
					marginTop: '0.2rem',
					marginLeft: '0.2rem'
				},
				/* Disabled Inputs Section */
				'& .MuiInputBase-input.Mui-disabled': {
					WebkitTextFillColor: NetcurioRefActionCDisabled,
					borderColor: NetcurioRefActionCDisabled
				},
				'& .MuiOutlinedInput-root.Mui-disabled': {
					'& fieldset': {
						borderColor: NetcurioRefActionCDisabled
					}
				}
			})
		} else
			setSxValue({
				'& .MuiInputBase-root': {
					height: heights[height] ? heights[height][variant] : '',
					borderColor: NetcurioRefPrimaryCLight
				},
				'& .MuiTextField-root': {
					height: heights[height] ? heights[height][variant] : '',
					borderColor: NetcurioRefPrimaryCLight
				},
				'& .MuiOutlinedInput-root': {
					height: heights[height] ? heights[height][variant] : '',
					'& fieldset': {
						borderColor: NetcurioRefPrimaryCLight
					},
					'&:hover fieldset': {
						borderColor: NetcurioRefPrimaryBDark
					}
				},
				'& .MuiOutlinedInput-input': {
					padding: '.6rem 1.4rem'
				},
				'& .MuiFilledInput-root': {
					height: heights[height] ? heights[height][variant] : ''
				},
				'& .MuiStandardInput-root': {
					height: heights[height] ? heights[height][variant] : ''
				},
				/* Shrinked Animated Label Section */
				'& .MuiInputLabel-shrink': {
					position: 'absolute',
					marginTop: '0.2rem',
					marginLeft: '0.2rem'
				},
				/* Disabled Inputs Section */
				'& .MuiInputBase-input.Mui-disabled': {
					WebkitTextFillColor: NetcurioRefActionCDisabled,
					borderColor: NetcurioRefActionCDisabled
				},
				'& .MuiOutlinedInput-root.Mui-disabled': {
					'& fieldset': {
						borderColor: NetcurioRefActionCDisabled
					}
				}
			})
	}, [warning])

	const numericFormatValues: NetcurioNumericFormatBase = {
		decimalScale,
		prefix,
		suffix,
		thousandSeparator,
		maxLimit,
		minLimit,
		allowNegative
	}

	return (
		<TextField
			{...rest}
			sx={sxValue}
			ref={ref}
			variant={variant}
			InputProps={{
				inputComponent: NetcurioNumericFormat as any,
				inputProps: numericFormatValues,
				style: {
					paddingLeft: '1.4rem !important'
				}
			}}
		/>
	)
})

NetcurioNumericField.displayName = 'NetcurioNumericField'
