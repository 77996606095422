import { fillModal } from '../../components/dialogModal/types'

export enum ModalTypes {
	Error,
	SendAssociation,
	Void,
	Cancel
}

export interface ModalData {
	fillModal: fillModal[]
	acceptActionModal?(): void
}

export interface TrackInformationData {
	emission_date: string
	emissary: string
	emissary_rfc: string
	payment_method: string
	receptor: string
	receptor_rfc: string
	uploaded_date: string
	uploaded_by?: string
	serie_folio_uuid?: string
	invoice_reference?: string
	uuid: string
}

export interface ItemsTableData {
	position: number
	concept: string
	amount: number
	net_amount: string
	tax: string
	request_concept: JSX.Element | string
	error?: boolean
}

export interface StatusMessage {
	missingLink: boolean
	error: {
		key: boolean
		value: string
	}
}

export interface CreditMemoAssociationItem {
	position: number
	reference_position: number | string
}
