import React from 'react'
import styles from './styles.module.scss'
import { useHistory } from 'react-router-dom'
import { NetcurioTooltip, NetcurioIconButton, NetcurioIcons } from '@netcurio/frontend-components'
import classNames from 'classnames'

export const AssociationItem = ({ url, label, dataTip, data, disabled = false }) => {
	const history = useHistory()

	return (
		<>
			{label && <p className={styles.textSearchPurchaseOrder}>{label}</p>}
			<div
				className={classNames(
					styles.inputTextSearchPurchaseOrder,
					styles.containerPSearchPurchaseOrder
				)}
			>
				<p className={styles.searchPurchaseOrder}>{data}</p>
				<div className={styles.poLinkContainer}>
					<NetcurioTooltip title={disabled ? dataTip : ''} placement={'top'}>
						<div>
							<NetcurioIconButton
								disabled={disabled}
								onClick={() => history.push(url)}
								color="primary"
								hoverIcon={<NetcurioIcons.ArrowCircleRight />}
								restingIcon={<NetcurioIcons.ArrowCircleRightOutlined />}
							/>
						</div>
					</NetcurioTooltip>
				</div>
			</div>
		</>
	)
}
