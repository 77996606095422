import {
	PURCHASE,
	allowDecimals,
	calendarLanguage,
	dateFormatter,
	parseTimestampToTimeZone
} from '@netcurio/frontend-common'
import { NetcurioAutocomplete, NetcurioTooltip, PricesInformation } from '@netcurio/frontend-components'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Constants from '../../utilities/constants'
import Formatter from '../../utilities/formatter'
import './styles.scss'

import cancelIcon from '@netcurio/frontend-assets/src/icons/cancel-red.svg'
import cancelSolidRed from '@netcurio/frontend-assets/src/icons/cancel-solid-red.svg'
import checkSolidGreen from '@netcurio/frontend-assets/src/icons/check-solid-green.svg'
import checkSolidYellow from '@netcurio/frontend-assets/src/icons/check-solid-yellow.svg'
import checkBlue from '@netcurio/frontend-assets/src/icons/check_blue.svg'

export const TableRow = ({
	confirmedAllItemsProps,
	informationTable,
	dateCopyAllItemsProps,
	positionKey,
	purchaseOrderType,
	setReasonItem,
	setRowSelected,
	changeConfirmAmount,
	changeRequiredDateProps,
	fillConsecutiveDate,
	selectedRow,
	allReasons
}): ReactElement => {
	const { t } = useTranslation()
	const [confirmedAllItems, setConfirmedAllItems] = useState<boolean>(false)
	const [imgCheckAll, setImgCheckAll] = useState<string>('')
	const [checkConfirm, setCheckConfirm] = useState(checkBlue)
	const [checkCancel, setCheckCancel] = useState(cancelIcon)
	const [valueAmountConfirm, setValueAmountConfirm] = useState<number | string>('')
	const [valueRequiredData, setValueRequiredData] = useState<string>('')
	const [fillRejectField, setFillRejectField] = useState<boolean>()
	const [selectedReason, setSelectedReason] = useState(undefined)
	const [checkDate, setCheckDate] = useState<boolean>(false)
	const [placeholderNA, setPlaceholderNA] = useState<string>('')
	const minDate = Formatter.getDateForCalendar(new Date(parseTimestampToTimeZone(new Date())))
	const [isRejectedPosition, setIsRejectedPosition] = useState<boolean>(false)

	useEffect(() => {
		if (confirmedAllItemsProps) {
			if (!confirmedAllItems) {
				let valueRequired = ''
				let confirmCheckSolidGreen = ''
				if (informationTable.required_delivery_date) {
					const dateInTimezone = parseTimestampToTimeZone(informationTable.required_delivery_date)
					valueRequired = Formatter.getDateForCalendar(new Date(dateInTimezone))
					confirmCheckSolidGreen = checkSolidGreen
				}
				setImgCheckAll(confirmCheckSolidGreen)
				setCheckConfirm(checkSolidGreen)
				setCheckCancel(cancelIcon)
				setValueAmountConfirm(informationTable.requested_amount)
				setValueRequiredData(valueRequired)
				setConfirmedAllItems(true)
				setFillRejectField(false)
				setSelectedReason(undefined)
			}
		} else {
			if (confirmedAllItems) {
				setImgCheckAll('adjust-icon')
				setCheckConfirm(checkBlue)
				setCheckCancel(cancelIcon)
				setValueAmountConfirm('')
				setValueRequiredData('')
				setConfirmedAllItems(false)
				setFillRejectField(false)
				setSelectedReason(undefined)
			}
		}
		if (dateCopyAllItemsProps) {
			let delivery_proposal_date = ''
			if (informationTable.delivery_proposal_date) {
				const dateInTimezone = parseTimestampToTimeZone(informationTable.delivery_proposal_date)
				delivery_proposal_date = Formatter.getDateForCalendar(new Date(dateInTimezone))
			} else if (valueRequiredData !== '') {
				setValueRequiredData('')
				setCheckDate(false)
			}
			if (
				informationTable.delivery_proposal_date &&
				valueRequiredData.toString() !== delivery_proposal_date.toString()
			) {
				if (informationTable.confirmed_amount === informationTable.requested_amount) {
					setImgCheckAll(checkSolidGreen)
				} else if (selectedReason) {
					setImgCheckAll(checkSolidYellow)
				}
				setValueRequiredData(delivery_proposal_date)
				setCheckDate(true)
			}
		} else if (!informationTable.delivery_proposal_date && valueRequiredData !== '' && checkDate) {
			setValueRequiredData('')
			setCheckDate(false)
		}
	}, [
		confirmedAllItemsProps,
		dateCopyAllItemsProps,
		informationTable,
		valueRequiredData,
		selectedReason,
		confirmedAllItems,
		checkDate
	])

	const checkColorConfirmAmount = (amount, checkChange, reason) => {
		const choiceReason = reason === '' ? selectedReason : reason
		setIsRejectedPosition(!amount || amount === 0)
		if (amount < informationTable.requested_amount) {
			if (choiceReason && checkChange === 'imgCheckAll') {
				setImgCheckAll(checkSolidYellow)
				setCheckConfirm(checkSolidYellow)
				setCheckCancel(cancelIcon)
				setFillRejectField(true)
			} else {
				setCheckConfirm(checkSolidYellow)
				setFillRejectField(true)
				setCheckCancel(cancelIcon)
				setImgCheckAll('')
			}
		} else {
			if (checkChange === 'imgCheckAll') {
				setImgCheckAll(checkSolidGreen)
				setFillRejectField(false)
				setSelectedReason(undefined)
				setCheckConfirm(checkSolidGreen)
				setCheckCancel(cancelIcon)
			} else if (checkChange === 'checkConfirm') {
				setImgCheckAll('')
				setFillRejectField(false)
				setCheckConfirm(checkSolidGreen)
				setCheckCancel(cancelIcon)
				selectReason(undefined)
			}
			setReasonItem(undefined, positionKey)
		}
	}

	const fillCompareToError = (dateToCompare, reason) => {
		const choiceReason = reason === '' ? selectedReason : reason
		try {
			dateToCompare.getTime()
			if (choiceReason) {
				setImgCheckAll(cancelSolidRed)
			} else {
				setImgCheckAll('')
			}
		} catch {
			setImgCheckAll('')
		}
		setCheckConfirm(checkBlue)
		setCheckCancel(cancelSolidRed)
	}

	const setRejectedAmount = () => {
		fillCompareToError(valueRequiredData, '')
		setValueAmountConfirm('')
		setIsRejectedPosition(true)
		setFillRejectField(true)
		setPlaceholderNA('NAtext')
		if (selectedReason !== undefined) {
			setImgCheckAll(cancelSolidRed)
		}
		setRowSelected(positionKey)
		changeConfirmAmount(Constants.STATUS.REJECTED, positionKey)
	}

	const setConfirmedAmount = () => {
		try {
			setImgCheckAll(checkSolidGreen)
		} catch {
			setImgCheckAll('')
		}
		setCheckConfirm(checkSolidGreen)
		setCheckCancel(cancelIcon)
		setFillRejectField(false)
		setIsRejectedPosition(false)
		setValueAmountConfirm(informationTable.requested_amount)
		setSelectedReason(undefined)
		setRowSelected(positionKey)
		setReasonItem(undefined, positionKey)
		changeConfirmAmount(informationTable.requested_amount, positionKey)
	}

	const changeRequiredDate = (evt) => {
		if (evt.target.value) {
			if (valueAmountConfirm > 0) {
				try {
					evt.target.value.getTime()
					checkColorConfirmAmount(valueAmountConfirm, 'imgCheckAll', '')
				} catch {
					checkColorConfirmAmount(valueAmountConfirm, 'checkConfirm', '')
				}
			} else if (checkCancel === cancelSolidRed || imgCheckAll === cancelSolidRed) {
				fillCompareToError(evt.target.value, '')
			} else {
				setCheckConfirm(checkBlue)
			}
			setValueRequiredData(evt.target.value)
			changeRequiredDateProps(evt.target.value, positionKey)
		}
	}

	const changeConfirmedAmount = (evt) => {
		if (evt.target.value === '.') {
			evt.target.value = '0.'
		}
		if (allowDecimals(evt.target.value)) {
			setIsRejectedPosition(evt.target.value === '' || parseFloat(evt.target.value) === 0)
			if (parseFloat(evt.target.value) <= informationTable.requested_amount) {
				try {
					checkColorConfirmAmount(evt.target.value, 'imgCheckAll', '')
				} catch {
					checkColorConfirmAmount(evt.target.value, 'checkConfirm', '')
				}
				setValueAmountConfirm(evt.target.value)
				changeConfirmAmount(evt.target.value, positionKey)
			} else if (evt.target.value === '') {
				setImgCheckAll('')
				setCheckConfirm(checkBlue)
				setCheckCancel(cancelIcon)
				setFillRejectField(false)
				setValueAmountConfirm('')
				setSelectedReason(undefined)
				setPlaceholderNA('')
				setReasonItem(undefined, positionKey)
				changeConfirmAmount(evt.target.value, positionKey)
			}
		}
	}

	const selectReason = (reason) => {
		const reasonSelectorAutoComplete = document.querySelector('.select-reason-item' + positionKey)
		if (valueAmountConfirm > 0) {
			try {
				checkColorConfirmAmount(valueAmountConfirm, 'imgCheckAll', reason)
			} catch {
				checkColorConfirmAmount(valueAmountConfirm, 'checkConfirm', '')
			}
		} else if (checkCancel === cancelSolidRed || imgCheckAll === cancelSolidRed) {
			fillCompareToError(valueRequiredData, reason)
		}
		if (reason === undefined) {
			reasonSelectorAutoComplete.classList.add('input-text-field-error')
		} else {
			if (checkCancel === cancelSolidRed || imgCheckAll === cancelSolidRed) {
				setImgCheckAll(cancelSolidRed)
			}
		}
		setSelectedReason(reason)
		setReasonItem(reason, positionKey)
	}

	const dateCopyAllItems = () => {
		if (valueRequiredData) {
			fillConsecutiveDate(valueRequiredData, positionKey, checkDate)
			setCheckDate(!checkDate)
		}
	}

	const getReasonLabel = (reason) => `${reason.value}`

	const showInputConfirmDate = () => {
		if (isRejectedPosition) {
			return (
				<p className={'text-column-main-table-orderDetails reason-table proposalNaRowEditPO'}>
					{t('NAtext')}
				</p>
			)
		} else {
			return (
				<div className={'containerProposalDeliverDate'}>
					<Calendar
						placeholder={t('dateProposalDeliverText')}
						className={
							'text-column-main-table-orderDetails proposalDeliverDateEdit proposalDeliverDate' +
							positionKey
						}
						inputClassName={'proposalDeliverDateEditInput'}
						locale={calendarLanguage}
						dateFormat="dd/mm/yy"
						value={valueRequiredData}
						onChange={(evt) => changeRequiredDate(evt)}
						onFocus={() => setRowSelected(positionKey)}
						minDate={minDate}
						readOnlyInput={true}
						inputStyle={{ border: 'none' }}
					/>
					<NetcurioTooltip title={t('dateToAll')}>
						<div className={'checkbox-align display-block'}>
							<Checkbox onChange={() => dateCopyAllItems()} checked={checkDate} />
						</div>
					</NetcurioTooltip>
				</div>
			)
		}
	}

	const getRowStatusVisibility = () => {
		if (
			imgCheckAll !== checkSolidGreen &&
			imgCheckAll !== checkSolidYellow &&
			imgCheckAll !== cancelSolidRed
		) {
			return 'hide-container'
		} else {
			return 'imgCheckColumnPurchaseOrderEdit;'
		}
	}

	const showProductPrices = (net_value, reference_price, unit_price, price_change_reason, tax) => {
		if (purchaseOrderType.key !== PURCHASE.PURCHASE_ORDER_TYPES.CONSIGNMENT) {
			return (
				<div>
					<div className={'text-in-line'}>
						<p className={'multiline-info'}>{t('itemNetValue')}</p>
						<p className={'multiline-info'}>{Formatter.currency.format(net_value)}</p>
					</div>
					<div className={'display-content'}>
						<PricesInformation
							referencePrice={reference_price}
							unitPrice={unit_price}
							priceChangeReason={price_change_reason}
						/>
					</div>
					<NetcurioTooltip title={t('information')} placement={'right'}>
						<div className={'text-in-line width-IVA'}>
							<p className={'multiline-info'}>{t('ivaText')}</p>
							<p className={'multiline-info'}>{Formatter.percent.format(tax)}</p>
						</div>
					</NetcurioTooltip>
				</div>
			)
		}
	}
	let styleHeightRow
	if (purchaseOrderType.key === PURCHASE.PURCHASE_ORDER_TYPES.CONSIGNMENT) {
		styleHeightRow = 'min-height-edition'
	}
	return (
		<div
			id={'container-column-purchase-orderDetail' + positionKey}
			className={
				styleHeightRow +
				`${
					positionKey !== selectedRow ? ' mainContainerRowEditPO' : ''
				} container-column-purchase-orderDetail gray-color-text-general-info row-edit` +
				positionKey
			}
		>
			<div
				id={'leftBoxBlueEdit' + positionKey}
				className={`${positionKey === selectedRow ? 'leftBoxBlueEdit' : ''}`}
			/>
			<div
				id={'rightBoxBlueEdit' + positionKey}
				className={`${positionKey === selectedRow ? 'rightBoxBlueEdit' : ''}`}
			/>
			<p className={'text-column-main-table-orderDetails positionRowEditPO'}>
				{informationTable.position}
			</p>
			<div className={'text-column-main-table-orderDetails text-align-left product-row-edit-po'}>
				<div className={'text-in-line tooltip-description'}>
					<p className={'multiline-info'}>{t('itemDescription')}</p>
					<p className={'multiline-info itemDescriptionOverflow'}>{informationTable.name}</p>
					<div className={'tooltip-text-description'}>
						<p className={'tooltip-text'}>{informationTable.name}</p>
					</div>
				</div>
				<div className={'text-in-line'}>
					<p className={'multiline-info'}>{t('itemCode')}</p>
					<p className={'multiline-info'}>{informationTable.code}</p>
				</div>
				{showProductPrices(
					informationTable.net_value,
					informationTable.reference_price,
					informationTable.unit_price,
					informationTable.price_change_reason,
					informationTable.tax
				)}
			</div>
			<p className={'text-column-main-table-orderDetails storageRowEditPO'}>
				{t(Formatter.nullValueFormattingToNA(informationTable.storage_location))}
			</p>
			<p className={'text-column-main-table-orderDetails requestedRowEditPO'}>
				{informationTable.requested_amount + ' ' + informationTable.unit?.toUpperCase()}
			</p>
			<div className={'text-column-main-table-orderDetails confirm-row-edit-po'}>
				<div>
					<div className={'confirmAmount'}>
						<InputText
							keyfilter={'pnum'}
							className={'inputAmountConfirm confirmedAmount' + positionKey}
							value={valueAmountConfirm}
							onChange={(evt) => changeConfirmedAmount(evt)}
							onFocus={() => setRowSelected(positionKey)}
							placeholder={t(placeholderNA)}
						/>
						<div
							className={
								'containerButtonPartialConfirm showButtonsAmount' +
								positionKey +
								`${selectedRow === positionKey ? ' displayBlock' : ''}`
							}
						>
							<NetcurioTooltip title={t('confirmButtonText')} placement={'top'}>
								<img
									className={'buttons-table'}
									src={checkConfirm}
									alt=""
									onClick={() => setConfirmedAmount()}
								/>
							</NetcurioTooltip>
							<NetcurioTooltip title={t('rejectButtonText')} placement={'top'}>
								<img
									className={'buttons-table'}
									src={checkCancel}
									alt=""
									onClick={() => setRejectedAmount()}
								/>
							</NetcurioTooltip>
						</div>
					</div>
					{fillRejectField ? (
						<NetcurioAutocomplete
							fullWidth={true}
							label={t('reasonSelectorItem')}
							options={allReasons}
							variant="outlined"
							height="smaller"
							value={selectedReason}
							onSelectValue={(e) => selectReason(e)}
							getOptionLabel={getReasonLabel}
						/>
					) : null}
				</div>
			</div>
			<p className={'text-column-main-table-orderDetails requiredRowEditPO'}>
				{informationTable.required_delivery_date === null
					? 'NA'
					: dateFormatter.format(parseTimestampToTimeZone(informationTable.required_delivery_date))}
			</p>
			{showInputConfirmDate()}
			<div
				className={
					'imgCheckColumnPurchaseOrderEdit container-img-check-column-purchase-orderEdit ' +
					getRowStatusVisibility()
				}
			>
				<img
					src={imgCheckAll}
					className={'imgCheckSizePOEdit status-row' + positionKey}
					alt="check"
				/>
			</div>
		</div>
	)
}
