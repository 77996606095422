import { dateFormatter, parseTimestampToTimeZone } from '@netcurio/frontend-common'
import {
	NetcurioButton,
	NetcurioGrid,
	NetcurioIcons,
	NetcurioInputLabel,
	NetcurioMenuItem,
	NetcurioNumericField,
	NetcurioSelect,
	NetcurioSelectChangeEvent,
	NetcurioTextField,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import GlobalQueries from '../../../../../components/queries'
import { Currency, MeasurementUnit, Product } from '../../../../../types'
import { connection } from '../../../../../utilities/connection'
import { showErrorComponent } from '../../../../../utilities/errorCode'
import { expiredToken } from '../../../../../utilities/expiredToken'
import Formatter from '../../../../../utilities/formatter'
import { ErrorModal } from './errorModal'
import styles from './productDetail.module.scss'
import * as queries from './queries'
import { SaveChangesModal } from './saveChangesModal'

interface FormErrors {
	description: boolean
	currency: boolean
	unit_price: boolean
	unit_measure: boolean
}

export default function ProductDetail() {
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const { t } = useTranslation()
	const { code } = useParams() as { code: string }
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [supplier, id] = code.split('&')
	const [errorCode, setErrorCode] = useState<string>('')
	const [product, setProduct] = useState<Product>({})
	const [readOnly, setReadOnly] = useState<boolean>(true)
	const [doneLoading, setDoneLoading] = useState<boolean>(false)
	const [showMessageError, setShowMessageError] = useState<boolean>(false)
	const [showSaveChangesModal, setShowSaveChangesModal] = useState<boolean>(false)
	const [measurementUnits, setMeasurementUnits] = useState<MeasurementUnit[] | undefined>()
	const [currenciesArray, setCurrenciesArray] = useState<Currency[] | undefined>()
	const [newEan, setNewEan] = useState<string>('')
	const [newUnitMeasure, setNewUnitMeasure] = useState<string>('')
	const [newStatus, setNewStatus] = useState<boolean>()
	const [newUnitPrice, setNewUnitPrice] = useState<string>()
	const [newTaxIndicator, setNewTaxIndicator] = useState<string | number>()
	const [newCurrency, setNewCurrency] = useState<string>('')
	const [newDescription, setNewDescription] = useState<string>('')
	const [errors, setErrors] = useState<FormErrors>({
		description: false,
		currency: false,
		unit_price: false,
		unit_measure: false
	})

	useEffect(() => {
		getCurrencies()
		getMeasurementUnits()
		getProduct()
	}, [])

	useEffect(() => {
		if (product && measurementUnits && currenciesArray && product.unit_price) {
			setNewEan(product.ean)
			setNewUnitMeasure(product.unit)
			setNewStatus(product.is_active)
			setNewUnitPrice(product.unit_price.toString())
			setNewTaxIndicator(product.tax)
			setNewCurrency(product.currency)
			setNewDescription(product.description)
			setDoneLoading(true)
			hideLoadingSpinner()
		}
	}, [product, measurementUnits, currenciesArray])

	const getProduct = () => {
		showLoadingSpinner()
		client
			.query({
				query: queries.PRODUCT,
				variables: {
					id: id,
					supplier: supplier
				},
				fetchPolicy: 'no-cache'
			})
			.then((result) => {
				if (result.data.Product) {
					setProduct(result.data.Product)
				} else {
					hideLoadingSpinner()
					setErrorCode('PRODUCT_NOT_FOUND')
				}
			})
			.catch((error) => {
				handleError(error)
			})
	}

	const getMeasurementUnits = () => {
		showLoadingSpinner()
		client
			.query({
				query: GlobalQueries.MEASUREMENT_UNITS
			})
			.then((result) => {
				const items = result.data.MeasurementUnits
				const netcurioMeasurementUnits: any[''] = items.map((item) => (
					<NetcurioMenuItem value={item.code} key={item.code}>
						{item.description}
					</NetcurioMenuItem>
				))
				setMeasurementUnits(netcurioMeasurementUnits)
			})
			.catch((error) => {
				handleError(error)
			})
	}

	const getCurrencies = () => {
		showLoadingSpinner()
		client
			.query({
				query: GlobalQueries.CURRENCIES
			})
			.then((result) => {
				const items = result.data.Currencies
				const netcurioCurrencies: any[] = items.map((item) => (
					<NetcurioMenuItem value={item.code} key={item.code}>
						{item.description}
					</NetcurioMenuItem>
				))
				setCurrenciesArray(netcurioCurrencies)
			})
			.catch((error) => {
				handleError(error)
			})
	}

	const handleError = (error: ErrorEvent) => {
		console.error(error)
		const newErrorCode = showErrorComponent(error)
		expiredToken(newErrorCode)
		if (!expiredToken(newErrorCode)) {
			setErrorCode(newErrorCode)
		}
	}

	const validateForm = () => {
		const tempErrors = { ...errors }
		let isValidForm = true
		if (!newUnitMeasure) {
			isValidForm = false
			tempErrors.unit_measure = true
		} else if (!newUnitPrice || newUnitPrice == '0') {
			isValidForm = false
			tempErrors.unit_price = true
		} else if (!newCurrency) {
			isValidForm = false
			tempErrors.currency = true
		} else if (!newDescription) {
			isValidForm = false
			tempErrors.description = true
		} else {
			tempErrors.unit_measure = false
			tempErrors.unit_price = false
			tempErrors.currency = false
			tempErrors.description = false
			isValidForm = true
			setShowSaveChangesModal(true)
		}
		setErrors(tempErrors)
		setShowMessageError(!isValidForm)
	}

	function updateProductDetails() {
		showLoadingSpinner()
		const newUnitPricePositive = newUnitPrice ? parseFloat(newUnitPrice?.replace(/[-,]/g, '')) : 0
		const newEanHandler = newEan === '' ? null : newEan
		client
			.mutate({
				mutation: queries.MODIFY_PRODUCT,
				variables: {
					id: id,
					unit_price: newUnitPricePositive,
					is_active: newStatus,
					tax: newTaxIndicator,
					ean: newEanHandler,
					unit: newUnitMeasure,
					currency: newCurrency,
					description: newDescription
				}
			})
			.then(() => {
				setDoneLoading(false)
				getProduct()
				setShowSaveChangesModal(false)
				setDoneLoading(true)
				setReadOnly(true)
			})
			.catch((error) => {
				hideLoadingSpinner()
				console.error(error)
				const newErrorCode = showErrorComponent(error)
				expiredToken(newErrorCode)
				if (!expiredToken(newErrorCode)) {
					setErrorCode(newErrorCode)
				}
			})
	}

	const handleCancel = () => {
		getProduct()
		setShowMessageError(false)
		errors['unit_measure'] = false
		errors['unit_price'] = false
		errors['currency'] = false
		errors['description'] = false
		setReadOnly(true)
	}

	const handleEanChange = (event: NetcurioSelectChangeEvent) => {
		setNewEan(event.target.value)
	}

	const handleUnitMeasureChange = (event: NetcurioSelectChangeEvent) => {
		setNewUnitMeasure(event.target.value)
	}

	const handleStatusChange = (event: NetcurioSelectChangeEvent) => {
		if (event.target.value == 'ACTIVE') {
			setNewStatus(true)
		} else if (event.target.value == 'INACTIVE') {
			setNewStatus(false)
		}
	}

	const handleUnitPriceChange = (event: NetcurioSelectChangeEvent) => {
		setNewUnitPrice(event.target.value)
	}

	const handleTaxChange = (event: NetcurioSelectChangeEvent) => {
		setNewTaxIndicator(event.target.value)
	}

	const handleCurrencyChange = (event: NetcurioSelectChangeEvent) => {
		setNewCurrency(event.target.value)
	}

	const handleDescriptionChange = (event: NetcurioSelectChangeEvent) => {
		setNewDescription(event.target.value)
	}

	return doneLoading ? (
		<div className={styles.mainContainer}>
			<NetcurioGrid container className={styles.gridContainer}>
				<NetcurioGrid item container xs={12} width={'100%'} className={styles.productTitleSection}>
					<p className={styles.productName}>{product.description}</p>
					<div className={styles.activeTagContainer}>
						<div
							className={classNames(product.is_active ? styles.activeTag : styles.inactiveTag)}
						>
							{product.is_active ? (
								<NetcurioIcons.CheckCircle />
							) : (
								<NetcurioIcons.CancelOutlined />
							)}
							<p className={styles.marginHorizontal1rem}>
								{t(product.is_active ? 'ACTIVE' : 'INACTIVE')}
							</p>
						</div>
					</div>
				</NetcurioGrid>
				<NetcurioGrid item container xs={12} width={'100%'} className={styles.productDetailBody}>
					<NetcurioGrid item container xs={12} width={'100%'} className={styles.rowGrid}>
						<NetcurioGrid item xs={3} className={styles.singleCell}>
							<NetcurioInputLabel htmlFor="product-id" shrink={true}>
								{t('productID')}
							</NetcurioInputLabel>
							<NetcurioTextField
								id={'product-id'}
								value={product.id}
								disabled={true}
								fullWidth
							/>
						</NetcurioGrid>
						<NetcurioGrid item xs={3} className={styles.singleCell}>
							<NetcurioInputLabel htmlFor="ean_product" shrink={true}>
								{t('ean_product')}
							</NetcurioInputLabel>
							<NetcurioTextField
								id={'ean_product'}
								value={newEan}
								disabled={readOnly}
								onChange={handleEanChange}
								fullWidth
							/>
						</NetcurioGrid>
						<NetcurioGrid item xs={3} className={styles.singleCell}>
							<NetcurioInputLabel htmlFor="createdBy_product" shrink={true}>
								{t('createdBy_product')}
							</NetcurioInputLabel>
							<NetcurioTextField
								id={t('createdBy_product')}
								value={
									product.created_by
										? product.created_by.name + ' ' + product.created_by.lastname
										: ''
								}
								disabled={true}
								fullWidth
							/>
						</NetcurioGrid>
						<NetcurioGrid item xs={3} className={styles.singleCell}>
							<NetcurioInputLabel htmlFor="creationDate_product" shrink={true}>
								{t('creationDate_product')}
							</NetcurioInputLabel>
							<NetcurioTextField
								id={t('creationDate_product')}
								value={dateFormatter.format(parseTimestampToTimeZone(product.created_at))}
								disabled={true}
								fullWidth
							/>
						</NetcurioGrid>
					</NetcurioGrid>
					<NetcurioGrid item container xs={12} width={'100%'} className={styles.rowGrid}>
						<NetcurioGrid item xs={3} className={styles.singleCell}>
							<NetcurioInputLabel htmlFor="modifiedBy_product" shrink={true}>
								{t('modifiedBy_product')}
							</NetcurioInputLabel>
							<NetcurioTextField
								id={t('modifiedBy_product')}
								value={
									product.modified_by
										? product.modified_by.name + ' ' + product.modified_by.lastname
										: ''
								}
								disabled={true}
								fullWidth
							/>
						</NetcurioGrid>
						<NetcurioGrid item xs={3} className={styles.singleCell}>
							<NetcurioInputLabel htmlFor="lastUpdatedDate_product" shrink={true}>
								{t('lastUpdatedDate_product')}
							</NetcurioInputLabel>
							<NetcurioTextField
								id={t('lastUpdatedDate_product')}
								value={dateFormatter.format(parseTimestampToTimeZone(product.updated_at))}
								disabled={true}
								fullWidth
							/>
						</NetcurioGrid>
						<NetcurioGrid item xs={3} className={styles.singleCell}>
							<NetcurioInputLabel htmlFor="um_product" shrink={true}>
								{t('um_product')}
							</NetcurioInputLabel>
							<NetcurioSelect
								id={t('um_product')}
								value={newUnitMeasure}
								disabled={readOnly}
								children={measurementUnits}
								onChange={handleUnitMeasureChange}
								error={errors['unit_measure']}
								fullWidth
							/>
						</NetcurioGrid>
						<NetcurioGrid item xs={3} className={styles.singleCell}>
							<NetcurioInputLabel htmlFor="status_product" shrink={true}>
								{t('status_product')}
							</NetcurioInputLabel>
							<NetcurioSelect
								id={t('status_product')}
								value={newStatus ? 'ACTIVE' : 'INACTIVE'}
								disabled={readOnly}
								onChange={handleStatusChange}
								error={errors['is_active']}
								fullWidth
							>
								<NetcurioMenuItem value={'ACTIVE'}>{t('ACTIVE')}</NetcurioMenuItem>
								<NetcurioMenuItem value={'INACTIVE'}>{t('INACTIVE')}</NetcurioMenuItem>
							</NetcurioSelect>
						</NetcurioGrid>
					</NetcurioGrid>
					<NetcurioGrid item container xs={12} width={'100%'} className={styles.rowGrid}>
						<NetcurioGrid item xs={3} className={styles.singleCell}>
							<NetcurioInputLabel htmlFor="unitPrice_product" shrink={true}>
								{t('unitPrice_product')}
							</NetcurioInputLabel>
							<NetcurioNumericField
								id={t('unitPrice_product')}
								prefix="$ "
								decimalScale={2}
								value={newUnitPrice}
								disabled={readOnly}
								onChange={handleUnitPriceChange}
								error={errors['unit_price']}
								fullWidth
							/>
						</NetcurioGrid>
						<NetcurioGrid item xs={3} className={styles.singleCell}>
							<NetcurioInputLabel htmlFor="taxIndicator_product" shrink={true}>
								{t('taxIndicator_product')}
							</NetcurioInputLabel>
							<NetcurioSelect
								id={t('taxIndicator_product')}
								value={newTaxIndicator}
								disabled={readOnly}
								onChange={handleTaxChange}
								error={errors['tax']}
								fullWidth
							>
								<NetcurioMenuItem value={0}>{Formatter.percent.format(0)}</NetcurioMenuItem>
								<NetcurioMenuItem value={0.08}>
									{Formatter.percent.format(0.08)}
								</NetcurioMenuItem>
								<NetcurioMenuItem value={0.16}>
									{Formatter.percent.format(0.16)}
								</NetcurioMenuItem>
							</NetcurioSelect>
						</NetcurioGrid>
						<NetcurioGrid item xs={3} className={styles.singleCell}>
							<NetcurioInputLabel htmlFor="currency_product" shrink={true}>
								{t('currency_product')}
							</NetcurioInputLabel>
							<NetcurioSelect
								id={t('currency_product')}
								value={newCurrency}
								disabled={readOnly}
								children={currenciesArray}
								onChange={handleCurrencyChange}
								error={errors['currency']}
								fullWidth
							/>
						</NetcurioGrid>
						<NetcurioGrid item xs={3} className={styles.singleCell}></NetcurioGrid>
					</NetcurioGrid>
					<NetcurioGrid item container xs={12} width={'100%'} className={styles.descriptionSection}>
						<NetcurioInputLabel htmlFor="textDescription" shrink={true}>
							{t('textDescription')}
						</NetcurioInputLabel>
						<NetcurioTextField
							variant="outlined"
							height="xl"
							multiline
							rows={5}
							id={t('textDescription')}
							value={newDescription}
							error={errors['description']}
							disabled={readOnly}
							onChange={handleDescriptionChange}
							fullWidth
							maxLength={500}
						/>
					</NetcurioGrid>
					{showMessageError && (
						<div className={styles.missingFieldMessage}>{t('missingFields')}</div>
					)}
					<NetcurioGrid item container xs={12} width={'100%'} className={styles.buttonSection}>
						{readOnly ? (
							<NetcurioButton variant="outlined" onClick={() => setReadOnly(!readOnly)}>
								{t('editButton')}
							</NetcurioButton>
						) : (
							<div>
								<NetcurioButton
									variant="text"
									color="secondary"
									onClick={() => handleCancel()}
								>
									{t('cancelButton')}
								</NetcurioButton>
								<NetcurioButton
									variant="outlined"
									onClick={() => validateForm()}
									className={styles.saveButton}
								>
									{t('saveButton')}
								</NetcurioButton>
							</div>
						)}
					</NetcurioGrid>
				</NetcurioGrid>
			</NetcurioGrid>
			<SaveChangesModal
				open={showSaveChangesModal}
				close={() => setShowSaveChangesModal(false)}
				onAccept={() => updateProductDetails()}
			/>
			<ErrorModal open={!!errorCode} errorCode={errorCode} />
		</div>
	) : (
		<div></div>
	)
}
