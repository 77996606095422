import { Filter, Roles, URLS, User, getCurrentUser } from '@netcurio/frontend-common'
import {
	Header,
	NetcurioButton,
	NetcurioIcons,
	NetcurioTooltip,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import { Dayjs } from 'dayjs'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { NewInvoiceModal } from '../../../components/NewInvoiceModal/NewInvoiceModal'
import { useCompanySettings } from '../../../hooks/useCompanySettings'
import { connection } from '../../../utilities/connection'
import { showErrorComponent } from '../../../utilities/errorCode'
import { expiredToken } from '../../../utilities/expiredToken'
import { downloadExcelFile } from '../../../utilities/file-handling/download-excel-file'
import { FileDescriptor } from '../../../utilities/file-handling/file-descriptor'
import listHelper from '../../../utilities/listHelper'
import UserInformation from '../../../utilities/userInformation'
import { TableInvoices } from './TableInvoices'
import styles from './invoicesList.module.scss'
import { ErrorInvoiceArchivedModal } from './modals/ErrorInvoiceArchivedModal'
import { DataInvoice, DataInvoiceList, FilterToApply, Invoice, UpdateFilter } from './types'
import { invoicesListValues, themeButtonsWithIconHeaderSmall } from './utilities/defaultValues'
import {
	ARCHIVE_INVOICES,
	INVOICES_CUSTOMER,
	INVOICES_SUPPLIER,
	PIN_INVOICES,
	PURCHASE_ORDERS_ROWS,
	UNARCHIVE_INVOICES,
	UNPIN_INVOICES
} from './utilities/queries'

const initialSort = 'created_at'

export const InvoicesList: FC = () => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [showBarLoader, setShowBarLoader] = useState<boolean>()
	const history = useHistory()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const { t } = useTranslation()
	const [userRole, setUserRole] = useState<Roles>()
	const [invoiceList, setInvoiceList] = useState<DataInvoiceList>(invoicesListValues)
	const [activePagination, setActivePagination] = useState<boolean>(false)
	const [stopPagination, setStopPagination] = useState<boolean>(true)
	const [currentUser, setCurrentUser] = useState<User>(undefined)
	const [errorCodeModal, setErrorCodeModal] = useState<string>()
	const [uuidInvoices, setUuidInvoices] = useState<Set<string>>(new Set())
	const [undoArchivedCache, setUndoArchivedCache] = useState<Set<string>>(new Set())
	const [openInvoiceModal, setOpenInvoiceModal] = useState(false)
	const disabledButtonArchived = !(uuidInvoices.size > 0)
	const disabledButtonPin = !(uuidInvoices.size > 0)
	const lastTimeoutRef = useRef<ReturnType<typeof setTimeout>>()
	const [purchaseOrderHasRows, setPurchaseOrderHasRows] = useState<boolean>(false)
	const tableHasInvoices = Object.keys(invoiceList.dataInvoice).length > 0
	const isSupplier = userRole === Roles.SUPPLIER
	const isCustomer = userRole === Roles.CUSTOMER
	const { companySettings } = useCompanySettings({ rfc: currentUser?.company?.rfc ?? null })
	const companyHasStandAloneEnable = companySettings?.standalone_invoice_workflow_enabled
	const showInvoiceBtn = isSupplier || (isCustomer && companyHasStandAloneEnable)

	const queryGetPurchaseOrderList = () => {
		client
			.query({
				query: PURCHASE_ORDERS_ROWS,
				variables: {
					limit: 10
				},
				fetchPolicy: 'no-cache'
			})
			.then((result) => {
				if (result.data.PurchaseOrders.total > 0) {
					setPurchaseOrderHasRows(true)
				}
			})
			.catch(handleError)
	}

	useEffect(() => {
		setCurrentUser(getCurrentUser())
		const isMediumWindow = 600
		const containerHeightCompensation = 95
		const containerHeightSmall = 500
		const heightRow = 45
		const additionalRow = 2
		let finalHeight: number
		if (window.innerHeight > isMediumWindow) {
			finalHeight = window.innerHeight - containerHeightCompensation
		} else {
			finalHeight = containerHeightSmall
		}
		const numberRows: number = Math.round(finalHeight / heightRow) + additionalRow
		setUserRole(UserInformation.getCompanyRole())
		queryGetPurchaseOrderList()
		setInvoiceList((prevState: DataInvoiceList) => ({
			...prevState,
			limitRows: numberRows,
			rowsActual: numberRows
		}))
		showLoadingSpinner()
	}, [])

	useEffect(() => {
		if (invoiceList.limitRows) {
			filteringInvoices(0, initialSort, '', initialSort)
		}
	}, [invoiceList.limitRows])

	useEffect(() => {
		if (activePagination && stopPagination) {
			setActivePagination(false)
			setStopPagination(false)
			filteringInvoices(invoiceList.rowsActual, '', '', '', undefined, undefined, undefined, 'none')
		}
	}, [activePagination, stopPagination])

	const filteringInvoices = (
		skip: number,
		sortField: string,
		sortOrder: string,
		elementFilterActual: string,
		valueFilter = '',
		initRange = '',
		finalRange = '',
		filterRemove = ''
	) => {
		let fieldListData: string = invoiceList.fieldList
		let orderListData: string = invoiceList.orderList
		let columnFilterActual: string = invoiceList.actualFilterData
		let currentList: DataInvoice = { ...invoiceList.dataInvoice }

		if (sortOrder !== '') {
			orderListData = sortOrder
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				orderList: sortOrder
			}))
		}
		if (sortField) {
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				fieldList: sortField
			}))
			fieldListData = sortField
		}

		if (elementFilterActual !== '') {
			columnFilterActual = elementFilterActual
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				actualFilterData: elementFilterActual
			}))
		}

		let typeFilterActual: string
		let initRangeActual: number | Dayjs
		let finalRangeActual: number | Dayjs
		const valueFilterActual: string = valueFilter

		switch (columnFilterActual) {
			case 'seriefolio':
			case 'receiver':
			case 'sender':
			case 'reference':
			case 'payment_complement':
				typeFilterActual = 'wildcard'
				break
			case 'status':
				typeFilterActual = 'exact_match'
				break
			case 'created_at':
			case 'proposed_payment_date':
				typeFilterActual = 'date'
				initRangeActual = initRange
				finalRangeActual = finalRange
				break
			case 'total':
				typeFilterActual = 'numeric'
				initRangeActual = initRange
				finalRangeActual = finalRange
		}

		if (skip > 0) {
			const rowsActual: number = invoiceList.rowsActual + invoiceList.limitRows
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				rowsActual: rowsActual
			}))
		} else {
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				rowsActual: invoiceList.limitRows,
				dataInvoice: {}
			}))
			currentList = {}
		}

		const filterToApply: Array<Filter> = activeFilterToApply(
			typeFilterActual,
			valueFilterActual || undefined,
			initRangeActual || undefined,
			finalRangeActual || undefined,
			columnFilterActual,
			filterRemove || undefined,
			sortField
		)
		queryFilteringInvoice(fieldListData, orderListData, filterToApply, skip, currentList)
	}

	const activeFilterToApply = (
		typeFilterActual: string,
		valueFilterActual: string,
		initRangeActual: number | string,
		finalRangeActual: number | string,
		columnFilterActual: string,
		filterRemove: string,
		sortField: string
	): Array<Filter> => {
		const resultFiltersToApply: FilterToApply = listHelper.generateFiltersToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField,
			invoiceList.dataFilters,
			invoiceList.filtersOfTypeStatus
		) as FilterToApply

		const obj: UpdateFilter = resultFiltersToApply.objectForStateUpdate

		setInvoiceList((prevState: DataInvoiceList) => ({
			...prevState,
			dataFilters: obj.dataFilters,
			dataFiltersArray: obj.dataFiltersArray,
			deleteRange: obj.deleteRange,
			filterContainerBar: obj.filterContainerBar
		}))

		if (obj.filtersOfTypeStatus) {
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				filtersOfTypeStatus: obj.filtersOfTypeStatus
			}))
		}
		return resultFiltersToApply.filterToApply
	}

	const queryFilteringInvoice = (
		fieldListData: string,
		orderListData: string,
		filterToApply: Array<Filter>,
		skip: number,
		currentList: DataInvoice
	) => {
		const copyFilterToApply = JSON.parse(JSON.stringify(filterToApply))
		copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
		client
			.query({
				query: isCustomer ? INVOICES_CUSTOMER : INVOICES_SUPPLIER,
				variables: {
					limit: invoiceList.limitRows,
					skip: skip,
					archived: false,
					sort_field: fieldListData,
					sort_order: orderListData,
					filter: copyFilterToApply
				},
				fetchPolicy: 'no-cache'
			})
			.then(
				(
					result: ApolloQueryResult<{
						Invoices: {
							list: Array<Invoice>
							total: number
						}
					}>
				) => {
					let interCount = skip
					const data: DataInvoice = { ...currentList }
					const obj: Array<Invoice> = result.data.Invoices.list
					for (const keyName in obj) {
						interCount++
						data[interCount] = obj[keyName]
					}
					setInvoiceList((prevState: DataInvoiceList) => ({
						...prevState,
						totalRows: result.data.Invoices.total,
						filtersOfTypeStatus: {
							...prevState.filtersOfTypeStatus,
							status: {
								...prevState?.filtersOfTypeStatus?.status,
								dataFilter: prevState?.filtersOfTypeStatus?.status?.dataFilter || ''
							}
						},
						dataInvoice: data
					}))
					setStopPagination(true)
					hideLoadingSpinner()
					setShowBarLoader(false)
				}
			)
			.catch(handleError)
	}

	const handleError = (error: Error) => {
		const errorCode = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setInvoiceList((prevState: DataInvoiceList) => ({
				...prevState,
				dataInvoice: {},
				filtersOfTypeStatus: {
					...prevState.filtersOfTypeStatus,
					status: {
						...prevState.filtersOfTypeStatus.status,
						dataFilter: ''
					}
				}
			}))
			setStopPagination(true)
			setErrorCodeModal(errorCode)
			setShowBarLoader(false)
		}
		hideLoadingSpinner()
	}

	const closeFilterContainerBar = () => {
		listHelper.closeFilterContainerBar(filteringInvoices, initialSort)
		setInvoiceList((prevState: DataInvoiceList) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const deleteFilterActive = (indexObject: string) => {
		listHelper.deleteFilterActive(
			indexObject,
			invoiceList.dataFilters,
			closeFilterContainerBar,
			filteringInvoices
		)
		setInvoiceList((prevState: DataInvoiceList) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const downloadReportExcel = () => {
		showLoadingSpinner()
		const copyFilterToApply = JSON.parse(JSON.stringify(invoiceList.dataFiltersArray))
		copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
		const body: string = JSON.stringify({
			companyFilter: currentUser ? currentUser.company.rfc : undefined,
			filters: copyFilterToApply,
			archived: false,
			sort_field: invoiceList.fieldList,
			sort_order: invoiceList.orderList
		})
		listHelper
			.generateReportList(body, 'invoices')
			.then(async (res) => {
				if (res.ok) {
					res.json().then((responseJson: FileDescriptor) => {
						downloadExcelFile(responseJson)
					})
					hideLoadingSpinner()
				} else {
					const error = await res.json()
					handleError(error)
					hideLoadingSpinner()
				}
			})
			.catch((error: Error) => {
				handleError(error)
			})
	}

	const redirectToDetail = (UUID: string) => {
		sessionStorage.setItem('invoiceRegistered', 'true')
		if (isSupplier) {
			history.push(`${URLS.INVOICE_EDIT}?invoice=${UUID}`)
		} else if (isCustomer && companyHasStandAloneEnable) {
			history.push(`${URLS.INVOICE_DETAIL}?invoice=${UUID}`)
		}
	}

	const saveArchivedInvoices = (uuid?: string) => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: ARCHIVE_INVOICES,
				variables: {
					invoicesIds: uuid ? [uuid] : Array.from(uuidInvoices)
				}
			})
			.then(() => {
				setUndoArchivedCache((prevState) => (uuid ? new Set([...prevState, uuid]) : uuidInvoices))
				if (lastTimeoutRef.current) {
					clearTimeout(lastTimeoutRef.current)
				}
				lastTimeoutRef.current = setTimeout(() => {
					setUndoArchivedCache(new Set())
				}, 6000)
				setUuidInvoices(new Set())
				filteringInvoices(0, initialSort, '', initialSort)
			})
			.catch(handleError)
	}

	const handleUndoArchivedInvoices = () => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: UNARCHIVE_INVOICES,
				variables: {
					invoicesIds: Array.from(undoArchivedCache)
				}
			})
			.then(() => {
				setUndoArchivedCache(new Set())
				filteringInvoices(0, initialSort, '', initialSort)
			})
			.catch(handleError)
	}

	const handlePinInvoicesFeature = (invoices) => {
		showLoadingSpinner()
		/*
		 * Rules:
		 * - If any item is unpinned, pin all
		 * - If all items are pinned, unpin all
		 * */
		const hasUnpinnedInvoices = invoices.some((invoiceUUID) => {
			const invoiceKey = Object.keys(invoiceList.dataInvoice).find((key) => {
				return invoiceList.dataInvoice[key].uuid === invoiceUUID
			})
			return !invoiceList.dataInvoice[invoiceKey].pinned
		})
		const neededMethod = hasUnpinnedInvoices ? PIN_INVOICES : UNPIN_INVOICES
		client
			.mutate({
				mutation: neededMethod,
				variables: {
					invoicesIds: Array.from(invoices)
				}
			})
			.then(() => {
				setUuidInvoices(new Set())
				filteringInvoices(0, initialSort, '', initialSort)
			})
			.catch(handleError)
	}

	return (
		<main className={styles.containerMainViewList}>
			<div className={styles.containerViewList}>
				<Header>
					<div>
						{showInvoiceBtn && (
							<NetcurioButton
								variant="outlined"
								className={styles.btnHeader}
								disabled={!purchaseOrderHasRows}
								onClick={() => setOpenInvoiceModal(true)}
								endIcon={
									<NetcurioIcons.AddCircleOutline
										className={purchaseOrderHasRows ? '' : styles.disabledIcon}
									/>
								}
							>
								<span> {t('newInvoice')} </span>
							</NetcurioButton>
						)}
					</div>
					<div>
						<NetcurioTooltip title={t('pin')}>
							<div>
								<NetcurioButton
									variant="outlined"
									className={classNames(disabledButtonPin && styles.btnPinDisabledIcon)}
									size="small"
									sx={themeButtonsWithIconHeaderSmall}
									disabled={disabledButtonPin}
									onClick={() => {
										handlePinInvoicesFeature(Array.from(uuidInvoices))
									}}
								>
									<NetcurioIcons.PushPin />
								</NetcurioButton>
							</div>
						</NetcurioTooltip>
						<NetcurioTooltip title={t('archive')}>
							<div>
								<NetcurioButton
									variant="outlined"
									className={classNames(
										disabledButtonArchived && styles.btnArchivedDisabledIcon
									)}
									size="small"
									sx={themeButtonsWithIconHeaderSmall}
									disabled={disabledButtonArchived}
									onClick={() => saveArchivedInvoices()}
								>
									<NetcurioIcons.Archive />
								</NetcurioButton>
							</div>
						</NetcurioTooltip>
						<NetcurioButton
							variant="outlined"
							className={styles.btnHeader}
							onClick={() => history.push(URLS.INVOICE_LIST_ARCHIVED)}
							endIcon={<NetcurioIcons.TableRowsOutlined color="info" />}
							size="small"
						>
							<span> {t('seeArchived')} </span>
						</NetcurioButton>
						<NetcurioTooltip title={t('buttonExportTooltip')}>
							<div>
								<NetcurioButton
									variant="outlined"
									className={styles.btnHeader}
									disabled={!tableHasInvoices}
									onClick={downloadReportExcel}
									endIcon={
										<NetcurioIcons.Download
											color="info"
											className={tableHasInvoices ? '' : styles.disabledIcon}
										/>
									}
								>
									<span> {t('exportbtn')} </span>
								</NetcurioButton>
							</div>
						</NetcurioTooltip>
					</div>
				</Header>
				<TableInvoices
					dataInvoice={invoiceList.dataInvoice}
					filteringInvoices={filteringInvoices}
					rowsActual={invoiceList.rowsActual}
					totalRows={invoiceList.totalRows}
					userRole={userRole}
					filterContainerBar={invoiceList.filterContainerBar}
					closeFilterContainerBar={closeFilterContainerBar}
					dataFiltersArray={invoiceList.dataFiltersArray}
					deleteFilterActive={deleteFilterActive}
					deleteRange={invoiceList.deleteRange}
					filtersOfTypeStatus={invoiceList.filtersOfTypeStatus}
					setActivePagination={setActivePagination}
					uuidInvoices={uuidInvoices}
					handleUndoArchivedInvoices={handleUndoArchivedInvoices}
					undoArchivedCount={undoArchivedCache.size}
					setUuidInvoices={setUuidInvoices}
					toggleArchiveInvoices={saveArchivedInvoices}
					togglePin={handlePinInvoicesFeature}
					showBarLoader={showBarLoader}
					setShowBarLoader={setShowBarLoader}
				/>
			</div>
			<ErrorInvoiceArchivedModal open={!!errorCodeModal} errorCode={errorCodeModal} />
			<NewInvoiceModal
				open={openInvoiceModal}
				onClose={() => setOpenInvoiceModal(false)}
				redirectToDetail={redirectToDetail}
				isCustomer={isCustomer}
				companyHasStandAloneEnable={companyHasStandAloneEnable}
			/>
		</main>
	)
}
