import {
	dateFormatter,
	getSerieFolio,
	parseTimestampToTimeZone,
	PURCHASE,
	Roles,
	URLS
} from '@netcurio/frontend-common'
import { NetcurioCheckbox, NetcurioIcons, NetcurioTooltip } from '@netcurio/frontend-components'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import Constants from '../../../utilities/constants'
import Formatter from '../../../utilities/formatter'
import styles from './invoicesList.module.scss'
import { TableRowProps } from './types'

export const TableRow = ({
	informationTable: {
		uuid,
		serie,
		folio,
		status,
		total,
		created_at,
		payment_complements,
		proposed_payment_date,
		reference_type,
		consumption,
		purchase_order,
		sender,
		receiver,
		reference,
		pinned,
		advance,
		override_by
	},
	userRole,
	uuidInvoices,
	setUuidInvoices,
	toggleArchiveInvoices,
	togglePin
}: TableRowProps) => {
	const { t } = useTranslation()
	const dateToExpire: Date = new Date()
	const [checked, setChecked] = useState<boolean>(false)
	const [isArchivedInvoice, setIsArchiveInvoice] = useState<boolean>(false)
	const isCustomer: boolean = userRole === Roles.CUSTOMER
	const isConsumptionRelationship: boolean = reference_type === Constants.INVOICE.CONSUMPTION
	const idRelated: string = isConsumptionRelationship ? consumption?.id : purchase_order?.id
	const isArchivedList: boolean = useLocation().pathname === URLS.INVOICE_LIST_ARCHIVED
	const customerReference: string = isConsumptionRelationship
		? consumption?.customer_reference
		: purchase_order?.customer_reference
	const isSupplier = userRole === Roles.SUPPLIER
	const isMissingLink = status.key === Constants.LINKS.MISSING_LINK
	const hasStatusError = status.key === Constants.ERROR.ERROR

	const addInvoiceToArchive = () => {
		const tempSet: Set<string> = new Set(uuidInvoices)
		tempSet.add(uuid)
		setUuidInvoices(tempSet)
	}
	const deleteInvoiceToArchive = () => {
		const tempSetArchived: Set<string> = new Set(uuidInvoices)
		tempSetArchived.delete(uuid)
		setUuidInvoices(tempSetArchived)
	}

	const getContainerClassName = (): string => {
		const isInvalidStatus: boolean = status?.key
			? status.key === Constants.ERROR.ERROR || status.key === Constants.ERROR.ERROR
			: false

		const isScheduled: boolean = status.key === PURCHASE.PAYMENT_SCHEDULED
		const hasValidDate: boolean = proposed_payment_date < dateToExpire
		const isMissingLink: boolean = status.key === PURCHASE.MISSING_LINK

		const isMissingClassName: boolean =
			isInvalidStatus || isMissingLink || (isCustomer && isScheduled && hasValidDate)

		return isMissingClassName ? styles.containerInvoicesMissingLink : ''
	}

	const goTo = () => {
		if (isSupplier && (isMissingLink || hasStatusError)) {
			return `${URLS.INVOICE_EDIT}?invoice=${uuid}`
		} else {
			return `${URLS.INVOICE_DETAIL}?invoice=${uuid}`
		}
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked)
		if (event.target.checked) addInvoiceToArchive()
		else deleteInvoiceToArchive()
	}

	const handleChangeArchiveInvoice = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsArchiveInvoice(event.target.checked)
		if (event.target.checked) toggleArchiveInvoices(uuid)
	}

	return (
		<div
			className={classNames(
				styles.containerRowList,
				checked ? styles.containerInvoicesCheckedArchived : getContainerClassName()
			)}
		>
			<div className={classNames(styles.fieldInvoices, styles.tableFieldCheckBoxContainer)}>
				<NetcurioCheckbox
					key={uuid}
					size="small"
					checked={checked}
					onChange={handleChange}
					inputProps={{ 'aria-label': 'controlled' }}
					className={classNames(checked ? styles.checked : getContainerClassName())}
				/>
				{!isArchivedList && (
					<NetcurioTooltip title={pinned ? t('unpinned') : t('pin')} disableInteractive>
						<div className={classNames(pinned ? styles.pinned : '')}>
							<NetcurioCheckbox
								size="small"
								className={styles.iconHoverRow}
								iconFontSize="1.4rem"
								icon={<NetcurioIcons.PushPin />}
								checkedIcon={<NetcurioIcons.PushPin />}
								checked={pinned}
								onChange={() => {
									if (togglePin) togglePin([uuid])
								}}
							/>
						</div>
					</NetcurioTooltip>
				)}
				<NetcurioTooltip title={isArchivedList ? t('unarchived') : t('archive')} disableInteractive>
					<div>
						<NetcurioCheckbox
							size="small"
							className={styles.iconHoverRow}
							icon={isArchivedList ? <NetcurioIcons.Unarchive /> : <NetcurioIcons.Archive />}
							checkedIcon={
								isArchivedList ? (
									<NetcurioIcons.Unarchive color="info" />
								) : (
									<NetcurioIcons.Archive color="info" />
								)
							}
							checked={isArchivedInvoice}
							onChange={handleChangeArchiveInvoice}
						/>
					</div>
				</NetcurioTooltip>
			</div>
			<Link to={goTo()} className={styles.gridAreaColumnRow}>
				<p className={styles.fieldInvoices}>{getSerieFolio(serie, folio)}</p>
				<p className={styles.fieldInvoices}>{status.value}</p>
				<p className={styles.fieldInvoices}>{isCustomer ? sender.name : receiver.name}</p>
				<p className={styles.fieldInvoices}>
					{dateFormatter.format(parseTimestampToTimeZone(created_at))}
				</p>
				<div className={styles.fieldInvoices}>
					{proposed_payment_date === null ? (
						<p>{t(PURCHASE.PENDING)}</p>
					) : (
						dateFormatter.format(parseTimestampToTimeZone(proposed_payment_date))
					)}
				</div>
				<div className={styles.fieldInvoices}>
					{reference === null ? (
						<p>{t(PURCHASE.PENDING)}</p>
					) : (
						Formatter.getInvoiceIdRelated(idRelated, customerReference)
					)}
				</div>
				<div className={styles.fieldInvoices}>
					{payment_complements.length > 0 ? (
						<div className={styles.serieFolioComplementInvoices}>
							<p>{getSerieFolio(payment_complements[0].serie, payment_complements[0].folio)}</p>
						</div>
					) : (
						<div className={classNames(styles.serieFolioComplementInvoices)}>
							<div>{t(PURCHASE.PENDING)}</div>
						</div>
					)}
				</div>
				<p className={styles.fieldInvoices}>{Formatter.currency.format(total)}</p>
			</Link>
			<div className={styles.containerManualAuthorization}>
				{override_by ? (
					<NetcurioTooltip title={t('manuallyAuthorized')} disableInteractive>
						<NetcurioIcons.InfoOutlined className={styles.manualAuthorizationIcon} />
					</NetcurioTooltip>
				) : (
					<div className={styles.manualAuthorizationIcon}></div>
				)}
				{advance && (
					<NetcurioTooltip title={t('advance')} placement={'left'} disableInteractive>
						<NetcurioIcons.LocalOfferOutlined className={styles.manualAuthorizationIcon} />
					</NetcurioTooltip>
				)}
			</div>
		</div>
	)
}
