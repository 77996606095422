import { Roles, URLS, getSerieFolio } from '@netcurio/frontend-common'
import {
	FileViewerModal,
	NetcurioButton,
	NetcurioIcons,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import { Header } from '@netcurio/frontend-components/src'
import { useQuery } from '@tanstack/react-query'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { fetchEnvironment } from '../../api/fetch-environment'
import { CancelButton } from '../../components/HeaderButtons/CancelButton'
import { ExportButton } from '../../components/HeaderButtons/ExportButton'
import { CreditMemo, FileViewerObject, RequestForCreditMemoItem } from '../../types'
import { beforeUnloadListener } from '../../utilities/beforeUnloadListener'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { errorModal } from '../../utilities/errorModal'
import { expiredToken } from '../../utilities/expiredToken'
import { downloadXMLFile } from '../../utilities/file-handling/download-xml-file'
import { getUrlParameter } from '../../utilities/getUrlParameter'
import UserInformation from '../../utilities/userInformation'
import { CatchedErrorModal } from './Modals/CathchedErrorModal/CatchedErrorModal'
import { RejectPendingMessageCreditMemoDetail } from './Modals/RejectPendingMessageCreditMemoDetail/RejectPendingMessageCreditMemoDetail'
import { CancelCreditMemoModal } from './Modals/cancelCreditMemoModal'
import { SendCreditMemoModal } from './Modals/sendCreditMemoModal'
import { VoidCreditMemoModal } from './Modals/voidCreditMemo'
import styles from './creditMemoDetail.module.scss'
import { InformationContainer } from './informationContainer'
import { LateralColumn } from './lateralColumn'
import { CANCEL_CREDIT_MEMO, CREDIT_MEMO_ASSOCIATION, CREDIT_MEMO_DETAIL, VOID_CREDIT_MEMO } from './queries'
import { CreditMemoAssociationItem, ModalData, ModalTypes } from './types'

export const CreditMemoDetail = (): React.ReactElement => {
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const { data: environment } = useQuery({
		queryKey: ['environment'],
		queryFn: fetchEnvironment,
		suspense: true,
		staleTime: Infinity
	})
	const { S3_BUCKET } = environment ? environment : { S3_BUCKET: '' }

	const { CREDIT_MEMOS_FOLDER } = Constants.FOLDER
	const history = useHistory()
	const { t } = useTranslation()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const creditMemoId = useMemo(() => getUrlParameter('creditmemo'), [])
	const [creditMemoDetail, setCreditMemoDetail] = useState<CreditMemo>()
	const [errorCode, setErrorCode] = useState<string>()
	const [showSendModal, setShowSendModal] = useState<boolean>(false)
	const [showVoidModal, setShowVoidModal] = useState<boolean>(false)
	const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<string>()
	const [showModal, setShowModal] = useState<boolean>(false)
	const [modalType, setModalType] = useState<ModalTypes>()
	const [rfcmItems, setRfcmItems] = useState<RequestForCreditMemoItem[]>([])
	const [rfcmReference, setRfcmReference] = useState<string>()
	const [fileViewer, setFileViewer] = useState<FileViewerObject>({
		open: false,
		titleText: '',
		fileSrc: ''
	})
	const [existsPendingMessage, setExistsPendingMessage] = useState<boolean>(false)
	const [openRejectPendingMsgModal, setOpenRejectPendingMsgModal] = useState<boolean>(false)
	const [cmHasBeenSent, setCmHasBeenSent] = useState<boolean>(false)

	const userRole = useMemo(() => UserInformation.getCompanyRole(), [])
	const isEditionMode =
		userRole === Roles.SUPPLIER &&
		(creditMemoDetail?.status.key === Constants.LINKS.MISSING_LINK ||
			creditMemoDetail?.status.key === Constants.ERROR.ERROR)

	const getCreditMemoDetail = () => {
		showLoadingSpinner()
		client
			.query({
				query: CREDIT_MEMO_DETAIL,
				variables: {
					uuid: creditMemoId
				},
				fetchPolicy: 'no-cache'
			})
			.then((result: ApolloQueryResult<{ CreditMemo: CreditMemo }>) => {
				if (result.data.CreditMemo) {
					setCreditMemoDetail(result.data.CreditMemo)
					if (result.data.CreditMemo.reference) {
						setRfcmReference(result.data.CreditMemo.reference)
					}
				} else {
					const errorCode = showErrorComponent(undefined)
					setModalType(ModalTypes.Error)
					setErrorCode(errorCode)
					setShowModal(true)
				}
				hideLoadingSpinner()
			})
			.catch(handleError)
			.finally(() => {
				hideLoadingSpinner()
			})
	}

	const formatErrorMessage = (message: string) => {
		return message.split(':')[1]
	}

	const handleError = (error: Error) => {
		const newErrorCode = showErrorComponent(error)
		if (!expiredToken(newErrorCode)) {
			setModalType(ModalTypes.Error)
			setErrorCode(newErrorCode)
			if (error.message) setErrorMessage(formatErrorMessage(error.message))
			setShowModal(true)
		}
	}

	useEffect(() => {
		getCreditMemoDetail()
	}, [])

	const voidCreditMemo = (): void => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: VOID_CREDIT_MEMO,
				variables: {
					uuid: creditMemoId
				}
			})
			.then(() => {
				getCreditMemoDetail()
			})
			.catch(handleError)
			.finally(() => {
				setShowVoidModal(false)
				setShowModal(false)
				hideLoadingSpinner()
			})
	}

	const cancelCreditMemo = (): void => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: CANCEL_CREDIT_MEMO,
				variables: {
					uuid: creditMemoId
				}
			})
			.then(() => {
				getCreditMemoDetail()
				hideLoadingSpinner()
			})
			.catch(handleError)
			.finally(() => {
				setShowCancelModal(false)
				setShowModal(false)
				hideLoadingSpinner()
			})
	}

	const getModalData = (): ModalData => {
		switch (modalType) {
			case ModalTypes.Error:
				return { fillModal: errorModal(errorCode, errorMessage) }
			case ModalTypes.SendAssociation:
				setShowSendModal(true)
				return {
					fillModal: null,
					acceptActionModal: null
				}
			case ModalTypes.Void:
				setShowVoidModal(true)
				return {
					fillModal: null,
					acceptActionModal: null
				}
			case ModalTypes.Cancel:
				setShowCancelModal(true)
				return {
					fillModal: null,
					acceptActionModal: null
				}
		}
	}

	const saveAssociatedItems = () => {
		if (!creditMemoDetail) return

		showLoadingSpinner()
		setShowModal(true)
		setModalType(undefined)

		const items: Array<CreditMemoAssociationItem> = creditMemoDetail.items.map((cmItem) => {
			return {
				position: cmItem.position,
				reference_position: cmItem.reference_position
			}
		})

		const dataCreditMemo = {
			uuid: creditMemoDetail.uuid,
			RFCMId: rfcmReference,
			items: items
		}
		client
			.mutate({
				mutation: CREDIT_MEMO_ASSOCIATION,
				variables: dataCreditMemo
			})
			.then(() => {
				getCreditMemoDetail()
				hideLoadingSpinner()
			})
			.catch(handleError)
			.finally(() => {
				setCmHasBeenSent(true)
				setShowSendModal(false)
				hideLoadingSpinner()
			})
	}

	useEffect(() => {
		if (creditMemoDetail) {
			const { uuid, serie, folio } = creditMemoDetail
			const fileName = `${uuid.toLowerCase()}.pdf`
			const fileSrc = `${S3_BUCKET}${CREDIT_MEMOS_FOLDER}/${fileName}`
			const serieFolio = getSerieFolio(serie, folio)
			const titleText = `${t('creditMemo')} ${serieFolio}`
			setFileViewer((state) => ({ ...state, titleText, fileSrc }))
		}
	}, [creditMemoDetail, getSerieFolio, t, S3_BUCKET, CREDIT_MEMOS_FOLDER])

	useEffect(() => {
		if (showModal && modalType !== undefined) {
			getModalData()
		}
	}, [showModal])

	const sendAssociation = () => {
		setShowModal(true)
		setModalType(ModalTypes.SendAssociation)
	}

	const handleDocumentSelected = (rfcmId: string | undefined, items: RequestForCreditMemoItem[]) => {
		setRfcmReference(rfcmId)
		setRfcmItems(items || [])
		if (rfcmId === undefined) {
			beforeUnloadListener('remove')
		} else {
			beforeUnloadListener('add')
		}
	}

	const associateItems = (chosenItem: RequestForCreditMemoItem, creditMemoItemIndex: number) => {
		if (!creditMemoDetail) return

		const updatedItems = creditMemoDetail.items.map((item, i) =>
			i === creditMemoItemIndex
				? {
						...item,
						reference_position: chosenItem.position,
						request_for_credit_memo_item: chosenItem
					}
				: item
		)

		setCreditMemoDetail({
			...creditMemoDetail,
			items: updatedItems
		})
	}

	const hasAllReferenceLinked = () => {
		return creditMemoDetail?.items.every((item) => item.reference_position !== null)
	}

	const closePDFModal = () => setFileViewer((state) => ({ ...state, open: false }))

	const cancelButtonAction = () => {
		if (existsPendingMessage) {
			setOpenRejectPendingMsgModal(true)
		} else history.push(URLS.CREDIT_MEMO_LIST)
	}

	return (
		<div className={styles.creditMemoDetailContainer}>
			<Header>
				<div>
					<CancelButton
						onClick={cancelButtonAction}
						translationKey="comebackListText"
						icon={<NetcurioIcons.ArrowBack className={classNames(styles.icon)} />}
					/>

					<ExportButton
						onClick={() => {
							downloadXMLFile(
								S3_BUCKET,
								creditMemoDetail.uuid,
								Constants.FOLDER.CREDIT_MEMOS_FOLDER + '/'
							)
						}}
						translationKey="downloadText"
					/>
					<NetcurioButton
						variant="outlined"
						size={'small'}
						onClick={() => setFileViewer((state) => ({ ...state, open: true }))}
						endIcon={
							<NetcurioIcons.Visibility className={classNames(styles.icon, styles.iconPDF)} />
						}
					>
						<span> {t('showCreditMemoText')} </span>
					</NetcurioButton>
				</div>
				<div></div>
			</Header>
			<div className={styles.creditMemoDetailMiddle}>
				<InformationContainer
					creditMemoDetail={creditMemoDetail}
					showHeaderMessage={isEditionMode && (!hasAllReferenceLinked() || rfcmItems.length === 0)}
					userRole={userRole}
					rfcmItems={rfcmItems}
					onRfcmItemAssociation={associateItems}
					cmHasBeenSent={cmHasBeenSent}
				/>
				<LateralColumn
					creditMemoDetail={creditMemoDetail}
					creditMemoId={creditMemoId}
					client={client}
					setShowModal={setShowModal}
					setErrorCode={setErrorCode}
					setModalType={setModalType}
					isEditionMode={isEditionMode}
					onDocumentSelected={handleDocumentSelected}
					disabledSendButton={!hasAllReferenceLinked()}
					rfcmReference={rfcmReference}
					sendAssociation={sendAssociation}
					refreshDocumentData={getCreditMemoDetail}
					userRole={userRole}
					hasPendingMsg={(pendingMsg) => setExistsPendingMessage(pendingMsg)}
				/>
				<FileViewerModal onClose={closePDFModal} {...fileViewer} />
			</div>
			<CancelCreditMemoModal
				open={showCancelModal}
				onAccept={cancelCreditMemo}
				onClose={() => {
					setShowCancelModal(false)
					setShowModal(false)
				}}
			/>
			<VoidCreditMemoModal
				open={showVoidModal}
				onAccept={voidCreditMemo}
				onClose={() => {
					setShowVoidModal(false)
					setShowModal(false)
				}}
			/>
			<SendCreditMemoModal
				open={showSendModal}
				onAccept={saveAssociatedItems}
				onClose={() => {
					setShowSendModal(false)
					setShowModal(false)
				}}
			/>
			<CatchedErrorModal open={!!errorCode} errorCode={errorCode} errorGraphQlMessage={errorMessage} />
			<RejectPendingMessageCreditMemoDetail
				open={openRejectPendingMsgModal}
				close={() => setOpenRejectPendingMsgModal(false)}
				onAccept={() => history.push(URLS.CREDIT_MEMO_LIST)}
			/>
		</div>
	)
}
