import { getCurrentUser, Status, URLS, User } from '@netcurio/frontend-common'
import {
	NetcurioAlert,
	NetcurioButton,
	NetcurioCheckbox,
	NetcurioDialog,
	NetcurioFormControl,
	NetcurioFormControlLabel,
	NetcurioFormGroup,
	NetcurioInputLabel,
	NetcurioMenuItem,
	NetcurioSelect,
	NetcurioTooltip,
	Severity
} from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import UserInformation from '../../utilities/userInformation'
import { UPDATE_DEFAULT_COMPANY } from './queries'
import styles from './SelectCompanyAtLogin.module.scss'

interface OptionsMenuItems {
	label: string
	value: string
	blocked?: string
}

const user: User | null = getCurrentUser()

export const SelectCompanyAtLogin: FC = () => {
	const { t } = useTranslation()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const history = useHistory<string>()
	const [isDefaultCompany, setIsDefaultCompany] = useState<boolean>(false)
	const [companySelected, setCompanySelected] = useState<OptionsMenuItems>()
	const [optionsMenuItems, setOptionsMenuItems] = useState<Array<OptionsMenuItems>>([])
	const [errorUpdate, setErrorUpdate] = useState<string>()
	const fullCompanyBlockedLabel = t('fullCompanyBlock')

	useEffect(() => {
		const companies = user?.companies
		const defaultCompany = companies
			? companies.find((company) => {
					return company.rfc === user?.default_company
				})
			: undefined
		const currentCompany: OptionsMenuItems = {
			label: defaultCompany?.name ?? '',
			value: defaultCompany?.rfc ?? '',
			blocked: undefined
		}
		if (companies) {
			const newOptions = companies.map((company) => {
				const newOption: OptionsMenuItems = {
					label: company.name ?? '',
					value: company.rfc ?? '',
					blocked:
						(company.status as Status).key === 'BLOCKED' ? fullCompanyBlockedLabel : undefined
				}
				return newOption
			})
			setOptionsMenuItems(newOptions)
			for (let i = 0; i < companies.length; i++) {
				if ((companies[i].status as Status).key !== Constants.USER_STATUS.BLOCKED) {
					currentCompany.label = companies[i].name ?? ''
					currentCompany.value = companies[i].rfc ?? ''
					break
				}
			}
		}
		setCompanySelected(currentCompany)
	}, [fullCompanyBlockedLabel])

	const changeCompany = (event: any) => {
		const company = optionsMenuItems.find((option) => option.value === event.target.value)
		if (!company?.blocked) {
			setCompanySelected(company)
		}
	}

	const acceptActionModal = async () => {
		let objectCompany
		if (user) {
			objectCompany = UserInformation.getCompanySelected(user.companies, companySelected?.value ?? '')
			UserInformation.setCurrentUser(user, objectCompany)
		}
		if (isDefaultCompany) {
			await client
				.mutate({
					mutation: UPDATE_DEFAULT_COMPANY,
					variables: { default_company: companySelected?.value }
				})
				.catch((error) => {
					const idCode = showErrorComponent(error)
					if (!expiredToken(idCode)) {
						setErrorUpdate(t('errorCodeText', { idCode }))
					}
				})
		}
		const internalUrl = sessionStorage.getItem('internalUrl')
		if (internalUrl !== null) {
			const sessionPath = new URL(internalUrl)
			history.replace(sessionPath.pathname)
			sessionStorage.clear()
		} else {
			const homepage = objectCompany?.homepage
			history.replace(homepage ? URLS[Constants.RELATION_KEY_URL[homepage]] : URLS.PO_LIST)
		}
	}

	return (
		<div>
			<NetcurioDialog
				open={true}
				maxWidth="60rem"
				titleText={t('selectCompanyAtLoginTitle')}
				actionButtons={
					<NetcurioButton
						variant="contained"
						disabled={!companySelected?.value}
						onClick={() => {
							acceptActionModal()
						}}
					>
						{t('continueText')}
					</NetcurioButton>
				}
				contentFontSize="1.4rem"
			>
				{errorUpdate && (
					<NetcurioAlert severity={Severity.Error}>
						<span className={styles.messageError}>{errorUpdate}</span>
					</NetcurioAlert>
				)}

				<p className={styles.description}>{t('selectCompanyAtLoginText')}</p>
				<NetcurioFormControl fullWidth>
					<NetcurioInputLabel id="selectCompanyAtLoginTitle">
						{t('selectCompanyAtLoginTitle')}
					</NetcurioInputLabel>
					<NetcurioSelect
						labelId="selectCompanyAtLoginTitle"
						label={t('selectCompanyAtLoginTitle')}
						variant="standard"
						minWidth="100%"
						value={companySelected?.value}
						onChange={changeCompany}
						displayEmpty
					>
						<NetcurioMenuItem disabled value={t('selectCompanyAtLoginTitle')}>
							<span>{t('selectCompanyAtLoginTitle')}</span>
						</NetcurioMenuItem>
						{optionsMenuItems.map((item) => (
							<NetcurioMenuItem key={item.value} value={item?.value} disabled={!!item.blocked}>
								<NetcurioTooltip
									title={item?.blocked ? <label>{item?.blocked}</label> : ''}
									placement="top"
									enterDelay={0}
								>
									<span className={styles.itemCompany}>{item?.label}</span>
								</NetcurioTooltip>
							</NetcurioMenuItem>
						))}
					</NetcurioSelect>
				</NetcurioFormControl>

				<div className={styles.checkBoxContainer}>
					<NetcurioFormGroup>
						<NetcurioFormControlLabel
							control={
								<NetcurioCheckbox onChange={() => setIsDefaultCompany(!isDefaultCompany)} />
							}
							label={t('checkboxSelectCompanyAtLoginText')}
						/>
					</NetcurioFormGroup>
				</div>
			</NetcurioDialog>
		</div>
	)
}
