import {
	dateFormatterLong,
	getCurrentUser,
	IBranchOffices,
	IHeaderInformation,
	ISupplier,
	ModalRFQ,
	parseTimestampToTimeZone
} from '@netcurio/frontend-common'
import { NetcurioAutocomplete, NetcurioDatePicker, useNetcurioLoader } from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { render } from 'react-dom'
import { useTranslation } from 'react-i18next'
import MainModal from '../../../components/dialogModal/mainModal'
import ComponentQueries from '../../../components/queries'
import { connection } from '../../../utilities/connection'
import { showErrorComponent } from '../../../utilities/errorCode'
import { errorModal } from '../../../utilities/errorModal'
import { expiredToken } from '../../../utilities/expiredToken'
import { formatAddress } from '../../../utilities/formatAddress'
import { useDebounce } from '../../../utilities/useDebounce'
import styles from './headerInformation.module.scss'

const HeaderInformation = ({
	setBranchOffice,
	errorInputBranchOffice,
	newRFQ,
	setExpiredDate,
	fillDataBranch,
	setSupplier,
	errorInputSupplier,
	fillSupplier,
	changeSupplier,
	resetChange
}: IHeaderInformation) => {
	const { t } = useTranslation()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()

	const user = getCurrentUser()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const minDate: Dayjs = dayjs()
	const [allBranchOffices, setAllBranchOffices] = useState<Array<IBranchOffices>>([])
	const [branchOfficesLoading, setBranchOfficesLoading] = useState(true)
	const [selectedBranchOffice, setSelectedBranchOffice] = useState<IBranchOffices | null>(null)
	const [deliveryAddress, setDeliveryAddress] = useState('')
	const [selectedSupplier, setSelectedSupplier] = useState<ISupplier | null>(null)
	const [rfcSupplier, setRfcSupplier] = useState('')
	const [beforeSupplier, setBeforeSupplier] = useState<ISupplier | null>(null)
	const [stateModal, setStateModal] = useState<ModalRFQ>({ errorCode: undefined })
	const [supplierInput, setSupplierInput] = useState('')
	const [currentSupplierList, setCurrentSupplierList] = useState<Array<ISupplier>>([])
	const [supplierListIsLoading, setSupplierListIsLoading] = useState(false)
	const supplierInputDebounced = useDebounce<string>(supplierInput, 500)

	useEffect(() => {
		getAllBranches()
		selectInitialSupplier()
	}, [])

	useEffect(() => {
		showModal()
	}, [stateModal])

	useEffect(() => {
		if (!changeSupplier) {
			selectSupplier(beforeSupplier || selectedSupplier)
			resetChange()
		}
	}, [changeSupplier])

	useEffect(() => {
		setDeliveryAddress(() => (selectedBranchOffice ? formatAddress(selectedBranchOffice) : ''))
		setBranchOffice(selectedBranchOffice)
	}, [selectedBranchOffice, setBranchOffice])

	function getAllBranches() {
		showLoadingSpinner()
		client
			.query({
				query: ComponentQueries.BRANCH_OFFICES,
				variables: {
					company: user.company.rfc
				}
			})
			.then((result) => {
				const allBranchOffices = result.data.BranchOffices
				if (allBranchOffices.length === 1) {
					setSelectedBranchOffice(allBranchOffices[0])
				}
				setAllBranchOffices(allBranchOffices)
				hideLoadingSpinner()
			})
			.catch(handleError)
			.finally(() => setBranchOfficesLoading(false))
	}

	function selectInitialSupplier() {
		showLoadingSpinner()
		client
			.query({
				query: ComponentQueries.SUPPLIERS_BY_NAME_OR_CODE,
				variables: { search_text: '' }
			})
			.then((result) => {
				const allSuppliers = result.data.SuppliersByNameOrCode
				if (allSuppliers.length === 1) selectSupplier(allSuppliers[0])
				hideLoadingSpinner()
			})
			.catch(handleError)
	}

	const handleError = useCallback(
		(error: Error) => {
			console.error(error)
			const errorCode = showErrorComponent(error)
			if (!expiredToken(errorCode)) setStateModal({ errorCode })
			hideLoadingSpinner()
		},
		[hideLoadingSpinner]
	)

	function showModal() {
		const parentModal = document.getElementById('parentModal')
		let element = null
		if (stateModal.errorCode) {
			element = (
				<MainModal
					fillModal={errorModal(stateModal.errorCode)}
					errorCode={stateModal.errorCode}
					errorModalShow={true}
				/>
			)
		}
		if (parentModal) {
			render(element, parentModal)
		}
	}

	function dateNew() {
		const currentDate = new Date()
		return dateFormatterLong.format(parseTimestampToTimeZone(currentDate))
	}

	const selectSupplier = useCallback(
		(supplier: ISupplier) => {
			setSupplier(supplier)
			setRfcSupplier(supplier?.rfc || undefined)
			setSelectedSupplier((state) => {
				setBeforeSupplier(state)
				return supplier
			})
		},
		[setSupplier]
	)

	useEffect(() => {
		if (supplierInputDebounced.length >= 3) {
			setSupplierListIsLoading(true)
			client
				.query({
					query: ComponentQueries.SUPPLIERS_BY_NAME_OR_CODE,
					variables: {
						search_text: supplierInputDebounced.toLowerCase()
					}
				})
				.then((result) => {
					setCurrentSupplierList(result.data.SuppliersByNameOrCode)
				})
				.catch(handleError)
				.finally(() => setSupplierListIsLoading(false))
		} else {
			setCurrentSupplierList([])
			setSupplierListIsLoading(false)
		}
	}, [supplierInputDebounced])

	const getBranchOfficesLabel = (branchOffice: IBranchOffices) => branchOffice.description
	const validateBranchOfficesValue = (option: IBranchOffices, value: IBranchOffices) =>
		option.id === value.id

	const getSuppliersLabel = (supplier: ISupplier) => `${supplier.name} - ${supplier.rfc}`
	const validateSuppliersValue = (option: ISupplier, value: ISupplier) => option.rfc === value.rfc
	function onSupplierInputValueChange(input: string) {
		setSupplierInput(input)
		setSupplierListIsLoading(input.length >= 3)
	}
	return (
		<div className={styles.newRfqModuleContainer}>
			<div className={styles.headerRfqContainer}>
				<div className={styles.titleHeaderRfq}>{t('newRFQText')}</div>
			</div>
			<div className={styles.informationContainerHeaderRfq}>
				<div className={classNames(styles.moduleRfq, styles.grayColorTextGeneralInfo)}>
					<div className={styles.leftModuleRfq}>
						<div className={styles.titleModuleRfq}>{t('requestDataText')}</div>
						<div className={styles.titleFieldsRfq}>{t('idText')} #</div>
						<div className={styles.onlyReadFieldsRfq}></div>
						<div className={styles.alignModulesRfq}>
							<div className={styles.leftModuleRfq}>
								<div className={styles.titleFieldsRfq}>{t('createByText')}</div>
								<div className={classNames(styles.onlyReadFieldsRfq, styles.nameNewRfq)}>
									{user.name + ' ' + user.lastname}
								</div>
							</div>
							<div className={styles.rightModuleRfq}>
								<div className={styles.titleFieldsRfq}>{t('creationDate')}</div>
								<div className={styles.onlyReadFieldsRfq}>{dateNew()}</div>
							</div>
						</div>
					</div>

					<div className={styles.rightModuleRfq}>
						<div className={styles.titleModuleRfq}>{t('enrollmentInformationTitle')}</div>
						<div className={styles.alignModulesRfq}>
							<div className={styles.leftModuleRfq}>
								<div className={styles.titleFieldsRfq}>{t('companyNameText')}</div>
								<div className={styles.onlyReadFieldsRfq}>{user.company.name}</div>
							</div>
							<div className={styles.rightModuleRfq}>
								<div className={styles.titleFieldsRfq}>{t('textDotRFC')}</div>
								<div className={styles.onlyReadFieldsRfq}>{user.company.rfc}</div>
							</div>
						</div>
						<div className={styles.titleFields}>{t('addressInputNewCompany')}</div>
						<div className={styles.onlyReadFieldsRfq}>{formatAddress(user.company)}</div>
					</div>
				</div>

				<div className={classNames(styles.moduleRfq, styles.grayColorTextGeneralInfo)}>
					<div className={styles.rightModuleRfq}>
						<div className={styles.titleModuleRfq}>{t('deliveryBranch')}</div>
						<div className={styles.alignModulesRfq}>
							<div className={styles.leftModuleRfq}>
								<div className={styles.titleFieldsRfq}>{t('titleBranchOfficeSelect')}</div>
								<div className={styles.branchSelectorFieldRfq}>
									<NetcurioAutocomplete
										size="small"
										height="smaller"
										placeholder={t('branchOfficeSelector')}
										variant="outlined"
										options={allBranchOffices}
										getOptionLabel={getBranchOfficesLabel}
										isOptionEqualToValue={validateBranchOfficesValue}
										value={selectedBranchOffice}
										onSelectValue={setSelectedBranchOffice}
										loading={branchOfficesLoading}
										error={errorInputBranchOffice}
										sx={{ '& .MuiInputBase-input': { height: '1.3rem' } }}
									/>
								</div>
							</div>
							<div className={styles.expiringDateRfq}>
								<div className={styles.titleFieldsRfq}>{t('effectiveDateText')}</div>
								<div className={styles.flexCalendar}>
									<NetcurioDatePicker
										value={newRFQ.expiredDate}
										onChange={(value: Dayjs) => setExpiredDate(value)}
										label={t('effectiveDatePlaceholder')}
										format="DD/MM/YY"
										minDate={minDate}
										fullWidth={true}
									/>
								</div>
							</div>
						</div>
						{fillDataBranch && (
							<p className={styles.notFilledMessageNewPo}>{t('missingFields')}</p>
						)}
						<div className={styles.titleFieldsRfq}>{t('deliveryAddressPoPdf')}</div>
						<div className={styles.onlyReadFieldsRfq}>{deliveryAddress}</div>
					</div>
				</div>

				<div className={classNames(styles.moduleRfq, styles.grayColorTextGeneralInfo)}>
					<div className={styles.rightModuleRfq}>
						<div className={styles.titleModuleRfq}>{t('supplierInformation')}</div>

						<div className={styles.alignModulesRfq}>
							<div className={styles.leftModuleRfq}>
								<div className={styles.titleFieldsRfq}>{t('supplier_PO')}</div>
								<div>
									<NetcurioAutocomplete
										size="small"
										height="smaller"
										placeholder={t('supplierSelector')}
										variant="outlined"
										options={currentSupplierList}
										getOptionLabel={getSuppliersLabel}
										isOptionEqualToValue={validateSuppliersValue}
										value={selectedSupplier}
										onSelectValue={selectSupplier}
										inputValue={supplierInput}
										onInputValueChange={onSupplierInputValueChange}
										loading={supplierListIsLoading}
										error={errorInputSupplier}
										minLength={3}
										sx={{ '& .MuiInputBase-input': { height: '1.3rem' } }}
									/>
								</div>
							</div>
							<div className={styles.leftModuleRfq}>
								<div className={styles.titleFieldsRfq}>{t('supplierRFC')}</div>
								<div className={styles.onlyReadFieldsRfq}>{rfcSupplier}</div>
							</div>
							<div className={styles.rightModuleRfq} />
						</div>
						{fillSupplier && <p className={styles.notFilledMessageNewPo}>{t('missingFields')}</p>}
					</div>
				</div>
			</div>
		</div>
	)
}
export default HeaderInformation
