import {
	NetcurioAutocomplete,
	NetcurioTableCell,
	NetcurioTableRow,
	NetcurioTooltip
} from '@netcurio/frontend-components'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	AssociationStateMessage,
	ConsumptionItem,
	InvoiceItem,
	PurchaseOrderItem
} from '../../../../../types'
import Constants from '../../../../../utilities/constants'
import Formatter from '../../../../../utilities/formatter'
import styles from '../../InvoiceEdit.module.scss'
import { ItemsToUpdate } from '../../types'

interface InvoiceItemEditProps {
	itemPosition: number
	invoiceItem: InvoiceItem
	disableAssociateProducts: boolean
	suggestionsItemsToAssociate: Array<PurchaseOrderItem | ConsumptionItem>
	associateProducts(indexSelect: number, item?: number): void
	documentSelected: string
	updateAssociatedItemPendingQuantity(itemsToUpdate: any): void
	showAssociationSection?: boolean
}

export const InvoiceItemEdit: FC<InvoiceItemEditProps> = ({
	invoiceItem: {
		position,
		concept,
		quantity,
		unit_price,
		net_amount,
		tax,
		error,
		code,
		reference_position
	},
	associateProducts,
	suggestionsItemsToAssociate = [],
	disableAssociateProducts,
	updateAssociatedItemPendingQuantity,
	itemPosition,
	documentSelected,
	showAssociationSection
}) => {
	const { t } = useTranslation()
	const [selectedItemPosition, setSelectedItemPosition] = useState<number>()
	const [associatedAmount, setAssociatedAmount] = useState<number>(0)
	const [associationStateMessage, setAssociationStateMessage] = useState<AssociationStateMessage>(
		AssociationStateMessage.NoError
	)
	const [value, setValue] = useState<PurchaseOrderItem | ConsumptionItem | null>(null)

	useEffect(() => {
		if (disableAssociateProducts && !documentSelected) {
			setAssociationStateMessage(AssociationStateMessage.NoError)
			setValue(null)
			setAssociatedAmount(0)
			setSelectedItemPosition(undefined)
		}
	}, [disableAssociateProducts, documentSelected])

	useEffect(() => {
		if (reference_position && suggestionsItemsToAssociate.length > 0) {
			const findSuggestionItem: PurchaseOrderItem | ConsumptionItem | undefined =
				suggestionsItemsToAssociate.find(
					(item: PurchaseOrderItem | ConsumptionItem): boolean =>
						item.position === reference_position
				)
			if (findSuggestionItem) {
				selectItem(findSuggestionItem)
			}
		}
	}, [reference_position, suggestionsItemsToAssociate])

	const getPercentageTolerance = (item: PurchaseOrderItem) => {
		const requestedAmount: number = item.requested_amount ?? 0
		const confirmedAmount: number = item.confirmed_amount ?? 0

		const positionAmount =
			confirmedAmount > 0 || item.requires_gr_confirmation ? confirmedAmount : requestedAmount

		const percentTolerance: number = item.percent_tolerance ?? 0
		return item.unlimited_tolerance ? 0 : positionAmount * percentTolerance
	}

	const getOptionLabel = (option: PurchaseOrderItem | ConsumptionItem) => {
		if (option.position === -1) {
			return t('errorAssociation')
		}
		const calcPercentageTolerance =
			(option as PurchaseOrderItem)?.percent_tolerance ||
			(option as PurchaseOrderItem)?.unlimited_tolerance
				? getPercentageTolerance(option as PurchaseOrderItem)
				: 0

		const isSamePosition: boolean = value?.position === option.position
		const nameProduct = (option as PurchaseOrderItem)?.name
			? (option as PurchaseOrderItem)?.name
			: (option as ConsumptionItem)?.description
		const productCode = option?.code ? `(${option.code})` : ''
		const pendingQtyToInvoice: number =
			(option.pending_quantity_to_invoice ?? 0) - calcPercentageTolerance
		const pendingAmountForUser = isSamePosition
			? associatedAmount > 0
				? associatedAmount
				: 0
			: pendingQtyToInvoice > 0
				? pendingQtyToInvoice
				: 0
		return `${option.position} - ${productCode} ${nameProduct} - ${Formatter.currency.format(
			option.unit_price ?? 0
		)} - ${pendingAmountForUser} ${Formatter.nullValueFormatting(option.unit)} ${t(
			isSamePosition ? 'itemAssociated' : 'pendingToAssociate'
		)}`
	}

	const getPreviousWithAddedPendingToInvoice = (): ItemsToUpdate => {
		const previousSelectedItem: PurchaseOrderItem | ConsumptionItem = suggestionsItemsToAssociate.filter(
			(previousItem: PurchaseOrderItem | ConsumptionItem) =>
				previousItem.position === selectedItemPosition
		)[0]
		const pendingQuantityToInvoice =
			(previousSelectedItem as PurchaseOrderItem).pending_quantity_to_invoice ?? 0
		const remainingPendingQuantityToInvoice: number = pendingQuantityToInvoice + associatedAmount

		return {
			position: selectedItemPosition ?? 0,
			newQuantity: remainingPendingQuantityToInvoice
		}
	}

	const unSelectItem = () => {
		if (selectedItemPosition) {
			updateAssociatedItemPendingQuantity([getPreviousWithAddedPendingToInvoice()])
		}
		setSelectedItemPosition(undefined)
		setAssociatedAmount(0)
		associateProducts(itemPosition)
	}

	const selectItem = (selectedItem?: PurchaseOrderItem | ConsumptionItem) => {
		if (selectedItem?.position === value?.position) {
			return
		}
		const pendingQuantityToInvoice = selectedItem?.pending_quantity_to_invoice ?? 0

		if (pendingQuantityToInvoice < quantity && !(selectedItem as PurchaseOrderItem).unlimited_tolerance) {
            const newValue = (selectedItem as PurchaseOrderItem)
            newValue.position = -1
			setValue(newValue)
			setAssociationStateMessage(AssociationStateMessage.NoPendingQuantityRemainingError)
			unSelectItem()
			return
		}

		const isMessageDidNotMatch: boolean =
			code !== undefined &&
            code !== null &&
			code !== Constants.SYMBOL.NOT_APPLICABLE &&
			selectedItem?.code !== undefined &&
            selectedItem?.code !== null &&
			selectedItem?.code !== Constants.SYMBOL.NOT_APPLICABLE &&
			code !== selectedItem?.code

		setAssociationStateMessage(
			isMessageDidNotMatch
				? AssociationStateMessage.CodeDidNotMatchWarning
				: AssociationStateMessage.NoError
		)

		if (selectedItem) {
			const calcPercentageTolerance =
				(selectedItem as PurchaseOrderItem)?.percent_tolerance ||
				(selectedItem as PurchaseOrderItem)?.unlimited_tolerance
					? getPercentageTolerance(selectedItem as PurchaseOrderItem)
					: 0

			const itemsToUpdate: Array<ItemsToUpdate> = []
			if (selectedItemPosition) {
				itemsToUpdate.push(getPreviousWithAddedPendingToInvoice())
			}
			const isPendingQtyBigger: boolean = quantity > pendingQuantityToInvoice
			const associatedAmount: number = isPendingQtyBigger ? pendingQuantityToInvoice : quantity
			const remainingPendingQuantityToInvoice: number = isPendingQtyBigger
				? 0
				: pendingQuantityToInvoice - quantity

			setSelectedItemPosition(selectedItem.position)
			setAssociatedAmount(associatedAmount)
			itemsToUpdate.push({
				position: selectedItem.position ? Number(selectedItem.position) : -1,
				newQuantity: remainingPendingQuantityToInvoice,
				userNewQuantity: pendingQuantityToInvoice - calcPercentageTolerance - quantity
			})
			updateAssociatedItemPendingQuantity(itemsToUpdate)
			associateProducts(itemPosition, selectedItem.position)
			setValue(selectedItem)
		} else {
			setValue(null)
			setAssociationStateMessage(AssociationStateMessage.NoError)
			unSelectItem()
		}
	}

	const onChangeInput = (value: string) => {
		if (value === '') {
			selectItem()
		}
	}

	return (
		<NetcurioTableRow isDetailRow rowWithError={error}>
			<NetcurioTableCell align="center">
				<p>{position}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p>{concept}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p>{Formatter.codeDescription(code, '')}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p>{quantity}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p>{Formatter.currency.format(unit_price)}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p>{Formatter.currency.format(net_amount)}</p>
			</NetcurioTableCell>
			<NetcurioTableCell align="center">
				<p>{Formatter.percent.format(tax)}</p>
			</NetcurioTableCell>

			{showAssociationSection && (
				<NetcurioTableCell align="center">
					<NetcurioTooltip
						title={
							associationStateMessage === AssociationStateMessage.NoError
								? ''
								: t(associationStateMessage)
						}
						placement="top"
					>
						<div className={styles.containerAutocompleteItem}>
							<NetcurioAutocomplete
								key={position}
								height="smaller"
								size="small"
								variant="outlined"
								value={value}
								options={suggestionsItemsToAssociate}
								onSelectValue={selectItem}
								onInputValueChange={onChangeInput}
								getOptionLabel={getOptionLabel}
								disabled={disableAssociateProducts}
								error={
									associationStateMessage ===
									AssociationStateMessage.NoPendingQuantityRemainingError
								}
								isOptionEqualToValue={(option: PurchaseOrderItem, value: PurchaseOrderItem) =>
									option?.position === value?.position
								}
								warning={
									associationStateMessage === AssociationStateMessage.CodeDidNotMatchWarning
								}
								disableClearable
							/>
						</div>
					</NetcurioTooltip>
				</NetcurioTableCell>
			)}
		</NetcurioTableRow>
	)
}
