import {
	IBranchOffices,
	PURCHASE,
	URLS,
	allowDecimals,
	getCurrency,
	getCurrentUser
} from '@netcurio/frontend-common'
import {
	CommentSectionVariant,
	CommentsSection,
	Header,
	NetcurioAutocomplete,
	NetcurioButton,
	NetcurioGrid,
	NetcurioIcons,
	NetcurioInputLabel,
	NetcurioMenuItem,
	NetcurioSelect,
	NetcurioTextField,
	TotalContainerBar,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import { ApolloQueryResult } from 'apollo-boost'
import dayjs from 'dayjs'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouterChildContext, useHistory } from 'react-router-dom'
import { ConditionalRender } from '../../../components/ConditionalRender/ConditionalRender'
import { Product, PurchaseOrder, PurchaseOrderItem, Supplier } from '../../../types'
import { connection } from '../../../utilities/connection'
import Constants from '../../../utilities/constants'
import { showErrorComponent } from '../../../utilities/errorCode'
import { expiredToken } from '../../../utilities/expiredToken'
import { formatAddress } from '../../../utilities/formatAddress'
import { useDebounce } from '../../../utilities/useDebounce'
import { ConfirmPurchaseOrderModal } from '../../newPurchaseOrder/PurchaseOrderModals/ConfirmPurchaseOrderModal'
import { QuotationInfo, RequestForQuotation } from '../../requestForQuotationDetail/types'
import {
	BRANCH_OFFICES,
	CREATE_PURCHASE_ORDER,
	QUOTATION,
	REQUEST_FOR_QUOTATION_DETAIL,
	SUPPLIERS_BY_NAME_OR_CODE
} from '../graphQl'
import { NewPurchaseOrderDTO, NewPurchaseOrderItemDTO } from '../interfaces'
import { Item } from './Item/Item'
import { CancelPOCreationModal } from './Modals/CancelPoCreationModal'
import { ChangePoTypeModal } from './Modals/ChangePoTypeModal'
import { DeleteSupplierModal } from './Modals/DeleteSupplierModal'
import { ErrorModal } from './Modals/ErrorModal'
import { PriceModifiedModal } from './Modals/PriceModifiedModal'
import styles from './newPurchaseOrder.module.scss'
import { defaultValuesItemPo, defaultValuesPurchaseOrder } from './types/defaultValuesNewPO'

export interface FieldValidations {
	product: boolean
	amount: boolean
}

export const NewPurchaseOrder: FC = () => {
	const user = getCurrentUser()
	const client = connection()
	const history: RouterChildContext['router']['history'] = useHistory()
	const { t } = useTranslation()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [poType, setPoType] = useState<string>(PURCHASE.PURCHASE_ORDER_TYPES.STANDARD)
	const [tempNewPoType, setTempNewPoType] = useState<string | undefined>()
	const [allBranchOffices, setAllBranchOffices] = useState<Array<IBranchOffices>>()
	const [selectedBranchOffice, setSelectedBranchOffice] = useState<string>()
	const [deliveryAddress, setDeliveryAddress] = useState<string>()
	const [suppliersList, setSuppliersList] = useState<Array<Supplier>>([])
	const [selectedSupplier, setSelectedSupplier] = useState<Supplier | undefined>(undefined)
	const [supplierRFC, setSupplierRFC] = useState<string>('')
	const [selectedSuppTemp, setSelectedSuppTemp] = useState<Supplier | undefined>(undefined)
	const [storageLocations, setStorageLocations] = useState<
		Array<{
			branch_office: string
			company: string
			description: string
			id: string
		}>
	>([])
	const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>({
		id: '',
		customer: {},
		supplier: {},
		items: [
			{
				code: '',
				name: '',
				product: undefined,
				position: undefined,
				net_value: 0,
				unit_price: undefined,
				unit: '',
				status: '',
				storage_location_id: '',
				storage_location: '',
				reference_price: undefined,
				requested_amount: 1,
				confirmed_amount: 0,
				delivery_proposal_date: undefined,
				required_delivery_date: undefined,
				tax: 0,
				rejected_reason: {
					key: '',
					value: ''
				},
				pending_delivery: undefined,
				delivered_quantity: undefined,
				price_change_reason: '',
				ean: '',
				purchase_order: '',
				customer_reference: '',
				supplier_reference: '',
				can_be_deleted: false,
				deleted: false,
				percent_tolerance: undefined,
				pending_quantity_to_invoice: undefined,
				receivedAmount: ''
			}
		],
		total: 0,
		status: {
			value: '',
			key: ''
		},
		created_at: dayjs(),
		updated_at: dayjs(),
		delivery_address_country_code: '',
		delivery_address_state_code: '',
		goodsReceipts: [],
		invoices: [],
		comments: [],
		comment: '',
		type: {
			value: '',
			key: ''
		}
	})
	const [advPercentage, setAdvPercentage] = useState<string | number>('')
	const [advAmount, setAdvAmount] = useState<string | number>('')
	const [isAdvAmountEnabled, setIsAdvAmountEnabled] = useState<boolean>(false)
	const [advAmountEror, setAdvAmountError] = useState<boolean>(false)
	const [comment, setComment] = useState<string>()
	const [totalErrorsByPO, setTotalErrorsByPO] = useState<Array<FieldValidations>>([])
	const [POFromQuotation, setPOFromQuotation] = useState<boolean>(false)
	const [canUserSubmitPO, setCanUserSubmitPo] = useState<boolean>(false)
	const [showChangePoTypeModal, setShowChangePoTypeModal] = useState<boolean>(false)
	const [showConfirmPOModal, setShowConfirmPOModal] = useState<boolean>(false)
	const [savePOModalPriceModified, setSavePOModalPriceModified] = useState<boolean>(false)
	const [openCancelModal, setOpenCancelModal] = useState<boolean>(false)
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
	const [showDeleteSupplierModal, setShowDeleteSupplierModal] = useState<boolean>(false)
	const [errorCode, setErrorCode] = useState<string>()
	const [suppOpen, setSuppOpen] = useState(false)
	const [suppSrchStrng, setSuppSrchStrng] = useState('')
	const [loading, setLoading] = useState<boolean>(false)
	const debouncedSupplierInput = useDebounce<string>(suppSrchStrng, 500)

	useEffect(() => {
		if (debouncedSupplierInput.length >= 3) {
			setLoading(true)
			suggestSupplier(debouncedSupplierInput)
		}
	}, [debouncedSupplierInput])

	useEffect(() => {
		for (const errObj of totalErrorsByPO) {
			if (errObj.amount || errObj.product) {
				setCanUserSubmitPo(false)
			}
		}
		for (const item of purchaseOrder.items) {
			if (item.code === '' || item.name === '') {
				setCanUserSubmitPo(false)
			} else {
				setCanUserSubmitPo(true)
			}
		}
	}, [totalErrorsByPO, purchaseOrder])

	const getQuotationBranch = (rfqId: string, customer: string) => {
		showLoadingSpinner()
		client
			.query({
				query: REQUEST_FOR_QUOTATION_DETAIL,
				variables: {
					id: rfqId,
					customer: customer
				},
				fetchPolicy: 'no-cache'
			})
			.then(async (result: ApolloQueryResult<{ RequestForQuotation: RequestForQuotation }>) => {
				const qoutation = result.data.RequestForQuotation
				if (qoutation) {
					setDeliveryAddress(
						qoutation.delivery_address_line_1 +
							', ' +
							qoutation.delivery_address_postal_code +
							', ' +
							qoutation.delivery_address_state +
							', ' +
							qoutation.delivery_address_country
					)
					setSelectedBranchOffice(qoutation.delivery_address_line_1)
				}
				hideLoadingSpinner()
			})
	}

	const fetchQuotationData = async (id: string, supplier: string) => {
		showLoadingSpinner()
		const quotation = await client
			.query({
				query: QUOTATION,
				variables: {
					id,
					supplier
				}
			})
			.then((result: ApolloQueryResult<{ Quotation: QuotationInfo }>) => {
				return result.data.Quotation
			})
			.catch((error) => {
				console.error(error)
			})
		if (quotation?.items) {
			const mappedItems = quotation.items.map((qItem) => {
				return {
					code: qItem.product_code,
					name: qItem.product_description.trim(),
					product: {
						ean: qItem.product_ean ? qItem.product_ean : '',
						id: qItem.product_code,
						description: qItem.product_description.trim(),
						currency: quotation.currency,
						unit_price: qItem.unit_price,
						unit: qItem.unit,
						tax: qItem.tax,
						referencePrice: qItem.unit_price
					},
					position: qItem.position,
					net_value: Number(qItem.quantity ?? 0) * Number(qItem.unit_price ?? 0),
					unit_price: qItem.unit_price,
					unit: qItem.unit,
					status: '',
					storage_location_id: '',
					storage_location: '',
					reference_price: qItem.unit_price,
					requested_amount: Number(qItem.quantity ?? 0),
					confirmed_amount: 0,
					delivery_proposal_date: undefined,
					required_delivery_date: dayjs(qItem.request_position.required_delivery_date),
					tax: qItem.tax,
					ean: qItem.product_ean,
					rejected_reason: {
						key: '',
						value: ''
					},
					can_be_deleted: quotation.items.length > 1
				}
			})
			setPurchaseOrder({
				...purchaseOrder,
				items: mappedItems,
				currency: quotation?.currency,
				total: getNetTotal(mappedItems),
				quotation: id
			})
			hideLoadingSpinner()
		}
	}

	const suggestSupplier = (debouncedSupplierInput: string) => {
		client
			.query({
				query: SUPPLIERS_BY_NAME_OR_CODE,
				variables: {
					search_text: debouncedSupplierInput
				}
			})
			.then((result: { data: { SuppliersByNameOrCode: any } }) => {
				setSuppliersList(result.data.SuppliersByNameOrCode)
				setLoading(false)
				hideLoadingSpinner()
			})
			.catch((error) => {
				console.error(error)
				setErrorCode(showErrorComponent(error))
				if (!expiredToken(errorCode ?? '')) {
					setShowErrorModal(true)
				}
				hideLoadingSpinner()
			})
	}

	useEffect(() => {
		getAllBranches()
		const sessionStorage = window.sessionStorage.getItem(Constants.QUOTATION.QUOTATION_INFO)
		if (!sessionStorage) {
			setPOFromQuotation(false)
		} else {
			setPOFromQuotation(true)
		}
		if (POFromQuotation && sessionStorage) {
			const storageJson = JSON.parse(sessionStorage)
			suggestSupplier(storageJson?.supplier)
			fetchQuotationData(storageJson.id, storageJson?.supplier).then()
			getQuotationBranch(storageJson?.rfqId, storageJson?.customer)
		}
	}, [sessionStorage, POFromQuotation])

	const handleSuppOpen = () => setSuppOpen((state) => !state)

	useEffect(() => {
		if (suppSrchStrng.length >= 2) {
			client
				.query({
					query: SUPPLIERS_BY_NAME_OR_CODE,
					variables: {
						search_text: suppSrchStrng
					}
				})
				.then((result: { data: { SuppliersByNameOrCode: any } }) => {
					setSuppliersList(result.data.SuppliersByNameOrCode)
					setLoading(false)
					hideLoadingSpinner()
				})
				.catch((error) => {
					console.error(error)
					setErrorCode(showErrorComponent(error))
					if (!expiredToken(errorCode ?? '')) {
						setShowErrorModal(true)
					}
					hideLoadingSpinner()
				})
		} else if (suppSrchStrng !== '') {
			setLoading(true)
		}
	}, [suppOpen, suppSrchStrng])

	const getAllBranches = () => {
		showLoadingSpinner()
		client
			.query({
				query: BRANCH_OFFICES,
				variables: {
					company: user?.company?.rfc
				}
			})
			.then((result: { data: { BranchOffices: any } }) => {
				const allBranchOffices = result.data.BranchOffices
				setAllBranchOffices(allBranchOffices)
				hideLoadingSpinner()
			})
			.catch((error) => {
				console.error(error)
				setErrorCode(showErrorComponent(error))
				if (!expiredToken(errorCode ?? '')) {
					setShowErrorModal(true)
				}
				hideLoadingSpinner()
			})
	}

	const actionButtonExitNewPO = () => {
		if (comment || selectedBranchOffice || selectedSupplier || purchaseOrder.items.length > 1) {
			setOpenCancelModal(true)
		} else {
			sessionStorage.clear()
			history.push(URLS.PO_LIST)
		}
	}

	const handleSelectBranch = (evt: string) => {
		if (allBranchOffices) {
			const chosenBranch = allBranchOffices.find((branch) => branch.description === evt)
			if (chosenBranch) {
				setSelectedBranchOffice(chosenBranch.address_line_1)
				setStorageLocations(chosenBranch.storage_locations)
				const chosenDeliveryAddress = formatAddress(chosenBranch)
				setDeliveryAddress(chosenDeliveryAddress)
			}
		}
	}

	const validateSupplierValue = (option: Supplier, value: { rfc: string }) => option?.rfc === value?.rfc

	const getSupplierLabel = (option: Supplier) => `${option.name} - ${option.rfc}`

	const isStandardFunctionality = () => {
		return (
			poType === PURCHASE.PURCHASE_ORDER_TYPES.STANDARD ||
			poType === PURCHASE.PURCHASE_ORDER_TYPES.EXPRESS
		)
	}

	const handleItemFieldChange = (evt: any, index: number, field: string) => {
		const newPurchaseOrder = purchaseOrder
		const itemData = purchaseOrder.items
		const code = itemData[index]?.code
		const name = itemData[index]?.name
		let requestedAmount = itemData[index]?.requested_amount
		let unitPrice = itemData[index]?.unit_price
		let requiredDate = itemData[index]?.required_delivery_date
		let storageLocation = itemData[index]?.storage_location
		const canBeDeleted = itemData[index]?.can_be_deleted

		switch (field) {
			case 'amount':
				requestedAmount = evt as number
				break
			case 'requiredDate':
				requiredDate = dayjs(evt)
				break
			case 'unitPrice':
				unitPrice = evt as number
				break
			case 'storageLocation':
				storageLocation = evt
				break
		}
		const tempItem: PurchaseOrderItem = {
			code: code,
			name: name,
			product: itemData[index].product,
			unit_price: unitPrice,
			reference_price: itemData[index].reference_price,
			unit: itemData[index]?.unit,
			tax: itemData[index]?.tax,
			requested_amount: Number(requestedAmount ?? 0),
			net_value: unitPrice ? requestedAmount * unitPrice : 0,
			required_delivery_date: requiredDate,
			can_be_deleted: canBeDeleted,
			storage_location: storageLocation,
			ean: itemData[index].ean ? itemData[index].ean : ''
		}
		itemData.splice(index, 1, tempItem)
		setCanUserSubmitPo(true)
		const netTotal = getNetTotal(itemData)
		setPurchaseOrder({ ...newPurchaseOrder, items: itemData, total: netTotal })
	}

	const getNetTotal = (itemData: PurchaseOrderItem[]) => {
		return itemData.reduce((total, num) => {
			if (typeof num.net_value === 'number') {
				return total + num.net_value
			} else {
				return total
			}
		}, 0)
	}

	const formatAdvAmount = (advanceAmount: string | number) => {
		if (typeof advanceAmount === 'number') {
			return advanceAmount
		} else {
			const num = +advanceAmount
			return num
		}
	}

	const selectedProductItem = (
		product: Product | undefined,
		itemIndex: number,
		unit_price: number | string,
		unit: string,
		tax: number | string,
		referencePrice: number,
		currency: string,
		storageLocation: string
	) => {
		if (product) {
			const itemDataListTemp = purchaseOrder.items
			const objectItem: PurchaseOrderItem = {
				code: product?.id,
				name: product && product.description ? product.description.trim() : '',
				product: product,
				position: itemIndex,
				unit_price: Number(unit_price),
				reference_price: referencePrice,
				unit: unit ? unit.trim() : '',
				tax: Number(tax),
				requested_amount: itemDataListTemp[itemIndex].requested_amount,
				delivery_proposal_date: itemDataListTemp[itemIndex].required_delivery_date,
				net_value: itemDataListTemp[itemIndex].requested_amount * referencePrice,
				storage_location: storageLocation,
				ean: product.ean ? product.ean : ''
			}
			itemDataListTemp.splice(itemIndex, 1, objectItem)
			const netTotal = getNetTotal(itemDataListTemp)
			setPurchaseOrder({
				...purchaseOrder,
				currency: currency,
				items: itemDataListTemp,
				total: netTotal
			})
		} else {
			const itemDataListTemp = purchaseOrder.items
			const tempItem: PurchaseOrderItem = {
				code: '',
				name: '',
				product: undefined,
				position: itemIndex,
				unit_price: undefined,
				reference_price: undefined,
				unit: '',
				tax: 0,
				requested_amount: itemDataListTemp[itemIndex].requested_amount,
				delivery_proposal_date: itemDataListTemp[itemIndex].required_delivery_date,
				net_value: 0,
				storage_location: storageLocation,
				ean: ''
			}
			itemDataListTemp.splice(itemIndex, 1, tempItem)
			const netTotal = getNetTotal(itemDataListTemp)
			setPurchaseOrder({
				...purchaseOrder,
				currency: currency,
				items: itemDataListTemp,
				total: netTotal
			})
			setCanUserSubmitPo(false)
		}
	}

	const checkMissingFields = (): boolean => {
		const items = purchaseOrder.items
		let isValidItem = true
		const errorsByItem: FieldValidations[] = []
		items.map((item) => {
			const itemErrors: FieldValidations = { product: false, amount: false }
			if (
				!item.product ||
				!item.ean ||
				item.requested_amount === 0 ||
				item.requested_amount.toString() === '' ||
				item.requested_amount.toString() === '0' ||
				!item.requested_amount ||
				!item.requested_amount ||
				(isStandardFunctionality() && (item.unit_price === 0 || !item.unit_price))
			) {
				if (!item.product || !item.code || !item.name || item.ean === undefined) {
					isValidItem = false
					itemErrors.product = true
				} else {
					itemErrors.product = false
				}
				if (
					item.requested_amount === 0 ||
					!item.requested_amount ||
					item.requested_amount.toString() === '' ||
					item.requested_amount.toString() === '0'
				) {
					isValidItem = false
					itemErrors.amount = true
				} else {
					itemErrors.amount = false
				}
			}
			errorsByItem.push(itemErrors)
		})
		setTotalErrorsByPO(errorsByItem)
		return isValidItem
	}

	const addItem = () => {
		const validateItem: Array<FieldValidations> = []
		const items = purchaseOrder.items
		const newItem: PurchaseOrderItem = {
			code: '',
			name: '',
			product: undefined,
			delivery_proposal_date: undefined,
			net_value: 0,
			reference_price: 0,
			requested_amount: 1,
			storage_location: '',
			tax: 0,
			unit: undefined,
			unit_price: 0,
			ean: ''
		}
		const tempValidationItem = {
			product: false,
			amount: false
		}
		if (checkMissingFields()) {
			items.push(newItem)
			validateItem.push(tempValidationItem)
			setPurchaseOrder({
				...purchaseOrder,
				items: items
			})
		}
	}

	const deleteItem = (itemIndex: number) => {
		const items = purchaseOrder.items
		if (items.length <= 1) {
			setCanUserSubmitPo(false)
			setPurchaseOrder({
				...purchaseOrder,
				items: [
					{
						code: '',
						name: '',
						product: undefined,
						position: undefined,
						net_value: 0,
						unit_price: undefined,
						unit: '',
						status: '',
						storage_location_id: '',
						storage_location: '',
						reference_price: undefined,
						requested_amount: 1,
						confirmed_amount: 0,
						delivery_proposal_date: undefined,
						required_delivery_date: undefined,
						tax: 0,
						pending_delivery: undefined,
						delivered_quantity: undefined,
						price_change_reason: '',
						ean: '',
						purchase_order: '',
						customer_reference: '',
						supplier_reference: '',
						can_be_deleted: false,
						deleted: false,
						percent_tolerance: undefined,
						pending_quantity_to_invoice: undefined,
						receivedAmount: ''
					}
				],
				total: 0
			})
		} else {
			items.splice(itemIndex, 1)
			setPurchaseOrder({
				...purchaseOrder,
				items: items,
				total: getNetTotal(items)
			})
		}
	}

	const verifyUnitPriceModified = () => {
		if (isStandardFunctionality()) {
			for (const item of purchaseOrder.items) {
				if (item.unit_price !== item.reference_price) {
					return true
				}
			}
		}
		return false
	}

	const saveDataNewPurchase = () => {
		if (checkMissingFields() && checkAdvanceFields()) {
			for (const errObj of totalErrorsByPO) {
				if (errObj.amount || errObj.product) {
					console.error('Please check missing errors.')
				}
			}
			if (verifyUnitPriceModified()) {
				setSavePOModalPriceModified(true)
				setOpenCancelModal(false)
			} else {
				setShowConfirmPOModal(true)
				setOpenCancelModal(false)
			}
		}
	}

	const createNewPO = useCallback(
		async (reason: any | undefined) => {
			const newPurchaseOrder = purchaseOrder
			const sendItems = []
			showLoadingSpinner()
			for (const key in newPurchaseOrder.items) {
				let objectItem: NewPurchaseOrderItemDTO = {
					position: parseInt(key) + 1,
					name: newPurchaseOrder.items[key].name ?? '',
					code: newPurchaseOrder.items[key].code ?? '',
					ean: newPurchaseOrder.items[key].ean ?? '',
					unit: newPurchaseOrder.items[key].unit ?? '',
					requested_amount: parseFloat(`${newPurchaseOrder.items[key].requested_amount}`) ?? 0,
					...(newPurchaseOrder.items[key].required_delivery_date
						? {
								required_delivery_date: dayjs(
									newPurchaseOrder.items[key].required_delivery_date
								).format('YYYY-MM-DD')
							}
						: {}),
					storage_location: newPurchaseOrder.items[key].storage_location_id ?? ''
				}
				if (isStandardFunctionality()) {
					objectItem = {
						...objectItem,
						net_value: newPurchaseOrder.items[key].net_value,
						unit_price: parseFloat(`${newPurchaseOrder.items[key].unit_price}`),
						reference_price: newPurchaseOrder.items[key].reference_price,
						tax: newPurchaseOrder.items[key].product?.tax
							? newPurchaseOrder.items[key].product?.tax
							: 0
					}
				}
				sendItems.push(objectItem)
			}
			const currentUser = getCurrentUser()
			const branch: IBranchOffices | undefined = allBranchOffices?.find(
				(branch) => branch.address_line_1 === selectedBranchOffice
			)
			const newPODto: NewPurchaseOrderDTO = {
				...(branch ? { branch_office_id: branch.id } : {}),
				...(branch ? { branch_office: branch.description } : {}),
				supplier: supplierRFC ?? '',
				total: getNetTotal(newPurchaseOrder.items),
				...(branch ? { delivery_address_line_1: branch.address_line_1 } : {}),
				...(branch ? { delivery_address_line_2: branch.address_line_2 } : {}),
				...(branch ? { delivery_address_country: branch.country_code } : {}),
				...(branch ? { delivery_address_country_code: branch.country_code } : {}),
				...(branch ? { delivery_address_state: branch.state_description } : {}),
				...(branch ? { delivery_address_state_code: branch.state_code } : {}),
				...(branch ? { delivery_address_postal_code: branch.postal_code } : {}),
				currency: newPurchaseOrder.currency ?? '',
				items: sendItems,
				...(reason ? { price_change_reason: reason } : {}),
				...(POFromQuotation ? { quotation: newPurchaseOrder.quotation } : {}),
				type: poType,
				customer_reference: newPurchaseOrder.customer_reference,
				user_reference: newPurchaseOrder.user_reference,
				...(comment
					? {
							comment: {
								text: comment
							}
						}
					: {}),
				...(advPercentage ? { advance_percentage: (advPercentage as unknown as number) * 0.01 } : {}),
				...(advAmount ? { advance_amount: formatAdvAmount(advAmount) } : {})
			}
			client
				.mutate({
					mutation: CREATE_PURCHASE_ORDER,
					variables: newPODto
				})
				.then((result) => {
					setSavePOModalPriceModified(false)
					setShowConfirmPOModal(false)
					hideLoadingSpinner()
					if (result.data.createPurchaseOrder.id) {
						const purchaseOrderId = result.data.createPurchaseOrder.id
						const userCompany = currentUser?.company
						history.push(
							URLS.PO_DETAIL +
								`?order=${purchaseOrderId}&customer=${userCompany ? userCompany.rfc : ''}`
						)
						sessionStorage.clear()
						sessionStorage.setItem('newOrder', 'true')
					} else {
						const errorCode = showErrorComponent(undefined)
						setErrorCode(errorCode)
					}
				})
				.catch((error) => {
					console.error(error)
					const errorCode = showErrorComponent(error)
					if (!expiredToken(errorCode)) {
						setShowErrorModal(true)
						setErrorCode(errorCode)
					}
					setSavePOModalPriceModified(false)
					setShowConfirmPOModal(false)
					hideLoadingSpinner()
				})
		},
		[
			purchaseOrder,
			showLoadingSpinner,
			hideLoadingSpinner,
			allBranchOffices,
			supplierRFC,
			poType,
			comment,
			POFromQuotation,
			isStandardFunctionality,
			selectedBranchOffice,
			history
		]
	)

	const handlePoTypeChange = (e: { target: { value: React.SetStateAction<string> } }) => {
		setShowChangePoTypeModal(true)
		setTempNewPoType(e.target.value.toString())
	}

	const changePoTypeAccepted = () => {
		showLoadingSpinner()
		setPurchaseOrder(defaultValuesPurchaseOrder)
		setSelectedSupplier(undefined)
		setSelectedBranchOffice('')
		setSupplierRFC('')
		setDeliveryAddress('')
		if (tempNewPoType) setPoType(tempNewPoType)
		setPurchaseOrder({ ...purchaseOrder, items: [defaultValuesItemPo] })
		setShowChangePoTypeModal(false)
		hideLoadingSpinner()
	}

	const handleSupplierSelection = (supplier: Supplier) => {
		if (supplier) {
			const chosenSupplier = suppliersList?.find((supp) => supp === supplier)
			setSelectedSupplier(chosenSupplier)
			setSupplierRFC(chosenSupplier ? chosenSupplier.rfc : '')
			setSelectedSuppTemp(chosenSupplier)
		} else {
			if (purchaseOrder.items && (purchaseOrder.items.length > 1 || purchaseOrder.items[0]?.product)) {
				setShowDeleteSupplierModal(true)
			} else {
				setSelectedSupplier(undefined)
				setSupplierRFC('')
				setSelectedSuppTemp(undefined)
			}
		}
	}

	const deleteSuppAccept = () => {
		setSupplierRFC('')
		setSelectedSupplier(undefined)
		setPurchaseOrder({
			...purchaseOrder,
			items: [defaultValuesItemPo]
		})
		setShowDeleteSupplierModal(false)
	}

	const deleteSuppCancel = () => {
		showLoadingSpinner()
		setSelectedSupplier(selectedSuppTemp)
		setSupplierRFC(selectedSuppTemp?.rfc ?? '')
		setShowDeleteSupplierModal(false)
		hideLoadingSpinner()
	}

	const setNewComment = (newValue: string) => {
		setComment(newValue)
	}

	const getSuggestion = (searchText: string) => {
		setSuppSrchStrng(searchText)
	}

	const setAdvancePercentage = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.value === '' || event.target.value === '0') {
			setAdvPercentage('')
			setIsAdvAmountEnabled(false)
			setAdvAmount('')
		} else if ((event.target.value as unknown as number) > 100) {
			setAdvPercentage('100')
			setIsAdvAmountEnabled(true)
		} else if (allowDecimals(event.target.value)) {
			setAdvPercentage(event.target.value)
			setIsAdvAmountEnabled(true)
		}
	}

	const setAdvanceAmount = (event: ChangeEvent<HTMLInputElement>) => {
		setAdvAmountError(false)
		if (event.target.value === '' || event.target.value === '0') {
			setAdvAmount('')
		} else if (allowDecimals(event.target.value)) {
			setAdvAmount(event.target.value)
		}
	}

	const checkAdvanceFields = () => {
		const tempAdv = Math.abs(
			Number((((purchaseOrder.total ?? 0) / 100) * (advPercentage as unknown as number)).toFixed(10))
		)
		if (tempAdv == (advAmount as unknown as number)) {
			return true
		} else {
			setAdvAmountError(true)
			return false
		}
	}

	return (
		<NetcurioGrid
			container
			minWidth="100%"
			minHeight="100vh"
			display="grid"
			gridTemplateRows="5.5rem 1fr"
		>
			<Header>
				<div>
					<NetcurioButton
						color="error"
						variant={'outlined'}
						onClick={actionButtonExitNewPO}
						size="small"
						endIcon={<NetcurioIcons.CancelOutlined />}
					>
						<span> {t('cancelText')} </span>
					</NetcurioButton>
				</div>
				<div></div>
			</Header>
			<NetcurioGrid container alignItems="flex-start" height="100%">
				<NetcurioGrid item xs={12} height="100%" width="100%">
					<NetcurioGrid item container xs={12} height="auto">
						<NetcurioGrid item xs={12} className={styles.mainCardContainer}>
							<p>{t('newPO')}</p>
							<p>{t(poType)}</p>
						</NetcurioGrid>
						<NetcurioGrid
							item
							container
							xs={12}
							className={styles.purchaseOrderInformationSection}
						>
							<NetcurioGrid item container xs={6} height="auto">
								<p className={styles.subtitleModule}>{t('orderType')}</p>
								<NetcurioInputLabel htmlFor="po_type" shrink={true}>
									{t('purchaseOrderText')}
								</NetcurioInputLabel>
								<NetcurioSelect
									id={'po_type'}
									value={poType}
									onChange={(e) => handlePoTypeChange(e)}
									fullWidth
									defaultValue={PURCHASE.PURCHASE_ORDER_TYPES.STANDARD}
								>
									<NetcurioMenuItem value={PURCHASE.PURCHASE_ORDER_TYPES.STANDARD}>
										{t(PURCHASE.PURCHASE_ORDER_TYPES.STANDARD)}
									</NetcurioMenuItem>
									<NetcurioMenuItem value={PURCHASE.PURCHASE_ORDER_TYPES.CONSIGNMENT}>
										{t(PURCHASE.PURCHASE_ORDER_TYPES.CONSIGNMENT)}
									</NetcurioMenuItem>
									<NetcurioMenuItem value={PURCHASE.PURCHASE_ORDER_TYPES.EXPRESS}>
										{t(PURCHASE.PURCHASE_ORDER_TYPES.EXPRESS)}
									</NetcurioMenuItem>
								</NetcurioSelect>
							</NetcurioGrid>
							{poType !== PURCHASE.PURCHASE_ORDER_TYPES.CONSIGNMENT ? (
								<NetcurioGrid item container xs={6} height="auto" paddingLeft={'2rem'}>
									<p className={styles.subtitleModule}>{t('advance')}</p>
									<NetcurioGrid item container xs={6} height="auto">
										<NetcurioInputLabel htmlFor="advancePercentage" shrink={true}>
											{t('advancePercentage') + ' (%)'}
										</NetcurioInputLabel>
										<NetcurioTextField
											value={advPercentage}
											onChange={setAdvancePercentage}
											fullWidth
										/>
									</NetcurioGrid>
									<NetcurioGrid item container xs={6} height="auto" paddingLeft={'1rem'}>
										<NetcurioInputLabel htmlFor="advanceAmount" shrink={true}>
											{t('advanceAmount')}
										</NetcurioInputLabel>
										<NetcurioTextField
											value={advAmount}
											onChange={setAdvanceAmount}
											disabled={!isAdvAmountEnabled}
											error={advAmountEror}
											fullWidth
										/>
										<ConditionalRender condition={advAmountEror}>
											<div className={styles.errorText}>
												{t('advanceAmountDoesntMatch')}
											</div>
										</ConditionalRender>
									</NetcurioGrid>
								</NetcurioGrid>
							) : (
								<NetcurioGrid item container xs={6} height="auto"></NetcurioGrid>
							)}
							<NetcurioGrid item container xs={12}>
								<NetcurioGrid item container xs={6}>
									<NetcurioGrid item xs={12}>
										<p className={styles.subtitleModule}>{t('PURCHASE_ORDER')}</p>
									</NetcurioGrid>
									<NetcurioGrid item xs={12}>
										<NetcurioInputLabel htmlFor="" shrink={true}>
											{t('idText')} #
										</NetcurioInputLabel>
										<NetcurioTextField
											value={purchaseOrder.id}
											fullWidth
											disabled={true}
										/>
									</NetcurioGrid>
									<NetcurioGrid item xs={6} paddingTop={'1rem'} paddingRight={'1rem'}>
										<NetcurioInputLabel htmlFor="" shrink={true}>
											{t('createByText')}
										</NetcurioInputLabel>
										<NetcurioTextField
											value={user?.name + ' ' + user?.lastname}
											fullWidth
											disabled={true}
										/>
									</NetcurioGrid>
									<NetcurioGrid item xs={6} paddingTop={'1rem'}>
										<NetcurioInputLabel htmlFor="" shrink={true}>
											{t('createDateText')}
										</NetcurioInputLabel>
										<NetcurioTextField
											value={dayjs().format('DD/MM/YYYY')}
											fullWidth
											disabled={true}
										/>
									</NetcurioGrid>
								</NetcurioGrid>
								<NetcurioGrid item container xs={6} paddingLeft={'2rem'}>
									<NetcurioGrid item xs={12}>
										<p className={styles.subtitleModule}>{t('companyInformation')}</p>
									</NetcurioGrid>
									<NetcurioGrid item xs={6} paddingRight={'1rem'}>
										<NetcurioInputLabel htmlFor="" shrink={true}>
											{t('companyNameText')}
										</NetcurioInputLabel>
										<NetcurioTextField
											value={user?.company?.name}
											fullWidth
											disabled={true}
										/>
									</NetcurioGrid>
									<NetcurioGrid item xs={6}>
										<NetcurioInputLabel htmlFor="" shrink={true}>
											{t('textRFC')}
										</NetcurioInputLabel>
										<NetcurioTextField
											value={user?.company?.rfc}
											fullWidth
											disabled={true}
										/>
									</NetcurioGrid>
									<NetcurioGrid item xs={12} paddingTop={'1rem'}>
										<NetcurioInputLabel htmlFor="" shrink={true}>
											{t('addressInputNewCompany')}
										</NetcurioInputLabel>
										<NetcurioTextField
											value={user?.company?.address_line_1}
											fullWidth
											disabled={true}
										/>
									</NetcurioGrid>
								</NetcurioGrid>
							</NetcurioGrid>
							<NetcurioGrid item container xs={12}>
								<NetcurioGrid item container xs={12}>
									<p className={styles.subtitleModule}>{t('deliveryBranch')}</p>
								</NetcurioGrid>
								<NetcurioGrid item container xs={6}>
									<NetcurioInputLabel htmlFor="branch_office" shrink={true}>
										{t('titleBranchOfficeSelect')}
									</NetcurioInputLabel>
									<NetcurioSelect
										id={'branch_office'}
										onChange={(e) => handleSelectBranch(e.target.value)}
										value={selectedBranchOffice}
										defaultValue={''}
										fullWidth
									>
										{allBranchOffices?.map((item, i) => (
											<NetcurioMenuItem key={i} value={item.description}>
												<span>{item.description}</span>
											</NetcurioMenuItem>
										))}
									</NetcurioSelect>
								</NetcurioGrid>
								<NetcurioGrid item xs={6}></NetcurioGrid>
								<NetcurioGrid item xs={12} sx={{ marginTop: '1rem' }}>
									<NetcurioInputLabel htmlFor="" shrink={true}>
										{t('addressDelivery')}
									</NetcurioInputLabel>
									<NetcurioTextField
										id={t('status_product')}
										value={deliveryAddress}
										fullWidth
										disabled={true}
									/>
								</NetcurioGrid>
							</NetcurioGrid>
							<NetcurioGrid item container xs={12}>
								<NetcurioGrid item xs={12}>
									<p className={styles.subtitleModule}>{t('supplierInformation')}</p>
								</NetcurioGrid>
								<NetcurioGrid item xs={4}>
									<NetcurioInputLabel htmlFor="" shrink={true}>
										{t('supplier_PO')}
									</NetcurioInputLabel>
									<NetcurioAutocomplete
										fullWidth
										height="smaller"
										size="small"
										variant="outlined"
										options={suppliersList}
										getOptionLabel={getSupplierLabel}
										isOptionEqualToValue={validateSupplierValue}
										value={selectedSupplier}
										onSelectValue={handleSupplierSelection}
										onInputValueChange={getSuggestion}
										loading={loading}
										minLength={3}
										onOpen={handleSuppOpen}
										onClose={handleSuppOpen}
									/>
								</NetcurioGrid>
								<NetcurioGrid item xs={4} paddingLeft={'1rem'}>
									<NetcurioInputLabel htmlFor="" shrink={true}>
										{t('suppliersRFC')}
									</NetcurioInputLabel>
									<NetcurioTextField value={supplierRFC} fullWidth disabled={true} />
								</NetcurioGrid>
								<NetcurioGrid item container xs={4}></NetcurioGrid>
							</NetcurioGrid>
						</NetcurioGrid>
					</NetcurioGrid>
					<NetcurioGrid item container xs={12} height="auto" paddingTop={'1rem'}>
						<NetcurioGrid item xs={9}>
							<NetcurioGrid item container xs={12} className={styles.mainCardContainer}>
								<p>{t('productsText')}</p>
							</NetcurioGrid>
							<NetcurioGrid
								item
								container
								xs={12}
								className={styles.purchaseOrderInformationSection}
							>
								{purchaseOrder.items.map((item, key) => (
									<div key={key}>
										<Item
											itemPosition={key}
											deleteItem={deleteItem}
											handleItemFieldChange={handleItemFieldChange}
											itemData={item}
											selectedSupplier={selectedSupplier}
											storageLocations={storageLocations}
											selectedProductItem={selectedProductItem}
											totalErrorsByPurchaseOrder={totalErrorsByPO}
											isBranchSelected={!!selectedBranchOffice}
											POFromQuotation={POFromQuotation}
										/>
									</div>
								))}
								<NetcurioButton
									startIcon={<NetcurioIcons.AddCircleOutlineOutlined />}
									onClick={addItem}
									variant="text"
								>
									{t('addItem')}
								</NetcurioButton>
							</NetcurioGrid>
						</NetcurioGrid>
						<NetcurioGrid item xs={3} paddingLeft={'1rem'}>
							<CommentsSection
								variant={CommentSectionVariant.Simple}
								onChange={setNewComment}
							/>
							<NetcurioGrid item paddingTop={'1rem'}>
								<TotalContainerBar
									title="total"
									fields={{
										currencyText: 'MXN'
									}}
									total={{
										totalDotText: getCurrency(
											purchaseOrder.total ? purchaseOrder.total : 0
										)
									}}
								/>
							</NetcurioGrid>
							<NetcurioGrid
								item
								paddingTop={'1rem'}
								paddingBottom={'1rem'}
								className={styles.totalBtnContainer}
							>
								<NetcurioButton
									variant="outlined"
									color="primary"
									disabled={!canUserSubmitPO}
									onClick={saveDataNewPurchase}
								>
									{t('makeOrder')}
								</NetcurioButton>
							</NetcurioGrid>
						</NetcurioGrid>
					</NetcurioGrid>
				</NetcurioGrid>
			</NetcurioGrid>
			<ErrorModal open={showErrorModal} errorCode={errorCode ?? ''} />
			<ChangePoTypeModal
				open={showChangePoTypeModal}
				close={() => setShowChangePoTypeModal(false)}
				onAccept={changePoTypeAccepted}
			/>
			<DeleteSupplierModal
				open={showDeleteSupplierModal}
				close={deleteSuppCancel}
				onAccept={deleteSuppAccept}
			/>
			<ConfirmPurchaseOrderModal
				open={showConfirmPOModal}
				close={() => setShowConfirmPOModal(false)}
				accept={() => createNewPO(undefined)}
			/>
			<PriceModifiedModal
				open={savePOModalPriceModified}
				close={() => setSavePOModalPriceModified(false)}
				accept={(reason) => createNewPO(reason)}
			/>
			<CancelPOCreationModal
				open={openCancelModal}
				close={() => setOpenCancelModal(false)}
				accept={() => {
					sessionStorage.clear()
					history.goBack()
				}}
			/>
		</NetcurioGrid>
	)
}
