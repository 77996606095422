import { dateFormatter } from '@netcurio/frontend-common'
import {
	NetcurioButton,
	NetcurioIcons,
	NetcurioTableCell,
	NetcurioTableRow,
	NetcurioTooltip,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import { Auth } from 'aws-amplify'
import React, { useEffect, useMemo, useState } from 'react'
import { File } from '../../types/File'
import { connection } from '../../utilities/connection'
import * as queries from '../../views/administratorConsole/tabs/users/userDetail/queries'
import { DeleteDocumentModal } from '../DeleteDocumentModal/DeleteDocumentModal'
import styles from './AttachedDocumentsModal.module.scss'
interface AttachedDocumentRow {
	file: File
	folderUUID: string
	getInvoiceInfo(): void
}

export const AttachedDocumentRow = ({ file, getInvoiceInfo, folderUUID }: AttachedDocumentRow) => {
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const [openDeleteDocModal, setOpenDeleteDocModal] = useState(false)
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [userName, setUserName] = useState('')

	useEffect(() => {
		getUser(file.created_by)
	}, [file.created_by])

	const deleteDocument = async () => {
		showLoadingSpinner()
		try {
			const token = await Auth.currentAuthenticatedUser()
				.then((user) => {
					return user.signInUserSession.idToken.jwtToken
				})
				.catch((err) => {
					console.error(err)
				})
			const headers = {
				'Content-Type': 'application/json; charset=UTF-8',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token
			}
			await fetch(`/api/v1/files/${folderUUID}/${file.name}`, {
				method: 'DELETE',
				headers
			})
		} catch (error) {
			console.error(error)
		} finally {
			getInvoiceInfo()
			setOpenDeleteDocModal(false)
			hideLoadingSpinner()
		}
	}

	const convertToKB = (size: number) => {
		return (size / 1024).toFixed(2)
	}

	const downloadFile = () => {
		if (!file.url) {
			throw new Error('Resource URL not provided! You need to provide one')
		}
		fetch(file.url)
			.then((response) => response.blob())
			.then((blob) => {
				const blobURL = URL.createObjectURL(blob)
				const a = document.createElement('a')
				a.href = blobURL

				if (file.description && file.description.length) a.download = file.description
				document.body.appendChild(a)
				a.click()
			})
			.catch((err) => {
				throw err
			})
	}

	const getUser = async (email: string) => {
		const result = await client.query({
			query: queries.USER,
			variables: {
				email
			},
			fetchPolicy: 'no-cache'
		})
		if (result.data.User) {
			setUserName(`${result.data.User.name} ${result.data.User.lastname}`)
		} else {
			throw 'User not found'
		}
	}

	return (
		<NetcurioTableRow>
			{/* TODO: This section will be implemented further on when downloading all */}
			<NetcurioTableCell align="left" sx={{ padding: 0 }}>
				<NetcurioTooltip title={file.description} placement="top">
					<div className={styles.documentInfoContainer}>
						<p className={styles.docName}>{file.description}</p>
						<span className={styles.docMetadata}>
							{convertToKB(file.file_size)} KB •{' '}
							{dateFormatter.format(new Date(file.created_at).getTime())} • {userName}
						</span>
					</div>
				</NetcurioTooltip>
			</NetcurioTableCell>
			<NetcurioTableCell align="right" sx={{ padding: 0, width: 0 }}>
				<NetcurioButton
					size="small"
					variant="borderless"
					color="primary"
					endIcon={<NetcurioIcons.Download fontSize="small" />}
					onClick={downloadFile}
				/>
			</NetcurioTableCell>
			<NetcurioTableCell align="right" sx={{ padding: 0, width: 0 }}>
				<NetcurioButton
					variant="borderless"
					color="primary"
					sx={{ paddingLeft: 0 }}
					endIcon={<NetcurioIcons.Delete fontSize="small" />}
					onClick={() => setOpenDeleteDocModal(true)}
				/>
			</NetcurioTableCell>
			<DeleteDocumentModal
				open={openDeleteDocModal}
				onClose={() => setOpenDeleteDocModal(false)}
				deleteDocument={deleteDocument}
			/>
		</NetcurioTableRow>
	)
}
