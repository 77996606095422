import { URLS } from '@netcurio/frontend-common'
import {
	FilePairs,
	FileResult,
	FileUpload,
	NetcurioButton,
	NetcurioDialog,
	PairFilesIcon,
	ProcessFileUpload,
	Severity,
	ValidationFiles
} from '@netcurio/frontend-components'
import { useQuery } from '@tanstack/react-query'
import { Auth } from 'aws-amplify'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchEnvironment } from '../../api/fetch-environment'
import { ExtraRequestParams } from '../../types/FileUpload'
import { refreshToken } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { uploadCFDI } from '../../utilities/uploadCFDI'
import {
	validateFilesHavePairs,
	validateNewFiles,
	validateSizeOfFiles,
	validateTypeOfFile
} from '../../utilities/uploadFilesFuctions'
import styles from './NewInvoiceModal.module.scss'

interface NewInvoiceModalProps {
	open: boolean
	onClose: () => void
	redirectToDetail?: (detail: string) => void
	extraRequestParams?: ExtraRequestParams
	isCustomer: boolean
	companyHasStandAloneEnable: boolean
}

/**
 * NewInvoiceModal
 * @param open <boolean>: If `true`, the component is shown.
 * @param onClose <void>: Callback fired when the component requests to be closed.
 * @param redirectToDetail <(detail: string) => void>: Function to execute a redirect
 * @param extraRequestParams <ExtraRequestParams>: Extra params for the request to the backend
 * @param isCustomer<boolean> validate is the actual user is customer
 * @param companyHasStandAloneEnable<boolean> validate is the actual company has the variable standalone_invoice_workflow_enabled enable
 * @returns component
 */
export function NewInvoiceModal({
	open,
	onClose,
	redirectToDetail,
	extraRequestParams,
	isCustomer,
	companyHasStandAloneEnable
}: NewInvoiceModalProps) {
	const { t } = useTranslation()

	const { SELECT, UPLOADING, FINISHED } = ProcessFileUpload
	const { PDF, XML } = Constants.MIME_TYPES

	const acceptFileTypes = `${PDF},${XML}`

	const [canUpload, setCanUpload] = useState(false)
	const [stopUpload, setStopUpload] = useState(false)
	const [process, setProcess] = useState<ProcessFileUpload>(SELECT)
	const [filesToUpload, setFilesToUpload] = useState<Array<File>>([])
	const [filesResult, setFilesResult] = useState<Array<FileResult>>([])

	const filePairs: FilePairs = useMemo(() => {
		return filesToUpload.reduce((group, file) => {
			const nameTrimmed = file.name.substring(0, file.name.lastIndexOf('.'))
			group[nameTrimmed] = group[nameTrimmed] ?? { files: [], isLoading: false }
			group[nameTrimmed].files.push(file)
			return group
		}, {})
	}, [filesToUpload])

	const filesErrorCounts = useMemo(() => {
		const filesPairs = Object.values(filePairs).map((fp) => fp.files) as Array<Array<File>>
		const filesNoPairs = filesPairs.filter((files) => files.length < 2).flat()
		return filesNoPairs.reduce((counts, file) => ({ ...counts, [file.type]: counts[file.type] + 1 }), {
			[PDF]: 0,
			[XML]: 0
		})
	}, [filePairs, PDF, XML])

	const pairFilesUploaded = useMemo(
		() => filesResult.filter((file) => file.icon === PairFilesIcon.Success).length,
		[filesResult]
	)

	useEffect(() => {
		return () => {
			setProcess(SELECT)
			setFilesToUpload([])
			setStopUpload(false)
		}
	}, [open, SELECT])

	useEffect(() => setFilesResult(Object.values(filePairs)), [filePairs])

	const { data: environment, isSuccess } = useQuery({
		queryKey: ['environment'],
		queryFn: fetchEnvironment,
		suspense: true,
		staleTime: Infinity
	})

	const typeOfFileValidation = (files: Array<File>) => validateTypeOfFile(files, acceptFileTypes)
	const filesHavePairs = () => validateFilesHavePairs(filesErrorCounts)
	const validateFilesToUpload = [typeOfFileValidation, validateSizeOfFiles, filesHavePairs]

	function filesUploaded(filesResult: Array<FileResult>): ValidationFiles {
		const total = filesResult.length
		const updated = pairFilesUploaded
		const CFDIName = t('invoiceName')
		return {
			severity: total === updated ? Severity.Success : Severity.Info,
			message: t('updatedBillsText', { updated, total, CFDIName })
		}
	}

	function associationNeeded(): ValidationFiles | void {
		const updated = pairFilesUploaded

		if (!(isCustomer && companyHasStandAloneEnable) && updated > 0 && !extraRequestParams) {
			return { severity: Severity.Info, message: t('messageAssociationNeeded') }
		}
	}
	const nextSteps = () => {
		const updated = pairFilesUploaded
		if (process === FINISHED && updated) {
			return {
				severity: Severity.Info,
				message: t('followStepsInvoiceDetails')
			}
		}
		return
	}

	const validateFilesResult = [nextSteps, filesUploaded, associationNeeded]

	const getToken = useCallback(async () => {
		return await Auth.currentAuthenticatedUser()
			.then((user) => user.signInUserSession.idToken.jwtToken)
			.catch(console.log)
	}, [])

	const submitFiles = useCallback(
		async (controller: AbortController, stopUpload: boolean) => {
			if (!stopUpload) {
				const { SUCCESSFUL, ERROR, UNKNOWN_ERROR } = Constants.UPLOAD_FILES.UPLOAD_FILES_RESPONSE
				const { UPLOADING_STOPPED } = Constants.UPLOAD_FILES.UPLOAD_FILES_ERRORS
				const { INVOICE: expectedCFDIType } = Constants.UPLOAD_FILES.EXPECTED_CFDI_TYPES

				refreshToken()
				const token = await getToken()
				for (const [fileName, filePairsObj] of Object.entries(filePairs)) {
					filePairs[fileName].isLoading = true
					setFilesResult(Object.values(filePairs))

					const uploadParams = { expectedCFDIType, extraRequestParams }
					const response = await uploadCFDI(
						environment?.REST_API_URL || '',
						filePairsObj.files,
						token,
						uploadParams,
						controller
					)
					if (response[SUCCESSFUL]) {
						filePairs[fileName].icon = PairFilesIcon.Success
						filePairs[fileName].UUID = response[SUCCESSFUL]
					} else if (response[ERROR] === '20') {
						filePairs[fileName].icon = PairFilesIcon.Stop
						filePairs[fileName].errorText = t(UPLOADING_STOPPED)
					} else {
						filePairs[fileName].icon = PairFilesIcon.Warning
						filePairs[fileName].errorText = t(response[ERROR] || response[UNKNOWN_ERROR])
					}
					filePairs[fileName].isLoading = false
					setFilesResult(Object.values(filePairs))
				}
				setProcess(FINISHED)
			}
		},
		[filePairs, extraRequestParams, getToken, t, FINISHED, isSuccess]
	)

	useEffect(() => {
		let controller: AbortController
		if (process === UPLOADING) {
			controller = new AbortController()
			submitFiles(controller, stopUpload)
		}
		return () => {
			controller?.abort()
		}
	}, [submitFiles, process, stopUpload, UPLOADING])

	const updateFilesToUpload = (fileList: FileList) =>
		setFilesToUpload((state) => validateNewFiles(fileList, state))

	const cancelUpload = () => setStopUpload(true)
	const processData = () => setProcess(UPLOADING)
	const deleteFile = (index: React.Key) => setFilesToUpload((state) => state.filter((_, i) => i !== index))
	function closeDialog() {
		onClose()
		if (
			redirectToDetail &&
			!extraRequestParams &&
			pairFilesUploaded === 1 &&
			pairFilesUploaded === filesResult.length
		) {
			redirectToDetail(filesResult[0].UUID)
		} else if (pairFilesUploaded > 0) {
			if (window.location.pathname === URLS.DASHBOARDS) location.href = URLS.INVOICE_LIST
			else location.reload()
		}
	}

	const actionButtons = (
		<div className={styles.actionButtonsForDialog}>
			<NetcurioButton variant="text" onClick={closeDialog} disabled={process === UPLOADING}>
				{process === SELECT ? t('cancelButton') : t('closeText')}
			</NetcurioButton>
			{process === SELECT ? (
				<NetcurioButton variant="contained" onClick={processData} disabled={!canUpload}>
					{t('processText')}
				</NetcurioButton>
			) : process === FINISHED ? (
				<NetcurioButton onClick={processData} disabled={true}>
					{t('stopText')}
				</NetcurioButton>
			) : (
				<NetcurioButton variant="contained" onClick={cancelUpload} disabled={process !== UPLOADING}>
					{t('stopText')}
				</NetcurioButton>
			)}
		</div>
	)

	return (
		<NetcurioDialog
			open={open}
			actionButtons={actionButtons}
			titleText={t('uploadNewInvoice')}
			minWidth="960px"
			maxWidth="960px"
		>
			<FileUpload
				fileUploadDescription={[t('fileInvoiceFormats'), t('associateInvoiceSteps')]}
				dragAndDropText={t('dragAndDrop')}
				waitUploadFilesText={t('invoicesBeingProcessed')}
				acceptFileTypes={acceptFileTypes}
				acceptMultipleFiles={true}
				process={process}
				filesToUpload={filesToUpload}
				filesResult={filesResult}
				validateFilesToUpload={validateFilesToUpload}
				validateFilesResult={validateFilesResult}
				setCanUpload={setCanUpload}
				updateFilesToUpload={updateFilesToUpload}
				deleteFile={deleteFile}
			/>
		</NetcurioDialog>
	)
}
