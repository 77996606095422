import { Status, dateFormatter, parseTimestampToTimeZone } from '@netcurio/frontend-common'
import {
	NetcurioCheckbox,
	NetcurioDatePicker,
	NetcurioDialog,
	NetcurioFormControlLabel,
	NetcurioFormLabel,
	NetcurioRadioButton,
	NetcurioTextField
} from '@netcurio/frontend-components'
import { TextArea } from '@netcurio/frontend-components/src'
import dayjs, { Dayjs } from 'dayjs'
import { t } from 'i18next'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useApprovalFields } from '../../../../../hooks/useApprovalFields'
import { Invoice } from '../../../../../types'
import { Validations } from '../../../../../types/Validations'
import constants from '../../../../../utilities/constants'
import { ValidationTable } from '../ValidationTable/ValidationTable'
import { ActionButtons } from './ActionButtons'
import { AuthorizationInfo } from './AuthorizationInfo'
import { Selectors } from './Selectors'
import styles from './StandaloneDocumentManuallyAuthModal.module.scss'

interface StandaloneDocumentManuallyAuthModalProps {
	open: boolean
	invoice: Invoice
	onClose(): void
	onManualAuthorization(
		motive: string,
		cost_center_key: string | null,
		expense_type_key: string | null,
		expense_subtype_key: string | null,
		bank_reference: string | null,
		payment_date: string | null
	): void
	previousValidation: Validations
	currentValidation: Validations
}

export const StandaloneDocumentManuallyAuthModal = ({
	open,
	invoice: { receiver },
	onClose,
	onManualAuthorization,
	invoice,
	previousValidation: initialPreviousValidation,
	currentValidation: initialCurrentValidation
}: StandaloneDocumentManuallyAuthModalProps) => {
	const [motiveChange, setMotiveChange] = useState(invoice.override_message ?? '')
	const [isDisclaimerChecked, setIsDisclaimerChecked] = useState(false)
	const [inputValues, setInputValues] = useState({
		costCenterValue: invoice.cost_center_key ?? '',
		expenseTypeValue: invoice.expense_type_key ?? '',
		expenseSubtypeValue: invoice.expense_subtype_key ?? '',
		bankReference: invoice.bank_reference ?? '',
		paymentDate: invoice.payment_date ? parseTimestampToTimeZone(invoice.payment_date) : ''
	})
	const [isInvoicePaidChecked, setIsInvoicePaidChecked] = useState<boolean>(() => {
		return Boolean(invoice.payment_date)
	})
	const [motiveChangeError, setMotiveChangeError] = useState<string | undefined>()
	const [costCenterError, setCostCenterError] = useState<boolean>(false)
	const [expenseTypeError, setExpenseTypeError] = useState<boolean>(false)
	const [expenseSubtypeError, setExpenseSubtypeError] = useState<boolean>(false)
	const [paidDateError, setPaidDateError] = useState<boolean>(false)

	const { costCenterRequired, expenseTypeRequired, costCenters, expenseTypes, expenseSubtypes } =
		useApprovalFields({
			rfc: receiver?.rfc ?? '',
			selectedExpenseTypeKey: inputValues.expenseTypeValue
		})

	useEffect(() => {
		setInputValues((prev) => ({ ...prev, expenseSubtypeValue: '' }))
	}, [inputValues.expenseTypeValue])

	const readOnly = useMemo(
		() =>
			![constants.INVOICE.IN_APPROVAL, constants.STATUS.ERROR].includes(
				(invoice.status as Status).key
			) && Boolean(invoice.override_message),
		[invoice]
	)

	const validationFromInvoice = useMemo(() => {
		return {
			accountingPeriodValidation: {
				value: invoice?.accounting_period_validation ?? 'OK',
				message: ''
			},
			satValidation: {
				value: invoice?.sat_validation ?? 'OK',
				message: invoice?.sat_validation_message === undefined ? '' : invoice.sat_validation_message
			}
		}
	}, [invoice])

	const currentValidation = useMemo(() => {
		if (readOnly) return validationFromInvoice
		return {
			accountingPeriodValidation: initialCurrentValidation.accountingPeriodValidation,
			satValidation: initialCurrentValidation.satValidation
		}
	}, [initialCurrentValidation, readOnly, validationFromInvoice])

	const previousValidation = useMemo(() => {
		if (readOnly) return validationFromInvoice
		return {
			accountingPeriodValidation: initialPreviousValidation.accountingPeriodValidation,
			satValidation: initialPreviousValidation.satValidation
		}
	}, [initialPreviousValidation, readOnly, validationFromInvoice])

	const passAllValidations = useMemo(() => {
		if (readOnly) return false
		return (
			currentValidation?.accountingPeriodValidation?.value === 'OK' &&
			currentValidation?.satValidation?.value === 'OK'
		)
	}, [currentValidation, readOnly])

	const modalTitle = useMemo(() => {
		return readOnly ? t('invoice.authorizationReason') : t('authorizeManualInvoice.header')
	}, [readOnly])

	const modalDescription = useMemo(() => {
		return readOnly ? t('authorizeManualInvoice.summary') : t('authorizeManualInvoice.description')
	}, [readOnly])

	const onAcceptAuthorization = () => {
		let allChecksPassed = true
		if (costCenterRequired && (!inputValues.costCenterValue || inputValues.costCenterValue === '')) {
			setCostCenterError(true)
			allChecksPassed = false
		} else {
			setCostCenterError(false)
		}
		if (expenseTypeRequired && (!inputValues.expenseTypeValue || inputValues.expenseTypeValue === '')) {
			setExpenseTypeError(true)
			allChecksPassed = false
		} else {
			setExpenseTypeError(false)
		}
		if (
			expenseTypeRequired &&
			(!inputValues.expenseSubtypeValue || inputValues.expenseSubtypeValue === '')
		) {
			setExpenseSubtypeError(true)
			allChecksPassed = false
		} else {
			setExpenseSubtypeError(false)
		}
		if (isInvoicePaidChecked && !inputValues.paymentDate) {
			setPaidDateError(true)
			allChecksPassed = false
		} else {
			setPaidDateError(false)
		}
		if (!motiveChange || motiveChange === '') {
			setMotiveChangeError(t('missingFields'))
			allChecksPassed = false
		} else {
			setMotiveChangeError(undefined)
		}
		if (allChecksPassed) {
			const data: { [key: string]: string | null } = {
				costCenter: inputValues.costCenterValue,
				expenseType: inputValues.expenseTypeValue,
				expenseSubtype: inputValues.expenseSubtypeValue,
				bankReference: inputValues.bankReference,
				paymentDate: inputValues.paymentDate?.toString() ?? ''
			}
			Object.keys(data).forEach((key) => {
				if (!data[key]) {
					data[key] = null
				}
			})

			onManualAuthorization(
				motiveChange,
				data?.costCenter,
				data?.expenseType,
				data?.expenseSubtype,
				data?.bankReference,
				data?.paymentDate?.toString() ?? ''
			)
		}
	}

	const isSubmitButtonEnable = useMemo(() => {
		return isDisclaimerChecked
	}, [isDisclaimerChecked])

	useEffect(() => {
		if (open) {
			setMotiveChange(invoice.override_message ?? '')
			setIsDisclaimerChecked(false)
			setInputValues({
				costCenterValue: invoice.cost_center_key ?? '',
				expenseTypeValue: invoice.expense_type_key ?? '',
				expenseSubtypeValue: invoice.expense_subtype_key ?? '',
				bankReference: invoice.bank_reference ?? '',
				paymentDate: invoice.payment_date ? parseTimestampToTimeZone(invoice.payment_date) : ''
			})
		}
	}, [open, invoice])

	const switchPaidValue = (): void => {
		setIsInvoicePaidChecked((prev) => !prev)
		setInputValues({ ...inputValues, bankReference: '', paymentDate: '' })
	}

	const handleBankRefChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setInputValues({ ...inputValues, bankReference: e.target.value })
	}

	return (
		<NetcurioDialog
			titleText={modalTitle}
			open={open}
			actionButtons={
				<ActionButtons
					onAuthorize={onAcceptAuthorization}
					onClose={onClose}
					disabled={!isSubmitButtonEnable}
					displaySubmitButton={!readOnly}
				/>
			}
			minWidth={'60rem'}
			maxWidth={'60rem'}
			contentFontSize={'0'}
		>
			<div className={styles.authorizeContainerColumn}>
				<div>
					<p className={styles.labels}>
						<Trans>{modalDescription}</Trans>
					</p>
				</div>
				<div className={styles.tableContainer}>
					<ValidationTable
						previousValidation={previousValidation}
						currentValidation={currentValidation}
						passAllValidations={passAllValidations}
					/>
				</div>
				{!readOnly && (
					<NetcurioFormControlLabel
						control={
							<NetcurioCheckbox onClick={() => setIsDisclaimerChecked(!isDisclaimerChecked)} />
						}
						value={isDisclaimerChecked}
						label={t('manuallyAuthorizedDisclaimer')}
						sx={{ margin: 0 }}
					/>
				)}
				<Selectors
					costCenterOptions={costCenters}
					expenseTypeOptions={expenseTypes}
					expenseSubtypeOptions={expenseSubtypes}
					readonly={readOnly}
					onChangeCostCenter={(value: string) =>
						setInputValues({ ...inputValues, costCenterValue: value })
					}
					onChangeExpenseType={(value: string) =>
						setInputValues({ ...inputValues, expenseTypeValue: value })
					}
					onChangeExpenseSubtype={(value: string) =>
						setInputValues({ ...inputValues, expenseSubtypeValue: value })
					}
					costCenterError={costCenterError}
					expenseTypeError={expenseTypeError}
					expenseSubtypeError={expenseSubtypeError}
					{...inputValues}
				/>
				<AuthorizationInfo
					hasValues={readOnly}
					authorizedBy={
						invoice?.override_by
							? `${invoice?.override_by?.name} ${invoice?.override_by?.lastname}`
							: ''
					}
					authorizedDate={
						invoice?.override_on
							? dateFormatter.format(parseTimestampToTimeZone(invoice.override_on))
							: 'NA'
					}
				/>

				<div className={styles.textAreaPaddingElimination}>
					<TextArea
						rows={3}
						placeholder={t('authorizeManualInvoice.textArea')}
						onChangeText={(response: string) => setMotiveChange(response)}
						readValue={motiveChange}
						maxLength={500}
						disabledInput={readOnly}
						errorMessage={motiveChangeError}
					/>
					{!readOnly && <p className={styles.minimumChar}>{t('max500Char')}</p>}
				</div>
				<div className={styles.isPaidInvoiceContainer}>
					<span className={styles.inlineSpan}>
						<NetcurioFormLabel>{t('authorizeManualInvoice.isInvoicePaid')}</NetcurioFormLabel>
					</span>
					<span className={styles.inline}>
						<NetcurioFormLabel>
							<p>{t('authorizeManualInvoice.yes')}</p>
						</NetcurioFormLabel>
						<NetcurioRadioButton checked={isInvoicePaidChecked} onChange={switchPaidValue} />
					</span>
					<span className={styles.inline}>
						<NetcurioFormLabel>
							<p>{t('authorizeManualInvoice.no')}</p>
						</NetcurioFormLabel>
						<NetcurioRadioButton checked={!isInvoicePaidChecked} onChange={switchPaidValue} />
					</span>
				</div>
				<div className={styles.textfieldsContainer}>
					<NetcurioTextField
						label={t('authorizeManualInvoice.bankReference')}
						size="small"
						value={inputValues.bankReference}
						placeholder={t('placeholderProduct')}
						onChange={(e) => handleBankRefChange(e)}
						disabled={!isInvoicePaidChecked || readOnly}
					/>
					<NetcurioDatePicker
						height="smaller"
						label={t('authorizeManualInvoice.paidDate')}
						format="DD/MM/YY"
						value={inputValues.paymentDate ? dayjs(inputValues.paymentDate) : undefined}
						onChange={(date: Dayjs) =>
							setInputValues({ ...inputValues, paymentDate: date.toString() })
						}
						disabled={!isInvoicePaidChecked || readOnly}
						error={paidDateError}
					/>
				</div>
				{paidDateError ? <p className={styles.errorMsgDate}>{t('missingFields')}</p> : <></>}
			</div>
		</NetcurioDialog>
	)
}
