import { dateFormatter, getSerieFolio, parseTimestampToTimeZone, Roles } from '@netcurio/frontend-common'
import {
	accountedStatus,
	canceledStatus,
	DetailTable,
	errorStatus,
	missingLinkStatus,
	NetcurioMenuItem,
	NetcurioSelect,
	NetcurioTooltip,
	StatusInformation,
	statusLoading,
	TrackInformation,
	voidedStatus
} from '@netcurio/frontend-components'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { CreditMemo, ErrorItems, RequestForCreditMemoItem } from '../../types'
import Constants from '../../utilities/constants'
import Formatter from '../../utilities/formatter'
import styles from './creditMemoDetail.module.scss'
import { ItemsTableData, StatusMessage, TrackInformationData } from './types'

interface InformationContainerProps {
	creditMemoDetail: CreditMemo
	showHeaderMessage: boolean
	userRole: string
	rfcmItems: RequestForCreditMemoItem[]
	closeWindow?: () => void
	onRfcmItemAssociation(rfcmItemPosition: RequestForCreditMemoItem, index: number): void
	cmHasBeenSent?: boolean
}

export const InformationContainer = ({
	creditMemoDetail,
	showHeaderMessage,
	userRole,
	rfcmItems,
	onRfcmItemAssociation,
	closeWindow,
	cmHasBeenSent
}: InformationContainerProps): React.ReactElement => {
	const { t } = useTranslation()
	const errorItems: Array<ErrorItems> = []
	const statusMessageCM: StatusMessage = {
		missingLink:
			creditMemoDetail?.status.key === Constants.LINKS.MISSING_LINK && userRole === Roles.SUPPLIER,
		error: {
			key: creditMemoDetail?.status.key === Constants.ERROR.ERROR,
			value: creditMemoDetail?.error || 'showError'
		}
	}
	const creditMemoItems = creditMemoDetail?.items

	const mapTrackInformationData = useCallback((): TrackInformationData => {
        return {
			emission_date: dateFormatter.format(parseTimestampToTimeZone(creditMemoDetail?.date)),
			emissary: creditMemoDetail?.sender.name ?? '',
			emissary_rfc: creditMemoDetail?.sender.rfc ?? '',
			payment_method: creditMemoDetail?.payment_method,
			receptor: creditMemoDetail?.receiver.name ?? '',
			receptor_rfc: creditMemoDetail?.receiver.rfc ?? '',
			uploaded_date: dateFormatter.format(parseTimestampToTimeZone(creditMemoDetail?.created_at)),
			...(userRole === Roles.CUSTOMER ? {} : { uploaded_by: creditMemoDetail?.uploaded_by }),
			...(creditMemoDetail?.invoice
				? {
						serie_folio_uuid: `${getSerieFolio(
							creditMemoDetail?.invoice?.serie,
							creditMemoDetail?.invoice?.folio
						)} - ${creditMemoDetail?.invoice?.uuid}`
					}
				: { invoice_reference: creditMemoDetail?.invoice_reference }),
			uuid: creditMemoDetail?.uuid
		}
	}, [creditMemoDetail])

	const mapItemsTable = useCallback((): ItemsTableData[] => {
		return (
			creditMemoItems?.map((item, creditMemoItemIndex) => {
				if (item.error)
					errorItems.push({
						index: creditMemoItemIndex,
						error: true
					})
				return {
					position: item.position,
					concept: item.concept,
					amount: item.quantity,
					net_amount: Formatter.currency.format(item.net_amount),
					tax: Formatter.percent.format(item.tax),
					request_concept:
						(rfcmItems.length > 0 && !cmHasBeenSent) ||
						(userRole === Roles.SUPPLIER && statusMessageCM.error.key) ? (
							<NetcurioSelect
								variant={'outlined'}
								key={`request-concept-auto-complete-${item.position}`}
								fullWidth
							>
								{rfcmItems.map((rfcmItem) => (
									<NetcurioMenuItem
										key={rfcmItem.position}
										value={`${rfcmItem.position} - ${rfcmItem.concept_description} - ${rfcmItem.net_amount}`}
										onClick={() => onRfcmItemAssociation(rfcmItem, creditMemoItemIndex)}
									>
										{`${rfcmItem.position} - ${rfcmItem.concept_description} - ${rfcmItem.net_amount}`}
									</NetcurioMenuItem>
								))}
							</NetcurioSelect>
						) : item.request_for_credit_memo_item || cmHasBeenSent ? (
							<span className={styles.cmRequestConceptField}>
								<NetcurioTooltip
									title={item.request_for_credit_memo_item?.concept_description ?? ''}
									placement={'top'}
								>
									<span className={styles.cmRequestConceptFieldConceptDescription}>
										{item.request_for_credit_memo_item?.concept_description ?? ''}
									</span>
								</NetcurioTooltip>
								<span>
									{t('amount')}
									{Formatter.currency.format(
										item.request_for_credit_memo_item?.net_amount ?? 0
									)}
								</span>
								<br />
								<span>
									{t('iva')}
									{Formatter.percent.format(item.request_for_credit_memo_item?.tax ?? 0)}
								</span>
							</span>
						) : (
							''
						)
				}
			}) || []
		)
	}, [creditMemoItems, rfcmItems, cmHasBeenSent])

	const getStatuses = useCallback((): StatusInformation[] => {
		const statuses = {
			[Constants.CREDIT_MEMO_STATUS.MISSING_LINK]: [missingLinkStatus()],
			[Constants.CREDIT_MEMO_STATUS.ERROR]: [errorStatus()],
			[Constants.CREDIT_MEMO_STATUS.ACCOUNTED]: [accountedStatus()],
			[Constants.CREDIT_MEMO_STATUS.VOIDED]: [voidedStatus()],
			[Constants.CREDIT_MEMO_STATUS.CANCELED]: [accountedStatus(), canceledStatus()],
			DEFAULT: [statusLoading()]
		}
		return statuses[creditMemoDetail?.status.key] || statuses['DEFAULT']
	}, [creditMemoDetail])

	return (
		<div className={styles.creditMemoInformationContainer}>
			<TrackInformation
				id={getSerieFolio(creditMemoDetail?.serie, creditMemoDetail?.folio)}
				information={mapTrackInformationData()}
				title="creditMemo"
				gridTemplateAreas={`
				'emission_date emissary emissary_rfc payment_method'
                'receptor receptor_rfc uploaded_date uploaded_by'
                '${mapTrackInformationData().invoice_reference ? 'invoice_reference invoice_reference' : 'serie_folio_uuid serie_folio_uuid'} uuid uuid'
				`}
				statuses={getStatuses()}
				showHeaderMessage={!!creditMemoDetail?.sat_validation_message || showHeaderMessage}
				headerMessage={
					creditMemoDetail?.sat_validation_message
						? creditMemoDetail?.sat_validation_message
						: rfcmItems.length === 0
							? 'associateCreditMemo'
							: 'pendingConcepts'
				}
				closeWindow={closeWindow}
				statusMessage={statusMessageCM}
			/>
			<div className={styles.separatorGrayCreditMemo}></div>
			<DetailTable
				items={mapItemsTable()}
				errorItems={errorItems}
				section={'creditMemoDetail'}
				itemsJustifyContent={{ 1: 'flex-start' }}
				itemsDataClassName={{ 5: styles.cmRFCMDataContainer }}
				dynamicTop="14.9rem"
			/>
		</div>
	)
}
