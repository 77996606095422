import React from 'react'
import { NetcurioButton, NetcurioDialog } from '@netcurio/frontend-components'
import { useTranslation } from 'react-i18next'

interface CancelCreditMemoModalProps {
	open: boolean
	onAccept(): void
	onClose(): void
}

/**
 * CancelCreditMemoModal
 * @description Helps to display a modal when the user is trying to cancel a credit memo
 * @param open <boolean>: If `true`, the component is shown
 * @param onClose <boolean>: If `false`, the component is hide
 * @param onAccept <void>: Callback fired when the user accept the action the modal
 * @return component <ReactNode>
 **/
export const CancelCreditMemoModal = ({ open, onAccept, onClose }: CancelCreditMemoModalProps) => {
	const { t } = useTranslation()

	return (
		<NetcurioDialog
			open={open}
			titleText={t('cancelCreditMemoTitle')}
			actionButtons={
				<>
					<NetcurioButton onClick={onClose}>{t('cancelButton')}</NetcurioButton>
					<NetcurioButton variant="contained" onClick={onAccept}>
						{t('acceptTextButton')}
					</NetcurioButton>
				</>
			}
		>
			<p>{t('cancelCreditMemoQuestion')}</p>
		</NetcurioDialog>
	)
}
