import { languageResources } from '@netcurio/frontend-translations'
import * as i18n from 'i18next'
import LngDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n.use(LngDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: languageResources,

		debug: false,

		fallbackLng: ['es'], // use en if detected lng is not available

		keySeparator: '.', // we now use keys in message.welcome form to use them in messages grouped by entities

		interpolation: {
			escapeValue: false // react already safes from xss
		},
		detection: {
			// order and from where user language should be detected
			order: ['navigator', 'htmlTag'],
			// keys or params to lookup language from
			lookupQuerystring: 'lng',
			lookupCookie: 'i18next',
			lookupLocalStorage: 'i18nextLng',
			lookupFromPathIndex: 0,
			lookupFromSubdomainIndex: 0,

			// cache user language on
			caches: ['localStorage', 'cookie'],
			excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

			// optional expire and domain for set cookie
			cookieMinutes: 10,
			cookieDomain: 'myDomain',

			// optional htmlTag with lang attribute, the default is:
			htmlTag: document.documentElement
		}
	})
