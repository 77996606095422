import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/nova-light/theme.css'
import * as React from 'react'
import { render } from 'react-dom'
import './initI18n'
// TODO: Update the components to no longer require next line:
import '@netcurio/frontend-assets/src/styles/base.scss'
import '@netcurio/frontend-components/dist/style.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Suspense } from 'react'
import { Bootstrap } from './bootstrap'
;(window as any).global = window

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			suspense: true
		}
	}
})

const app = document.getElementById('root')
render(
	<Suspense fallback={<h1>Cargando...</h1>}>
		<QueryClientProvider client={queryClient}>
			<Bootstrap />
		</QueryClientProvider>
	</Suspense>,
	app
)
